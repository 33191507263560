import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalCsService {
  constructor() {}

  public _debugMode: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly debugMode: Observable<boolean> = from(this._debugMode.asObservable());

  public isCS(): boolean {
    // Are we a member of our fine Customer Services team?
    const isCS = localStorage.getItem('isCS');
    return isCS === 'true' || !environment.production;
  }

  toggleDebugMode() {
    this._debugMode.next(!this._debugMode.value);
  }
}
