import { Injectable, isDevMode } from '@angular/core';
import * as Sentry from '../../../sentry';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Logger } from '@app/shared/utils';

enum IntercomActions {
  SHOW = 'show',
  SHOW_NEW_MESSAGE = 'showNewMessage',
  SHOW_ARTICLE = 'showArticle',
  START_TOUR = 'startTour',
  SHUTDOWN = 'shutdown',
}

/**
 * Service to interact with Intercom
 * Intercom is loaded through Segment.io and made available on the window object
 */
@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(private authService: AuthenticationService) {}

  public openHelpArticle(articleId: number | string) {
    if (!articleId) {
      return;
    }

    if (!this.intercom) {
      window.open(`https://my.hospitable.com/support-documentation/article/${articleId}`, '_blank');
      return;
    }

    return this.intercom(IntercomActions.SHOW_ARTICLE, articleId);
  }

  public showNewMessage(message: string) {
    if (isDevMode()) {
      Logger.log('[Intercom Action]', '"showMessage"', `"${message}"`);
    }
    if (!this.intercom) {
      this.emailFallback(message);
    }
    this.intercom(IntercomActions.SHOW_NEW_MESSAGE, message);
  }

  public investigateSubscriptionIssue() {
    this.showNewMessage(
      "Please help me investigate an issue I'm having with subscribing to Hospitable. Code: subscription_cannot_be_created"
    );
  }

  public show(message = '') {
    if (isDevMode()) {
      Logger.log('[Intercom Action]', '"show"', `"${message}"`);
    }
    if (!this.intercom) {
      this.emailFallback(message);
      return;
    }

    return this.intercom(IntercomActions.SHOW, message);
  }

  public startTour(tourId: string) {
    if (!this.intercom) {
      Sentry.captureException('Tour failed to load. Intercom may be blocked or is not loaded.');
      return;
    }

    this.intercom(IntercomActions.START_TOUR, tourId);
  }

  public shutdown() {
    if (!this.intercom) {
      return;
    }

    this.intercom(IntercomActions.SHUTDOWN);
  }

  private get intercom() {
    return (window as any).Intercom;
  }

  private get user() {
    return this.authService.getUserDetails();
  }

  private emailFallback(message: string) {
    const userId = this.user?.id ? `User ID: ${this.user.id}` : '';
    const subject = message?.length ? message : 'I need support';
    window.open(`mailto:support@hospitable.com?subject=${subject}. ${userId}`, '_blank');
  }
}
