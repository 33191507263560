import { Injectable } from '@angular/core';
import { OptimizelyFeatureFlag } from '@app/shared/interfaces';
import { CustomCodeService } from '@app/shared/services/custom-codes/custom-code.service';
import { EditorApiService, SmartCode } from '@app/shared/services/editor/editor-api.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { dedupeArray } from '@app/shared/utils';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

export const CUSTOM_CODE_REGEX = /%%([^ \n\r\t-,.;].+?)%%/g;
export const SHORT_CODE_REGEX = /%{1}([^ -,.;].*?)%{1}/g; // Match all single %'s with something inbetween, but don't match %%
export const SMART_CODE_INSERT_STRATEGY_MAP = {
  smart_greeting: 'prepend',
  smart_sign_off: 'append',
};

/** Localized service to mange editor state */
@Injectable({
  providedIn: 'any',
})
export class EditorService {
  public smartCodes$ = new BehaviorSubject<SmartCode[]>([]);
  public inUse$ = new BehaviorSubject<{ smart: SmartCode['key'][]; custom: string[] }>({ smart: [], custom: [] });
  public availableSmartCodes$ = combineLatest([this.smartCodes$, this.inUse$]).pipe(
    map(([smartCodes, inUse]) => {
      return smartCodes.filter((c) => !inUse?.smart.includes(c.key));
    })
  );
  public canSeeSmartCodes$ = new BehaviorSubject<boolean>(false);
  public allCustomCodes$ = this.customCodesDataSource();

  private customCodesWithUpsellConditions$ = this.allCustomCodes$.pipe(
    map((codes) => {
      return codes.filter(
        (code) =>
          (code.condition?.key === 'night_before_check_in_available' ||
            code.condition?.key === 'night_after_check_out_available') &&
          code.condition?.operator === '=' &&
          code.condition?.value === true
      );
    })
  );

  public upsellWarning$ = combineLatest([this.customCodesWithUpsellConditions$, this.inUse$]).pipe(
    map(([upsellCustomCodes, inUse]) => {
      if (
        !inUse.smart.includes('smart_upsell') ||
        !inUse.custom?.some((c) => upsellCustomCodes.find((upsellCustomCode) => c === upsellCustomCode.key))
      ) {
        return [];
      }
      return upsellCustomCodes.filter((upsellCustomCode) => inUse.custom.includes(upsellCustomCode.key));
    })
  );

  public smartCodesEnabled$ = this.optimizelyService.featureEnabled$(OptimizelyFeatureFlag.SmartCodes);
  public smartCodesEnabled = false;

  constructor(
    private readonly editorService: EditorApiService,
    private readonly optimizelyService: OptimizelyService,
    private readonly customCodeService: CustomCodeService
  ) {
    this.smartCodesEnabled$.subscribe((enabled) => {
      this.smartCodesEnabled = enabled;
    });
  }

  /**
   * If a smartcode exists in the template, we need add it to the list of smart codes in use
   * Could we tap into the prior process to avoid double checking the text content?
   * @param text innerText of the editor (not full html content)
   * @returns void
   */
  public processSmartCodeUsageInText(text: string): void {
    if (!text || !this.smartCodes$.value?.length) {
      return;
    }

    if (!text.match(SHORT_CODE_REGEX)) {
      this.useSmartCodes([]);
      return;
    }

    // remove custom codes from our test
    const customCodeMatches = text.match(CUSTOM_CODE_REGEX)?.map((m) => m.split('%%')[1]) ?? [];
    this.inUse$.next({ ...this.inUse$.value, custom: dedupeArray(customCodeMatches) });
    text = text.replace(CUSTOM_CODE_REGEX, '');

    const smartCodeMatches = text
      .match(SHORT_CODE_REGEX)
      ?.filter((m) => this.isSmartCode(m.split('%')[1]))
      ?.map((m) => m.split('%')[1]);

    if (!smartCodeMatches?.length) {
      this.useSmartCodes([]);
      return;
    }

    this.useSmartCodes(smartCodeMatches as SmartCode['key'][]);
  }

  public isSmartCode(key: string) {
    if (!this.smartCodes$.value?.length) {
      return;
    }
    // Do this without looping, using a map?
    return this.smartCodes$.value.some((c) => c.key === key);
  }

  public getSmartCode(key: string) {
    if (!this.smartCodes$.value?.length) {
      return;
    }
    return this.smartCodes$.value.find((c) => c.key === key);
  }

  public fetchSmartCodes(type) {
    const allowed = this.smartCodesAllowed(type);
    this.canSeeSmartCodes$.next(allowed);

    if (!allowed) {
      return of({ short: [] });
    }
    return this.editorService.getSmartCodes(type).pipe(
      tap((res) => {
        this.addSmartCodes(res.short);
      })
    );
  }

  public refreshCustomCodes() {
    this.allCustomCodes$ = this.customCodesDataSource();
    return this.customCodesDataSource();
  }

  private customCodesDataSource() {
    return this.customCodeService.getCustomCodes().pipe(
      map((res) => res?.data),
      shareReplay(1)
    );
  }

  private smartCodesAllowed(type): boolean {
    const excludedTypes = ['question', 'review'];
    return this.smartCodesEnabled && !excludedTypes.includes(type?.toLowerCase().split(':')[0]);
  }

  private addSmartCodes(codes: SmartCode[]) {
    this.smartCodes$.next([...this.smartCodes$.value, ...codes]);
  }

  private useSmartCodes(codes: SmartCode['key'][]) {
    this.inUse$.next({ ...this.inUse$.value, smart: dedupeArray(codes) });
  }
}
