import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SvgCacheService } from '@app/ui';
import { sbnbIcon } from '@app/ui/icons/sbnb-icons';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

const USES_CURRENT_COLOR = {
  check: true,
  not_allowed: true,
  cog: true,
  hyperlink: true,
  alarm_bell_off: true,
  alarm_bell_check: true,
  properties: true,
  book_open: true,
  book_open_bold: true,
  phone: true,
  email: true,
  time: true,
  details: true,
  accounting_coins_stack: true,
  adults: true,
  task: true,
  alert_circle: true,
  check_circle: true,
};

@Component({
  selector: 'sbnb-icons-page',
  template: `
    <div class="bg-grape-darker min-h-screen">
      <div class="flex flex-col  items-center justify-center p-10 ">
        <div class="w-full flex items-center justify-center bg-grape-darker sticky top-0 z-10 py-2 box-shadow-heavy">
          <mat-form-field class="!text-white nopadding">
            <mat-label>Search icons</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input [formControl]="searchControl" type="text" matInput placeholder="Search..." />
          </mat-form-field>
        </div>
        <div class="grid gap-1 grid-cols-2 md:grid-cols-4 lg:grid-cols-8 bg-grape-darker mt-2 mb-12 p-1">
          <div
            *ngFor="let icon of filteredIcons$ | async"
            class="icon-cell px-2 py-4 flex flex-col gap-2 items-center justify-center rounded-sm bg-white">
            <ng-container *ngIf="copiedMap$ | async as copiedMap">
              <div
                class="copy-component cursor-pointer"
                (click)="copyComponent(icon)"
                [matTooltip]="'Copy component code'">
                <sbnb-icon *ngIf="!copiedMap[icon]" icon="copy_grass"></sbnb-icon>
              </div>
              <div
                *ngIf="copiedMap[icon]"
                class="text__xsmall bg-grape-darker text-white p-2 rounded-md absolute top-1 right-1">
                Copied!
              </div>
            </ng-container>

            <sbnb-icon [icon]="icon" [size]="36"></sbnb-icon>
            <code class="text__xsmall break-all night-lighter">{{ icon }}</code>
            <div *ngIf="usesCurrentColor[icon]" class="absolute bottom-0 left-3" matTooltip="Can be styled with CSS">
              <sbnb-icon [size]="16" icon="thumbs_up"></sbnb-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep .mat-input-element {
        color: white !important;
      }

      ::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: #fff !important;
      }

      .copy-component {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
      }
      .icon-cell {
        position: relative;
        &:hover {
          .copy-component {
            display: block;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsPageComponent implements OnInit {
  public usesCurrentColor = USES_CURRENT_COLOR;
  public icons;
  public copiedMap$ = new BehaviorSubject<Record<sbnbIcon, boolean>>({} as Record<sbnbIcon, boolean>);
  public searchControl = new FormControl('');
  public filteredIcons$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    debounceTime(300),
    distinctUntilChanged(),
    map((search?: string) => {
      if (!search?.length) {
        return this.icons;
      }
      const modSearch = search.toLowerCase().replace(/[-_]/g, ' ');
      return this.icons.filter((icon) => icon.replace(/_/g, ' ').includes(modSearch));
    })
  );

  constructor(
    private svgCacheService: SvgCacheService,
    private clipboard: ClipboardService
  ) {}

  ngOnInit(): void {
    this.icons = this.svgCacheService.getAllIcons();
  }

  public copyComponent(icon: sbnbIcon): void {
    const text = `<sbnb-icon icon="${icon}"></sbnb-icon>`;
    this.clipboard.copy(text);
    this.copiedMap$.next({ ...this.copiedMap$.value, [icon]: true });
    setTimeout(() => {
      this.copiedMap$.next({ ...this.copiedMap$.value, [icon]: false });
    }, 2000);
  }
}
