import { Injectable } from '@angular/core';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { PropertiesService } from './properties/properties.service';
import { ScriptLoaderService } from './script-loader/script-loader.service';
import { Logger } from '@app/shared/utils';

interface DelightedProperties {
  is_primary_user: boolean;
  is_beta_user: boolean;
  is_direct_user: boolean;
  is_smartlocks_user: boolean;
  property_count: number;
  direct_plan?: string; // Note the '?' which indicates it might be optional
  direct_paid_customer?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DelightedService {
  constructor(
    private scriptLoader: ScriptLoaderService,
    private propertyService: PropertiesService,
    private directSubscriptionService: DirectSubscriptionService,
    private segmentService: SegmentIoService
  ) {}

  delighted: any = null;
  user = JSON.parse(localStorage.getItem('user'));

  init() {
    this.scriptLoader
      .load({
        name: 'Delighted NPS',
        src: '/assets/js/delighted.js',
      })
      .subscribe(
        (res) => {
          Logger.info('Delighted NPS loaded');
          this.delighted = (window as any).delighted;

          // There's no rush to mark for survey, and we want to be sure all the data we need has loaded
          setTimeout(() => {
            this.survey();
          }, 10_000);
        },
        (err) => {
          Logger.info('Delighted NPS Script failed to load');
        }
      );
  }

  survey() {
    if (!this.delighted) {
      Logger.info('Delighted NPS not loaded, unable to survey');
      return;
    }

    if (localStorage.getItem('isCS') === 'true') {
      Logger.info('Delighted NPS not loaded, CS user');
      return;
    }

    this.user = JSON.parse(localStorage.getItem('user'));

    if (!this.user) {
      Logger.info('Delighted NPS not loaded, no authenticated user');
      return;
    }

    let property_count = 0;

    this.propertyService.getProperties(null, null, null, 0, false, 'simple').subscribe((res) => {
      if (res.data?.length) {
        property_count = res.data.length;
      }

      const directSub = this.directSubscriptionService.getCurrentSubscriptionStatus();

      const properties: DelightedProperties = {
        is_primary_user: this.user.email === this.user.team?.owner_email,
        is_beta_user: this.user.beta_features_enabled,
        is_direct_user: directSub?.onboarded,
        is_smartlocks_user: this.user.has_smartlocks,
        property_count: property_count,
      };

      // Only add direct_plan and direct_paid_customer to the properties if direct_plan is true
      if (directSub?.plan) {
        properties.direct_plan = directSub?.plan;
        properties.direct_paid_customer = !directSub?.trial;
      }

      this.delighted.survey({
        email: this.user.email,
        name: `${this.user.first_name} ${this.user.last_name}`,
        createdAt: this.user.created_at,
        properties: properties,
        onRespond: () => {
          Logger.info('Delighted NPS responded');
          this.segmentService.track(SegmentEvent.DelightedNPSResponded, {});
        },
      });
    });
  }
}
