import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  isExpiredSubscription as isExpiredSubscriptionFn,
  isNotSubscribed as isNotSubscribedFn,
  isTrialSubscription as isTrialSubscriptionFn,
  canManageAddons as canManageAddonsFn,
  PlanId,
  Subscription,
} from './billing.models';
import { BillingState, BILLING_FEATURE_KEY } from './billing.reducer';

// Lookup the 'Billing' feature state managed by NgRx
export const getBillingState = createFeatureSelector<BillingState>(BILLING_FEATURE_KEY);

export const getSubscriptionLoadingState = createSelector(
  getBillingState,
  (state: BillingState) => state.subscription.loadingState
);
export const getSubscription = createSelector(getBillingState, (state: BillingState) => state.subscription.data);

export const isOnProfessionalPlan = createSelector(getSubscription, (subscription) =>
  Boolean(subscription?.plan?.name === PlanId.Professional)
);

export const isOnHostPlan = createSelector(getSubscription, (subscription) =>
  Boolean(subscription?.plan?.name === PlanId.Host)
);

export const isExpiredSubscription = createSelector(getSubscription, (subscription) =>
  isExpiredSubscriptionFn(subscription)
);

export const isTrialSubscription = createSelector(getSubscription, (subscription) =>
  isTrialSubscriptionFn(subscription)
);

export const canManageAddons = createSelector(getSubscription, (subscription) => canManageAddonsFn(subscription));

export const isNotSubscribed = createSelector(getSubscription, (subscription) => isNotSubscribedFn(subscription));

export const isAnnualSubscription = createSelector(
  getSubscription,
  (subscription) => subscription?.plan?.type === 'yearly'
);

export const canCancelSubscription = createSelector(
  getSubscription,
  (subscription) =>
    subscription?.plan?.type !== 'yearly' &&
    (subscription?.status === 'active' ||
      subscription?.status === 'trialing' ||
      subscription?.status === 'paused' ||
      subscription?.status === 'pending')
);

export const getPlansLoadingState = createSelector(getBillingState, (state: BillingState) => state.plans.loadingState);

export const getPlans = createSelector(getBillingState, (state: BillingState) => state.plans.data);

export const hasActivePaymentMethod = createSelector(getSubscription, (subscription: Subscription) => {
  return subscription?.active_payment_method?.status === 'valid';
});

export const getPendingSubscriptionChange = createSelector(
  getBillingState,
  (state: BillingState) => state.pendingSubscriptionChange
);

export const getPendingSubscriptionChangePlan = createSelector(
  getPendingSubscriptionChange,
  getPlans,
  (pendingSubscriptionChange, plans) => plans?.find((plan) => plan?.plan_id === pendingSubscriptionChange?.planId)
);

export const getDowngradeChecks = createSelector(
  getPendingSubscriptionChange,
  (pendingSubscriptionChange) => pendingSubscriptionChange?.downgradeChecks?.entitlements
);

export const getDeviceAddonState = createSelector(getBillingState, (state: BillingState) => state.smartDevices);

export const getDeviceAddonActivated = createSelector(getDeviceAddonState, (state) => state.data?.activated);

export const getSmartDevicePrice = createSelector(getSubscription, (subscription) => {
  return subscription?.additional_devices;
});

export const getCancelSubscriptionSubmittingState = createSelector(
  getBillingState,
  (state: BillingState) => state.cancelSubscription.submitting
);
