import { Component, ChangeDetectionStrategy, ElementRef, Input, OnChanges } from '@angular/core';
import { SvgCacheService } from '@app/ui/icons/icon/svg-cache.service';
import { sbnbIcon } from '@app/ui/icons/sbnb-icons';

@Component({
  selector: 'sbnb-icon',
  template: `
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        line-height: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon: sbnbIcon;
  @Input() classes: string;
  @Input() size = 20;

  constructor(
    private svgCacheService: SvgCacheService,
    private element: ElementRef<HTMLElement>
  ) {}

  ngOnChanges() {
    this.buildSvg();
  }

  private buildSvg() {
    if (!this.element) {
      return;
    }

    const icon = this.svgCacheService.getIcon(this.icon);

    if (!icon) {
      return;
    }

    try {
      // Set the innerHTML of the element to the SVG string
      this.element.nativeElement.innerHTML = icon;
      // Select the svg element and add classes and styles
      const svg = this.element.nativeElement.querySelector('svg');
      if (!svg) {
        return;
      }

      svg.classList.add(...(this.classes?.split(' ') ?? []));
      svg.style.setProperty('min-width', `${this.size}px`);
      svg.style.setProperty('height', `${this.size}px`);
      svg.style.setProperty('width', `${this.size}px`);
    } catch (error) {
      console.error('Error building SVG:', error);
    }
  }
}
