import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@app/core/app-config';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { PusherService } from '@app/shared/services/pusher/pusher.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserPermissions } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';

export interface OnboardingStore {
  data: OnboardingItem[];
  meta: OnboardingMetadata;
}

export interface OnboardingItem {
  id: number;
  title: string;
  completed: boolean;
  completion_source?: 'system' | 'user';
  enabled: boolean;
  can_manually_mark_as_completed: boolean;
  action?: OnboardingAction;
  children?: OnboardingItem[];
  children_expanded?: boolean;
}

export interface OnboardingAction {
  label: string;
  link?: string;
  tour_id?: string;
  mobile_tour_id?: string;
}

export interface OnboardingMetadata {
  has_changes_since_last_seen: boolean;
  change_ids?: number[];
  has_ever_connected_airbnb_account: boolean;
  pending_count?: number; // number of incomplete actionable steps
  count?: number; // total number of actionable steps
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  defaultData: OnboardingStore = {
    data: [],
    meta: {
      has_changes_since_last_seen: false,
      has_ever_connected_airbnb_account: false,
    },
  };

  private _onboardingItems: BehaviorSubject<OnboardingStore> = new BehaviorSubject(this.defaultData);
  public readonly onboardingItems: Observable<OnboardingStore> = this._onboardingItems.asObservable();

  // userPermissions$ = this.auth.permissions;

  onboardingPusherUpdates$ = this.pusher.onboardingUpdate;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly auth: AuthenticationService,
    private readonly permissionService: UserPermissionsService,
    private readonly pusher: PusherService
  ) {
    this.permissionService.permissions.subscribe((perms: UserPermissions) => {
      if (perms && perms.is_team_owner) {
        // Subscribe to pusher updates
        this.onboardingPusherUpdates$.subscribe((payload: OnboardingStore) => {
          console.log(payload);
          this._onboardingItems.next(payload);
        });

        // Fetch the onboarding items, if our initial page isn't onboarding
        if (this.router.url.indexOf('/onboarding') === -1 && this.router.url.indexOf('/user/register/done') === -1) {
          this.getOnboardingItems().subscribe((res) => {});
        }
      }
    });
  }

  getOnboardingItems(markAsSeen = false) {
    return this.http.get(`${config.API_URL}/user/onboarding?mark_as_seen=${markAsSeen}`).pipe(
      map((res: OnboardingStore) => {
        this._onboardingItems.next(res);

        return res;
      }),
      catchError((err) => {
        return of(this.defaultData);
      })
    );
  }

  markItemAsManuallyCompleted(item: OnboardingItem, parentId: number, completed: boolean) {
    this.http.post(`${config.API_URL}/user/onboarding/${item.id}/complete`, {}).subscribe((res: OnboardingStore) => {
      this._onboardingItems.next(res);
    });

    // const itemInSubject = this.findOnboardingItemById(item.id, parentId);

    // if (parentId) {
    //   const child = this.findChildItemIndexById(item.id, itemInSubject);
    //   child.completed = completed;
    // } else {
    //   itemInSubject.completed = completed;
    //   itemInSubject.completion_source = 'user';

    //   // Complete child tasks
    //   if (itemInSubject.children?.length > 0) {
    //     for (let index = 0; index < itemInSubject.children.length; index++) {
    //       const element = itemInSubject.children[index];
    //       element.completed = completed;
    //       element.completion_source = 'user';
    //     }
    //   }
    // }

    // this._onboardingItems.next(this._onboardingItems.value);
  }

  expandCollapseChildren(item: OnboardingItem, expand: boolean) {
    // We're going to update this locally ourselves, because it should be lightning fast.
    const itemInSubject = this.findOnboardingItemById(item.id);

    if (itemInSubject) {
      itemInSubject.children_expanded = expand;
      this._onboardingItems.next(this._onboardingItems.value);
    }

    // We don't care about the response, but we do tell the API of the state change so that it's recorded for future visits
    this.http.post(`${config.API_URL}/user/onboarding/${item.id}/expand`, {}).subscribe((res: OnboardingStore) => {});
  }

  private findOnboardingItemById(id: number, parentId?: number): OnboardingItem {
    let index: number;

    if (parentId) {
      index = this._onboardingItems.value.data.findIndex((x) => x.id === parentId);
    } else {
      index = this._onboardingItems.value.data.findIndex((x) => x.id === id);
    }

    if (index < 0) {
      throwError('Onboarding item not found to update');
      return;
    }

    return this._onboardingItems.value.data[index];
  }

  findChildItemIndexById(childId: number, itemInSubject: OnboardingItem) {
    const childIndex = itemInSubject.children.findIndex((x) => x.id === childId);

    return itemInSubject.children[childIndex];
  }
}
