import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { HostsService } from '@app/shared/services/hosts/hosts.service';
import { PlatformsService } from '@app/shared/services/platforms/platforms.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'sbnb-rule-scope',
  templateUrl: './rule-scope.component.html',
  styleUrls: ['./rule-scope.component.scss'],
})
export class RuleScopeComponent implements OnInit {
  possiblePlatforms: any[];
  possibleHosts: any[];
  possibleProperties: any[];

  selectedPlatforms: any[] = [];
  selectedAllPlatforms: boolean;

  selectedHosts: any[] = [];
  selectedAllHosts: boolean;

  selectedProperties: any[] = [];
  selectedAllProperties: boolean;

  loadingPlatforms = true;
  loadingHosts = true;
  loadingProperties = true;

  searchCriteriaPlatforms: string;
  searchCriteriaHosts: string;
  searchCriteriaProperties: string;

  platformStagePassed: boolean;
  hostsStagePassed: boolean;
  propertiesStagePassed: boolean;

  numDeletedHosts: number;
  numDeletedProperties: number;

  initialized: boolean;

  @Input() initial: any;
  @Input() airbnbOnly: boolean;
  @Input() debounceTime: number = 1000;
  @Output() completed: EventEmitter<any> = new EventEmitter();

  filteredHosts: BehaviorSubject<any> = new BehaviorSubject([]);
  filteredProperties: BehaviorSubject<any> = new BehaviorSubject([]);

  private scopeChangeSubject: Subject<any> = new Subject();

  constructor(
    private propertiesService: PropertiesService,
    private hostsService: HostsService,
    private platformsService: PlatformsService
  ) {}

  ngOnInit() {
    if (this.initial) {
      this.selectedAllHosts = this.initial.all_hosts;
      this.selectedAllPlatforms = this.initial.all_platforms;
      this.selectedAllProperties = this.initial.all_properties;
      this.selectedHosts = this.initial.hosts;
      this.selectedPlatforms = this.initial.platforms;
      this.selectedProperties = this.initial.properties;
    }

    const properties$ = this.propertiesService.getScopingProperties([], null, null, 5000, false);
    const hosts$ = this.hostsService.getHosts();
    const platforms$ = this.platformsService.getPlatforms();

    forkJoin(properties$, hosts$, platforms$).subscribe((res) => {
      this.loadingProperties = false;
      this.loadingHosts = false;
      this.loadingPlatforms = false;

      this.possibleProperties = res[0].data;
      this.filteredProperties.next(res[0].data);
      this.possibleHosts = res[1];

      // If airbnbOnly is true, filter out other hosts
      let hosts = res[1];
      if (this.airbnbOnly === true) {
        hosts = hosts.filter((host) => host.platform === 'airbnb');
        this.possibleHosts = hosts;
      }
      this.filteredHosts.next(hosts);

      this.possiblePlatforms = res[2];

      if (this.selectedAllPlatforms) {
        this.selectAllPlatforms();
      }

      if (this.selectedAllHosts) {
        this.selectAllHosts();
      }

      if (this.selectedAllProperties) {
        this.selectAllProperties();
      }

      this.platformStagePassedCheck(true);

      this.numDeletedHosts = this.howManyHostsAreDeleted();
      this.numDeletedProperties = this.howManyPropertiesAreDeleted();

      if (this.selectedAllProperties || this.selectedProperties.length > 0) {
        this.propertiesStagePassed = true;
      }

      this.initialized = true;

      // Debounce scope updates
      this.scopeChangeSubject.pipe(debounceTime(this.debounceTime)).subscribe((x) => {
        this.propertyStagePassedCheck();
      });
    });
  }

  // The API tells us about all hosts, active or deleted
  // The count of hosts should only show those which are active, we need to manually calculate how many are deleted to achieve this
  howManyHostsAreDeleted(): number {
    if (
      !this.possibleHosts ||
      this.possibleHosts.length === 0 ||
      !this.selectedHosts ||
      this.selectedHosts.length === 0
    ) {
      return 0;
    }

    let count = 0;

    this.selectedHosts.forEach((sH) => {
      let index = this.possibleHosts.findIndex((pH) => pH.user_id == sH);

      if (index === -1) {
        count++;
      }
    });

    return count;
  }

  howManyPropertiesAreDeleted(): number {
    if (
      !this.possibleProperties ||
      this.possibleProperties.length === 0 ||
      !this.selectedProperties ||
      this.selectedProperties.length === 0
    ) {
      return 0;
    }

    let count = 0;

    this.selectedProperties.forEach((sP) => {
      let index = this.possibleProperties.findIndex((pP) => pP.id == sP);

      if (index === -1) {
        count++;
      }
    });

    return count;
  }

  propertyCheckboxChange(property, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedProperties.push(property.id);
    } else {
      this.selectedAllProperties = false;
      const index = this.selectedProperties.findIndex((prop) => prop === property.id);

      if (index !== -1) {
        this.selectedProperties.splice(index, 1);
      }
    }

    this.scopeChangeSubject.next(true);
  }

  propertyAllCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedAllProperties = true;
      this.searchCriteriaProperties = '';
      this.selectAllProperties(true);
    } else {
      this.selectedAllProperties = false;
    }

    this.scopeChangeSubject.next(true);
  }

  propertySearchUpdated(searchCriteria: string) {
    this.filteredProperties.next(
      this.possibleProperties.filter((prop) =>
        prop.name ? prop.name.toLowerCase().includes(searchCriteria.toLowerCase()) : false
      )
    );
  }

  hostsSearchUpdated(searchCriteria: string) {
    this.filteredHosts.next(
      this.possibleHosts.filter((host) =>
        host.name ? host.name.toLowerCase().includes(searchCriteria.toLowerCase()) : false
      )
    );
  }

  hostCheckboxChange(host, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedHosts.push(host.user_id + '');
    } else {
      this.selectedAllHosts = false;
      const index = this.selectedHosts.findIndex((selHost) => selHost == host.user_id);

      if (index !== -1) {
        this.selectedHosts.splice(index, 1);
      }
    }

    this.hostsStagePassedCheck();
  }

  hostAllCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedAllHosts = true;
      this.selectAllHosts();
      this.searchCriteriaHosts = '';
    } else {
      this.selectedAllHosts = false;
    }

    this.hostsStagePassedCheck();
  }

  selectAllHosts() {
    if (this.selectedAllHosts) {
      this.selectedHosts = [];
      this.possibleHosts.forEach((pH) => {
        if (pH.selectable || this.selectedAllPlatforms) {
          this.selectedHosts.push(pH.user_id + '');
        }
      });
    }
  }

  selectAllPlatforms() {
    if (this.selectedAllPlatforms) {
      this.selectedPlatforms = [];
      this.possiblePlatforms.forEach((pP) => {
        this.selectedPlatforms.push(pP.key);
      });
    }
  }

  selectAllProperties(force = false) {
    if (this.selectedAllProperties) {
      this.selectedProperties = [];
      this.possibleProperties.forEach((pProp) => {
        if (pProp.selectable && pProp.hosts.length > 0) {
          this.selectedProperties.push(pProp.id);
        }
      });
    }

    if (this.initialized && !force) {
      this.checkForUnselectableProperties();
      return;
    }
  }

  checkForUnselectableProperties() {
    const newSelected = [];
    this.selectedProperties.forEach((sP) => {
      const pP = this.possibleProperties.find((pP) => pP.id === sP);

      if (pP && pP.selectable) {
        newSelected.push(sP);
      }
    });

    this.selectedProperties = newSelected;
  }

  platformAllCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedAllPlatforms = true;
      this.searchCriteriaPlatforms = '';
      this.selectAllPlatforms();
    } else {
      this.selectedAllPlatforms = false;
    }

    this.platformStagePassedCheck();
  }

  platformsSearchUpdated(event: string) {
    this.searchCriteriaPlatforms = event;
  }

  platformCheckboxChange(platform, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedPlatforms.push(platform.key);
    } else {
      this.selectedAllPlatforms = false;
      const index = this.selectedPlatforms.findIndex((selPlat) => selPlat === platform.key);

      if (index !== -1) {
        this.selectedPlatforms.splice(index, 1);
      }
    }

    this.platformStagePassedCheck();
  }

  platformStagePassedCheck(clear: boolean = true) {
    if (clear) {
      if (!this.selectedAllHosts) {
        this.selectedHosts = this.selectedHosts.filter((sh, index) => {
          const hostObj = this.possibleHosts.find((x) => x.user_id == sh); // sadly, leave this as '==', as we have some string ID's, and some numeric :(

          if (hostObj) {
            const shallWeKeepThis = this.selectedPlatforms.includes(hostObj.platform) || this.selectedAllPlatforms;

            if (!shallWeKeepThis) {
              return false;
            } else {
              return true;
            }
          }
        });
      }

      if (this.selectedPlatforms.includes('direct') || this.selectedAllPlatforms) {
        // find any possibleHosts with platform 'direct' and add them to selectedHosts
        this.possibleHosts.forEach((pH) => {
          if (pH.platform === 'direct' && this.selectedHosts.includes(pH.user_id + '') === false) {
            this.selectedHosts.push(pH.user_id + '');
          }
        });
      }

      this.platformStagePassed = false;
      this.hostsStagePassed = false;
      this.propertiesStagePassed = false;
    }

    if (this.selectedAllPlatforms || this.selectedPlatforms.length > 0) {
      this.platformStagePassed = true;

      this.possibleHosts.forEach((host) => {
        if (this.selectedAllPlatforms) {
          host.selectable = true;
        } else {
          host.selectable = false;

          const index = this.selectedPlatforms.findIndex((sp) => sp === host.platform);

          if (index !== -1) {
            host.selectable = true;
          }
        }

        if (host.platform === 'direct') {
          host.selectable = false;
        }
      });

      this.possibleHosts.sort((a, b) => {
        return (
          this.selectedHosts.indexOf(b.user_id + '') - this.selectedHosts.indexOf(a.user_id + '') ||
          b.selectable - a.selectable
        );
      });

      this.selectAllHosts();

      this.hostsStagePassedCheck(true);
    } else {
      this.platformStagePassed = false;
    }
  }

  hostsStagePassedCheck(clear: boolean = true) {
    this.hostsStagePassed = false;
    this.propertiesStagePassed = false;

    if (this.selectedAllHosts || this.selectedHosts.length > 0) {
      this.hostsStagePassed = true;

      this.possibleProperties.forEach((property) => {
        property.selectable = false;

        const propertyUserIds = [];

        property.hosts.forEach((host) => {
          propertyUserIds.push(host.platform_id);
        });

        let matchesUser = false;

        if (this.selectedAllHosts || this.selectedPlatforms.includes('direct')) {
          matchesUser = true;
        }

        if (!matchesUser) {
          this.selectedHosts.forEach((host) => {
            const index = propertyUserIds.findIndex((userId) => userId.toString() === host.toString());

            if (index !== -1) {
              matchesUser = true;
            }
          });
        }

        if (matchesUser) {
          property.selectable = true;
        }
      });

      this.possibleProperties.sort((a, b) => {
        return (
          this.selectedProperties.indexOf(b.id) - this.selectedProperties.indexOf(a.id) || b.selectable - a.selectable
        );
      });

      this.selectAllProperties();

      this.scopeChangeSubject.next(true);
    } else {
      this.hostsStagePassed = false;
    }
  }

  propertyStagePassedCheck() {
    if (this.selectedAllProperties || this.selectedProperties.length > 0) {
      this.propertiesStagePassed = true;

      this.completed.emit({
        all_hosts: this.selectedAllHosts,
        all_platforms: this.selectedAllPlatforms,
        all_properties: this.selectedAllProperties,
        platforms: this.selectedPlatforms,
        properties: this.selectedProperties,
        hosts: this.selectedHosts,
      });
    }
  }

  clearPlatforms() {
    this.selectedAllPlatforms = false;
    this.selectedPlatforms = [];
    this.selectedAllHosts = false;
    this.selectedHosts = [];
    this.selectedAllProperties = false;
    this.selectedProperties = [];
    this.platformStagePassedCheck();
  }

  clearHosts() {
    this.selectedAllHosts = false;
    this.selectedHosts = [];
    this.selectedAllProperties = false;
    this.selectedProperties = [];
    this.hostsStagePassedCheck();
  }

  clearProperties() {
    this.selectedAllProperties = false;
    this.selectedProperties = [];
    this.scopeChangeSubject.next(true);
  }
}
