declare let require: any;

const DEFAULTS = {
  name: 'production',
  trialLength: '14',
  production: true,
  apiUrl: 'https://api.hospitable.com/v1',
  siteApiUrl: 'https://api.hospitable.com/sites',
  bookingApiUrl: 'https://api.hospitable.com/bookings/api',
  metricsV2Url: 'https://my.hospitable.com/next/metrics',
  autohostPortalUrl: 'https://portal.autohost.ai/reservation',
  coreNovaUrl: 'https://nova.live.hospitable.tech',
  directNovaUrl: 'https://nova-direct.live.hospitable.tech',
  smartDevicesApiUrl: 'https://api.hospitable.com/smartlocks',
  pusher: {
    key: 'a0669e0347fc04d25304',
    cluster: 'eu',
  },
  chargebee: {
    site: 'smartbnb-admin',
    publishableKey: 'live_pTcdLbcd0NjgefOezaW8UTt30V227oBpsS',
  },
  chargebeev2: {
    site: 'hospitable',
    publishableKey: 'live_cuF7aywYLE5V9y8GlbrnhHqNn57cuzphiY',
  },
  optimizely: {
    sdkKey: 'KruhhauQnbqKV5vwAidWjC',
    flags: [],
  },
  remotelock: {
    client_id: 'a3fee30ddcdbf8e20ea49d95f4e7893afc98ba0afc5a9ac19bc4e8ed5f8a9c68',
    client_redirect: `https://my.hospitable.com/apps/remotelock`,
  },
  filestack: {
    apiKey: 'ASCskKPlUThi30TqTok8Hz',
  },
  stripe: {
    client_id: 'ca_L5e2rFh0Fgd2P0T3wYezthVG5TSehF3O',
  },
  google: {
    places: {
      apiKey: 'AIzaSyBBhVlQ_rfVVB8NwRS4-RPj_Yp5w4gd7lQ',
    },
  },
  autohostSdk: {
    scriptSrc: 'https://sdk.autohost.ai/dist/AutohostSDK.v1.bundle.js',
    sandbox: false,
  },
  version: "4a5839f3",
  churnkey: {
    appId: 'uxfbi8a65',
    mode: 'live',
  },
  posthog: {
    flags: [],
  },
};

export const environment = DEFAULTS;
