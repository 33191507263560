import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as BillingActions from './billing.actions';
import { PlanId, StashedPaymentMethod } from './billing.models';
import * as BillingSelectors from './billing.selectors';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LoadingState } from '@app/shared/interfaces';

@Injectable()
export class BillingFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  subscription$ = this.store.pipe(select(BillingSelectors.getSubscription));
  subscriptionLoadingState$ = this.store.pipe(select(BillingSelectors.getSubscriptionLoadingState));
  public isOnProfessionalPlan$ = this.store.pipe(select(BillingSelectors.isOnProfessionalPlan));
  public isOnHostPlan$ = this.store.pipe(select(BillingSelectors.isOnHostPlan));
  isTrialSubscription$ = this.store.pipe(select(BillingSelectors.isTrialSubscription));
  public plansLoadingState$ = this.store.pipe(select(BillingSelectors.getPlansLoadingState));
  public plans$ = this.store.pipe(select(BillingSelectors.getPlans));
  public hasActivePaymentMethod$ = this.store.pipe(select(BillingSelectors.hasActivePaymentMethod));
  public pendingSubscriptionChange$ = this.store.pipe(select(BillingSelectors.getPendingSubscriptionChange));
  public pendingSubscriptionChangePlan$ = this.store.pipe(select(BillingSelectors.getPendingSubscriptionChangePlan));
  public downgradeChecks$ = this.store.pipe(select(BillingSelectors.getDowngradeChecks));
  public isExpiredSubscription$ = this.store.pipe(select(BillingSelectors.isExpiredSubscription));

  public notExpiredCheck$ = this.subscriptionLoadingState$.pipe(
    tap((loadingState) => {
      if (loadingState === LoadingState.NotSent) {
        this.loadSubscription();
      }
    }),
    filter((loadingState) => loadingState === LoadingState.Success),
    switchMap(() => this.isExpiredSubscription$),
    map((isExpired) => !isExpired)
  );
  public isAnnualSubscription$ = this.store.pipe(select(BillingSelectors.isAnnualSubscription));

  public deviceAddonActivated$ = this.store.pipe(select(BillingSelectors.getDeviceAddonActivated));
  public deviceAddOnState$ = this.store.pipe(select(BillingSelectors.getDeviceAddonState));
  public smartDevicePrice$ = this.store.pipe(select(BillingSelectors.getSmartDevicePrice));
  public cancelSubscriptionSubmittingState$ = this.store.pipe(
    select(BillingSelectors.getCancelSubscriptionSubmittingState)
  );
  public canCancelSubscription$ = this.store.pipe(select(BillingSelectors.canCancelSubscription));
  public canManageAddons$ = this.store.pipe(select(BillingSelectors.canManageAddons));

  constructor(private readonly store: Store) {}

  initManageSubscription() {
    this.loadSubscription();
    this.loadPlans();
  }

  initiateSubscriptionChange(planId: PlanId, downgrade: boolean, redirectUrl = '/settings/manage-subscription') {
    this.store.dispatch(BillingActions.initiateSubscriptionChange({ planId, downgrade, redirectUrl }));
  }

  abandonSubscriptionChange() {
    this.store.dispatch(BillingActions.abandonSubscriptionChange());
  }

  changeSubscription(
    planId: PlanId,
    opts: { activeListings?: number; stashedPaymentMethod?: StashedPaymentMethod; isSubscribing?: boolean } = {}
  ) {
    this.store.dispatch(BillingActions.changeSubscription({ planId, ...opts }));
  }

  loadSubscription() {
    this.store.dispatch(BillingActions.loadSubscription());
  }

  loadDowngradeIssues() {
    this.store.dispatch(BillingActions.loadDowngradeIssues());
  }

  loadDeviceAddon() {
    this.store.dispatch(BillingActions.loadDeviceAddon());
  }

  confirmDeviceAddon() {
    this.store.dispatch(BillingActions.confirmDeviceAddon());
  }

  cancelSubscription(opts: { reason: string; textReason: string }) {
    this.store.dispatch(BillingActions.cancelSubscription(opts));
  }

  private loadPlans() {
    this.store.dispatch(BillingActions.loadPlans());
  }
}
