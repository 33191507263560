<button
  fxLayout
  fxLayoutAlign="start center"
  mat-flat-button
  [class.button__primary]="type === 'primary' || type === 'primary-teal' || type === 'primary-grape'"
  [class.button__secondary]="type === 'secondary'"
  [class.button__text]="type === 'text'"
  [class.button__text-secondary]="type === 'text-secondary'"
  [class.button__small]="size === 'mini'"
  [class.button__primary-teal]="type === 'primary-teal'"
  [class.button__primary-grape]="type === 'primary-grape'"
  [class.button__secondary-danger]="type === 'secondary-danger'"
  [style.color]="labelColor"
  [class.disabled-btn]="loading || disabled"
  [disabled]="loading || disabled"
  [style.width]="_width"
  (click)="clicked.emit()">
  <img
    *ngIf="icon"
    class="button-icon"
    fxFlex="18px 0 0"
    [src]="'/assets/iconography/' + icon + '.svg'"
    style="margin-right: 6px"
    onload="SVGInject(this)" />

  <span class="label">{{ loadingLabel && loading ? loadingLabel : label }}</span>

  <ng-content></ng-content>

  <mat-spinner
    *ngIf="loading"
    class="button__spinner"
    [class.button__spinner-teal]="type === 'primary-teal'"
    [class.button__spinner-secondary]="type === 'secondary'"
    [diameter]="size === 'mini' ? 16 : 30"></mat-spinner>
</button>
