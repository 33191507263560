import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'sbnb-textarea',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="textarea-container">
      <textarea
        *ngIf="formControlRef"
        matInput
        [formControl]="formControlRef"
        autocomplete="off"
        [placeholder]="placeholder"
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="minRows"
        [cdkAutosizeMaxRows]="maxRows"></textarea>
    </div>
  `,
  styles: [
    `
      @import 'variables';

      .textarea-container {
        border: 1px solid $smoke;
        border-radius: $textarea-border-radius;
        padding: $textarea-padding;
      }

      textarea {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        resize: vertical;
      }
    `,
  ],
})
export class TextareaComponent {
  @Input() formControlRef: FormControl | AbstractControl;
  @Input() placeholder?: string;
  @Input() minRows = 2;
  @Input() maxRows = 8;

  constructor() {}
}
