import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { OnboardingService, OnboardingStore } from '@app/modules/onboarding/onboarding.service';
import { User } from '@app/shared/interfaces';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { SidebarCountsService } from '@app/shared/services/sidebar-counts/sidebar-counts.service';
import { Team, UserManagementService } from '@app/shared/services/user-management/user-management.service';
import { environment } from '@env/environment';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NavbarService } from './navbar.service';

declare var window: any;

export class NavItem {
  icon: string;
  link: string;
  tooltipText?: string;
  position: 'top' | 'bottom';
  external?: boolean;
  hidden?: boolean;
  hiddenMobile?: boolean;
  separator?: boolean;
  expandedText: string;
  expandedTooltipText: string;
  class?: string;
  badgeCountKey?: string;
  id?: string;
  comingSoon?: boolean;
}

@Component({
  selector: 'sbnb-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss', './nav.component.mobile.scss'],
  host: {
    '(document:click)': 'clickOutside($event)',
    '(document:touchend)': 'clickOutside($event)',
  },
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NavComponent implements OnInit, OnDestroy {
  private closeExpandedNavBarBelow = 1280; // number of pixels below which the navbar will not be in an expanded state. Even if the user preference is to have it expanded

  public navItems: NavItem[];
  public mobileMenuVisible: boolean;
  public currentUser$: Observable<User>;
  public expanded: boolean;
  environment = environment;
  debugMode$ = this.csService.debugMode;
  isCS: boolean;
  badgeCounts: object;
  url = window.location.href;
  overrideExpanded: boolean;
  teams: Team[];
  isTeamOwner = true;
  initialized: boolean;
  animEnabled: boolean;

  perms$ = this.userPermissionsService;

  palantirSrc = '/assets/images/palantir.svg';

  @ViewChild('mainNavigation', { static: false }) mainNavigation: ElementRef;
  @ViewChild('mobileButton') mobileButton: ElementRef;
  @Input('bg-mobile') bgMobile: string;

  constructor(
    private authService: AuthenticationService,
    private userPermissionsService: UserPermissionsService,
    private csService: InternalCsService,
    private userManagementService: UserManagementService,
    private sidebarCountsService: SidebarCountsService,
    private navbarService: NavbarService,
    private onboardingService: OnboardingService,
    public demoService: DemoService,
    private optimizely: OptimizelyService
  ) {}

  ngOnInit() {
    this.navbarService.expanded$.subscribe((res) => {
      this.expanded = res;
    });
    this.initialized = true;

    this.currentUser$ = this.authService.user$;

    this.initWndowResizeObserver().subscribe((width) => {
      this.overrideExpanded = width < this.closeExpandedNavBarBelow ? true : false;
    });

    this.userManagementService.getTeamsForUser().subscribe((res) => {
      this.teams = res;
      this.isTeamOwner = this.teams.some((team) => team.owner === true);
    });

    this.isCS = this.csService.isCS();

    combineLatest([this.currentUser$, this.userPermissionsService.permissions]).subscribe(([user, permissions]) => {
      if (user) {
        if (permissions.is_team_owner || permissions.is_admin || permissions.all_features) {
          this.navItems = this.getNavItems(user, [], true);
        } else {
          this.navItems = this.getNavItems(user, permissions.features);
        }
      }
    });

    this.getBadgeCounts();
  }

  initWndowResizeObserver(): Observable<number> {
    return fromEvent(window, 'resize').pipe(
      map((event: any) => (event.target as Window).innerWidth),
      startWith(window.innerWidth)
    );
  }

  checkIfNavbarIsScrollable() {
    if (this.mainNavigation) {
      if (
        this.mainNavigation.nativeElement.scrollHeight > this.mainNavigation.nativeElement.offsetHeight &&
        !this.expanded
      ) {
        this.mainNavigation.nativeElement.style.width = '100px';
      } else {
        this.mainNavigation.nativeElement.style.width = null;
      }
    }
  }

  getBadgeCounts() {
    this.sidebarCountsService.getCountsFromApi().subscribe((res) => {
      this.badgeCounts = res;

      this.onboardingService.onboardingItems.subscribe((items: OnboardingStore) => {
        // Don't show an unread count if the user has not tried connecting an account yet
        if (!items.meta.has_ever_connected_airbnb_account) {
          return;
        }

        this.badgeCounts['incomplete_onboarding_steps'] = items.meta.pending_count;
      });
    });
  }

  ngOnDestroy() {
    // this.userPermissions.unsubscribe();
  }

  getNavItems(user: User, features = [], all = false) {
    const allNavItems = {
      inbox: {
        icon: 'nav-inbox',
        link: '/inbox',
        expandedText: 'Inbox',
        expandedTooltipText: 'Your unified global inbox \n for guest communication.',
        tooltipText: 'Inbox',
        position: 'top',
        badgeCountKey: 'unread_messages',
        id: 'nav-inbox',
      },
      properties: {
        icon: 'nav-properties',
        link: '/properties',
        expandedText: 'Properties',
        expandedTooltipText: 'Manage your listings and properties.',
        tooltipText: 'Properties',
        position: 'top',
        badgeCountKey: 'new_properties',
        id: 'nav-properties',
      },
      calendar: {
        icon: 'nav-calendar',
        link: '/calendar',
        expandedText: 'Calendar',
        expandedTooltipText: 'Multicalendar for managing prices \n and availability.',
        tooltipText: 'Calendar',
        position: 'top',
        separator: false,
        id: 'nav-calendar',
      },
      gx: {
        icon: 'nav-gx',
        link: '/gx',
        expandedText: 'Guest Experience',
        expandedTooltipText: 'Messaging rules for automating \n guest communication.',
        tooltipText: 'Guest Experience',
        position: 'top',
        id: 'nav-gx',
      },
      ops: {
        icon: 'nav-ops',
        link: '/operations',
        expandedText: 'Operations',
        expandedTooltipText: 'Task assignment and team communication.',
        tooltipText: 'Operations',
        position: 'top',
        badgeCountKey: 'unassigned_tasks',
        id: 'nav-ops',
      },
      metrics: {
        icon: 'nav-metrics',
        link: '/metrics',
        expandedText: 'Metrics',
        expandedTooltipText: 'Data driven decisions for your business.',
        tooltipText: 'Metrics',
        position: 'top',
        id: 'nav-metrics',
      },
      connnectedAccounts: {
        icon: 'nav-connected-accounts',
        link: '/accounts',
        expandedText: 'Connected Accounts',
        expandedTooltipText: 'Connect your Airbnb, Vrbo & Booking.com accounts to Hospitable.',
        tooltipText: 'Connected Accounts',
        position: 'top',
        id: 'nav-connected-accounts',
      },
      direct: {
        icon: 'nav-direct',
        link: '/direct',
        expandedText: 'Direct',
        expandedTooltipText: 'Build your own website tailored to short-term rental bookings.',
        tooltipText: 'Direct',
        position: 'top',
        id: 'nav-direct',
        hidden: false,
      },
      devices: {
        icon: 'nav-devices',
        link: '/devices',
        expandedText: 'Devices',
        expandedTooltipText: 'Provide guests a seamless experience by using Hospitable to manage your smart devices.',
        tooltipText: 'Devices',
        position: 'top',
        id: 'nav-devices',
        hidden: false,
        comingSoon: false,
      },
      apps: {
        icon: 'nav-apps',
        link: '/apps',
        expandedText: 'Apps',
        expandedTooltipText: 'Expanded functionality through \n integrations, webhooks and API. ',
        tooltipText: 'Apps',
        position: 'bottom',
        id: 'nav-apps',
      },
      help: {
        icon: 'nav-help',
        link: '/support-documentation',
        expandedText: 'Help',
        expandedTooltipText: 'Learn more about Hospitable.',
        tooltipText: 'Support documentation',
        position: 'bottom',
        id: 'nav-help',
        badgeCountKey: 'incomplete_onboarding_steps',
      },
    };

    let nav = [];
    let allFeatures = [
      'inbox',
      'properties',
      'calendar',
      'gx',
      'ops',
      'metrics',
      'connnectedAccounts',
      'direct',
      'devices',
      'apps',
      'help',
      'changelog',
    ];
    if (all) {
      features = allFeatures;
    } else {
      features.push('help', 'changelog');
    }

    allFeatures.forEach(function (feature) {
      if (features.includes(feature) && allNavItems[feature]) {
        nav.push(allNavItems[feature]);
      }
    });

    if (features.includes('smartlocks_dashboard') && !all) {
      nav.push(allNavItems['devices']);
    }

    return nav;
  }

  public expandChange() {
    if (this.overrideExpanded) {
      this.showMobileNav();
      return;
    }

    this.navbarService.setExpanded(!this.expanded);
  }

  logout(): void {
    this.authService.logout();
  }

  showMobileNav(): void {
    setTimeout(() => {
      this.mobileMenuVisible = true;
    }, 10);
  }

  clickOutside(event: MouseEvent): void {
    if (!this.mainNavigation.nativeElement.contains(event.target) && this.mobileMenuVisible) {
      // Close the expanded nav bar
      this.mobileMenuVisible = false;
    }
  }

  setDebugMode() {
    this.csService.toggleDebugMode();
  }

  enableDemoMode(): void {
    this.demoService.toggleDemoMode(true);
  }

  changeActiveTeam(newTeamId: number) {
    return this.userManagementService.switchActiveTeam(newTeamId).subscribe((res) => {});
  }

  goToExternalLink(link: string) {
    if (link) {
      window.location.href = link;
    }
  }
}
