import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@app/core/app-config';
import { GetPropertiesAPIRes, PropertySettings } from '@app/modules/settings/models/property-settings';
import { GxAiPreviewData, GxAiPreviewMetadata, GxAiSettings } from '@app/shared/interfaces/lib/gx-settings.interface';
import { Channel } from '@app/shared/models/channel';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SegmentIoService } from '../segmentIo/segment-io.service';
import { Money } from '@app/shared/interfaces';

export interface EmailChangeRequestPayload {
  email: string;
}

export interface SyncSettings {
  key: string;
  value: boolean;
  title: string;
  label: string;
  disabled: boolean;
  coming_soon: boolean;
  refreshes: string;
  help_article?: string;
}

export interface SyncSettingsPostPayload {
  [key: string]: boolean;
}

export interface ListingReview {
  listings: Listing[];
  types: string[];
  meta: {
    total_listings: number;
  };
}

export interface Listing {
  listing_id: string;
  name: string;
  picture: string;
  type: 'hosted' | 'cohosted' | 'archived';
  muted?: boolean;
  property_id?: any;
  needs_primary_host_connection?: boolean;
}

export interface PrioritySupportQuote {
  charge_amount: Money;
  priority_support_base_addon_amount: string;
  adhoc_charge_until: string;
  priority_support_addon_amount_per_property: string;
}

export const calculateSupportTier = (hasPrioritySupport, supportPlanName) => {
  if (!hasPrioritySupport) {
    return 'basic';
  }
  if (supportPlanName === 'vip-priority-support') {
    return 'vip';
  }
  return 'premium';
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  settingsUrl = `${config.API_URL}/settings/personal`;
  passwordUrl = `${config.API_URL}/settings/security`;
  billingUrl = `${config.API_URL}/settings/billing`;
  subscsriptionUrl = `${config.API_URL}/settings/subscription`;
  subscribeUrl = `${config.API_URL}/settings/subscribe`;
  invoicesUrl = `${config.API_URL}/settings/invoices`;
  accountsUrl = `${config.API_URL}/settings/channels`;
  gxUrl = `${config.API_URL}/settings/gx`;
  notificationUrl = `${config.API_URL}/settings/notifications`;
  operationsUrl = `${config.API_URL}/settings/operations`;
  propertyUrl = `${config.API_URL}/settings/properties`;
  generalUrl = `${config.API_URL}/settings/general`;
  syncUrl = `${config.API_URL}/settings/sync`;
  prioritySupportUrl = `${config.API_URL}/billing/priority-support`;
  confirmEmailUrl = `${config.API_URL}/settings/personal/confirm-email`;
  emailChangeRequestsUrl = `${config.API_URL}/settings/personal/email-change`;

  constructor(
    private http: HttpClient,
    private readonly segmentIoService: SegmentIoService,
    private readonly router: Router
  ) {}

  getSubscribeData(): Observable<any> {
    return this.http.get(this.subscribeUrl).pipe(map((res: any) => res.data));
  }

  trySubscribe(plan_id: string, terms: boolean, payment_method_nonce: any, device_data: any) {
    return this.http
      .post(`${this.subscribeUrl}/${plan_id}`, {
        terms,
        payment_method_nonce,
        device_data,
      })
      .pipe(
        map((res) => res),
        catchError((err: HttpErrorResponse) => {
          alert(err.error.error);
          return of(false);
        })
      );
  }

  getProfile(): Observable<any> {
    return this.http.get(this.settingsUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getSubscription(): Observable<any> {
    return this.http.get(this.subscsriptionUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  cancelSubscription(id: string): Observable<any> {
    return this.http
      .request('delete', `${this.subscsriptionUrl}/${id}`, {
        body: { confirmed: true },
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getInvoices(): Observable<any> {
    return this.http.get(this.invoicesUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getInvoice(url: string): Observable<any> {
    return this.http.get(url).pipe(
      map((res: any) => {
        return res.url;
      })
    );
  }

  getAccounts(): Observable<any> {
    return this.http.get(this.accountsUrl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getGxSettings(): Observable<any> {
    return this.http.get(this.gxUrl, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  saveGxSettings(payload: any): Observable<any> {
    return this.http.put(this.gxUrl, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getGeneralSettings(): Observable<any> {
    return this.http.get(this.generalUrl, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  saveGeneralSettings(payload: any): Observable<any> {
    return this.http.put(this.generalUrl, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getNotificationSettings(): Observable<any> {
    return this.http.get(this.notificationUrl, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getOperationsSettings(): Observable<any> {
    return this.http.get(this.operationsUrl, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  saveNotificationSettings(payload: any): Observable<any> {
    return this.http.put(this.notificationUrl, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  saveOperationsSettings(payload: any): Observable<any> {
    return this.http.put(this.operationsUrl, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSyncSettings(): Observable<SyncSettings[]> {
    return this.http.get(this.syncUrl).pipe(
      map((res: { data: SyncSettings[] }) => {
        return res.data;
      })
    );
  }

  saveSyncSettings(payload: SyncSettingsPostPayload): Observable<any> {
    return this.http.post(this.syncUrl, payload);
  }

  disableSyncOnAllProperties() {
    return this.http.post(`${this.syncUrl}/reset`, {});
  }

  getPropertySettings(): Observable<PropertySettings> {
    return this.http.get(this.propertyUrl).pipe(
      map((res: GetPropertiesAPIRes) => {
        const propertySettings: PropertySettings = res;
        propertySettings.lead_platform = this.determineLeadPlatform(res);
        return propertySettings;
      })
    );
  }

  private determineLeadPlatform(propertyApiRes: GetPropertiesAPIRes): 'airbnb' | 'homeaway' | 'booking' {
    // if one is active, its the lead otherwise we return the first default that we see
    return (
      propertyApiRes.data.lead_platforms.find((x) => x.active)?.platform ??
      propertyApiRes.data.lead_platforms.find((x) => x.default)?.platform
    );
  }

  updatePropertySettings(payload: any): Observable<GetPropertiesAPIRes> {
    return this.http.put(this.propertyUrl, payload).pipe(
      map((res: GetPropertiesAPIRes) => {
        return res;
      })
    );
  }

  resetPropertyNames(): Observable<GetPropertiesAPIRes> {
    return this.http.put(this.propertyUrl, { reset_names: true }).pipe(
      map((res: GetPropertiesAPIRes) => {
        return res;
      })
    );
  }

  connectProperties(propertyIds: number[], platform: string, email: string, quickSetup = false): Observable<any> {
    return this.http
      .post(`${this.accountsUrl}/${platform}/${email}/connect`, {
        property_ids: propertyIds,
        login: email,
        quick_setup: quickSetup,
      })
      .pipe(
        map((res: any) => {
          this.segmentIoService.track('fe_booking_com_property_id_added', {
            label: 'Channels',
          });
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return of({
            error,
          });
        })
      );
  }

  getOccupancyBasedPricingSettings(propertyIds: number[], platform: string, email: string): Observable<any> {
    return this.http
      .post(`${this.accountsUrl}/${platform}/${email}/pricing`, {
        property_ids: propertyIds,
        login: email,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return of({
            error,
          });
        })
      );
  }

  setOccupancyBasedPricingSettings(propertiesRequest: any[], platform: string, email: string): Observable<any> {
    return this.http.put(`${this.accountsUrl}/${platform}/${email}/pricing`, propertiesRequest).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          error,
        });
      })
    );
  }

  addAccount(login: string, password: string, platform: string): Observable<any> {
    return this.http.post(this.accountsUrl, { login, password, platform }).pipe(
      map((res: any) => {
        this.checkForUpgradeToAirbnbOfficial(res);
        if (platform === Channel.BOOKING) {
          localStorage.setItem('account_login', login);
        }

        this.segmentIoService.track('fe_channel_connected', {
          label: 'Channels',
        });
        return res.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          error,
        });
      })
    );
  }

  // Certain other API responses will call this function to see if we received friction with Airbnb unofficial, and upgrade the user to the airbnb official flow
  checkForUpgradeToAirbnbOfficial(res: any) {
    if (res.airbnb_auth_url) {
      this.router.navigate(['/accounts/new/airbnb-official/' + btoa(res.airbnb_auth_url) + '/unofficial-upgrade']);
      throw 'Stop execution on the rest of the pipe - not seen, but must be thrown to stop the pipe processing';
    }
  }

  updateAccount(url: string, login: string, password: string, platform: string): Observable<any> {
    return this.http.put(url, { login, password, platform }).pipe(
      map((res: any) => {
        this.checkForUpgradeToAirbnbOfficial(res);
        return res.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          error,
        });
      })
    );
  }

  attemptAutoLogin(url: string, login: string, platform: string): Observable<any> {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

    return this.http.put(url, { login, platform }, { headers }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          error,
        });
      })
    );
  }

  resyncAccount(url: string): Observable<any> {
    return this.http.post(url, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteAccount(url: string): Observable<any> {
    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getVerifyOptions(url: string) {
    return this.http.get(url).pipe(
      map((res: any) => {
        this.checkForUpgradeToAirbnbOfficial(res);
        return res.data;
      })
    );
  }

  start2faWithOptions(url: string, type: string, id: number) {
    return this.http.post(url, { type, id }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  validate2faCode(url: string, type: string, id: number, code: string) {
    return this.http.post(url, { type, id, code }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          error,
        });
      })
    );
  }

  saveProfile(payload: any): Observable<any> {
    return this.http.put(this.settingsUrl, payload).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  savePassword(payload: any): Observable<any> {
    return this.http.put(this.passwordUrl, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getChargebeeHostedPage(): Promise<any> {
    return this.http
      .get(`${config.API_URL}/settings/subscribe/chargebee`)
      .pipe(
        map((res: any) => {
          return res.hosted_page;
        })
      )
      .toPromise();
  }

  fetchListings(platform: string, channelId: string): Observable<ListingReview> {
    return this.http.get(`${this.accountsUrl}/${platform}/${channelId}/listings`).pipe(
      map((res: any) => {
        return {
          listings: res.data,
          types: this.calculateListingTypes(res.data),
          meta: res.meta,
        };
      })
    );
  }

  fetchPropertiesList(channelLogin: string): any {
    return this.http.get(`${this.accountsUrl}/booking/${channelLogin}/properties/list`).pipe(
      map((res: any) => {
        return {
          properties: res.data,
          meta: res.meta,
        };
      })
    );
  }

  fetchPropertiesDetails(channelLogin: string, properties: any[]): any {
    return this.http
      .post(`${this.accountsUrl}/booking/${channelLogin}/properties/details`, { property_ids: properties })
      .pipe(
        map((res: any) => {
          return {
            properties: res.data,
            meta: res.meta,
          };
        })
      );
  }

  recheckPropertiesDetails(channelLogin: string, properties: any[]): any {
    return this.http
      .post(`${this.accountsUrl}/booking/${channelLogin}/properties/recheck`, { property_ids: properties })
      .pipe(
        map((res: any) => {
          return {
            properties: res.data,
            meta: res.meta,
          };
        })
      );
  }

  quickConnectProperties(channelLogin: string, properties: any[]): any {
    return this.http
      .post(`${this.accountsUrl}/booking/${channelLogin}/properties/connect`, { property_ids: properties })
      .pipe(
        map((res: any) => {
          return {
            properties: res.data,
            meta: res.meta,
          };
        })
      );
  }

  private calculateListingTypes(listings: Listing[]) {
    const values = [...new Set(listings.map((listing) => listing.type))];

    return values.sort(function (a, b) {
      const sort = {
        hosted: 1,
        cohosted: 2,
        archived: 3,
      };
      return sort[a] - sort[b];
    });
  }

  getPrioritySupportQuote(): Observable<PrioritySupportQuote> {
    return this.http.get<PrioritySupportQuote>(`${this.prioritySupportUrl}/quote`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  cancelPrioritySupport() {
    return this.http.delete(this.prioritySupportUrl);
  }

  upgradeToPrioritySupport() {
    return this.http.post(this.prioritySupportUrl, {});
  }

  public getEmailChangeRequests() {
    return this.http.get<EmailChangeRequestPayload>(`${this.emailChangeRequestsUrl}`, {});
  }

  // Proxy to the Laravel confirm email route
  public confirmEmail(token: string) {
    return this.http.post(`${this.confirmEmailUrl}/${token}`, { token });
  }

  public getGxAiSettings(): Observable<GxAiSettings> {
    return this.http.get<{ data: GxAiSettings }>(`${this.gxUrl}/ai`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  public updateGxAiSettings(settings: GxAiSettings) {
    return this.http.put(`${this.gxUrl}/ai`, settings);
  }

  public getDataForGxAiPreview(rulesetId: string | null): Observable<GxAiPreviewMetadata> {
    let url = `${this.gxUrl}/ai/preview`;
    if (rulesetId) {
      url += `?ruleset_id=${rulesetId}`;
    }
    return this.http.get<{ data: GxAiPreviewMetadata }>(url).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  public generateGxAiPreviewMessage(ruleId: number, threadUuid: string): Observable<GxAiPreviewData> {
    return this.http
      .post<{ data: GxAiPreviewData }>(`${this.gxUrl}/ai/preview`, {
        ruleset_id: ruleId,
        thread_uuid: threadUuid,
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  private handleError(err) {
    let errorMessage;

    if (err.error.errors) {
      errorMessage = '';
      Object.values(err.error.errors).forEach((error) => {
        if (error) {
          errorMessage += error;
        }
      });
    }

    return of({
      error: true,
      message: err.error.message,
      errorMessage,
    });
  }

  private parseForErrorMessages(err): string {
    if (!err.error.errors) {
      return err.error.message;
    }

    let errorMessage = '';

    Object.entries(err.error.errors).forEach((error) => {
      if (error) {
        errorMessage += error;
      }
    });

    return errorMessage;
  }

  private handlePasswordUpdateError(err) {
    let message: string;

    if (err.error.errors.current_password) {
      message = err.error.errors.current_password[0];
    }

    return of({
      error: true,
      message,
    });
  }
}
