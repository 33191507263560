import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, SimpleApiValidationError } from '@app/shared/interfaces';
import { Observable } from 'rxjs';
import {
  DeviceAddonResponse,
  DowngradeChecks,
  Plan,
  PlanId,
  StashedPaymentMethod,
  Subscription,
} from './billing.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {}

  loadSubscription(): Observable<Subscription> {
    return this.http.get<Subscription>(`${this.apiUrl}/settings/billing/subscription`);
  }

  loadPlans(): Observable<ApiResponse<Plan[]>> {
    return this.http.get<ApiResponse<Plan[]>>(`${this.apiUrl}/settings/billing/subscription/change-subscription-plans`);
  }

  changeSubscription(planId: PlanId): Observable<Record<string, unknown> | SimpleApiValidationError> {
    return this.http.post<Record<string, unknown>>(
      `${this.apiUrl}/settings/billing/subscription/change-subscription-plan`,
      {
        plan_id: planId,
      }
    );
  }

  attemptSubscribe(
    active_listings: number,
    stashedPaymentMethod?: StashedPaymentMethod,
    planId?: PlanId
  ): Observable<any> {
    const payload = { active_listings };

    if (stashedPaymentMethod) {
      payload['intent_id'] = stashedPaymentMethod.token;
    }

    if (planId) {
      payload['plan_id'] = planId;
    }

    return this.http.post(`${this.apiUrl}/settings/billing/subscribe`, payload);
  }

  checkDowngradeIssues(planId: PlanId): Observable<DowngradeChecks> {
    return this.http.get<DowngradeChecks>(`${this.apiUrl}/settings/billing/subscription/downgrade/${planId}`);
  }

  /**
   * This is presented to the user as an "addon". However,
   * in the database, its stored as a user fragment for the sole
   * purpose of tracking whether the user has agreed to be billed
   * @returns DeviceAddonResponse
   */
  loadDeviceAddon() {
    return this.http
      .get<{ data: DeviceAddonResponse }>(`${this.apiUrl}/billing/smart-devices`)
      .pipe(map((res) => res.data));
  }

  confirmDeviceAddon(): Observable<any> {
    return this.http.post(`${this.apiUrl}/billing/smart-devices`, {});
  }

  cancelSubscriptionImmediately(opts: { reason: string; textReason: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}/settings/billing/subscription/cancel`, opts);
  }
}
