import { config } from '@app/core/app-config';

interface IntegrationInfo {
  integration_id: string;
  integration: string;
  page_identifier: string;
  logo: string;
}

export enum IntegrationType {
  Schlage = 'Schlage',
  RemoteLock = 'RemoteLock',
  August = 'August',
  Yale = 'Yale',
  SmartThings = 'SmartThings',
  Nuki = 'Nuki',
  IglooHome = 'IglooHome',
  TTLock = 'TTLock',
}

export function resolveIntegrationInfo(currentURL: string): IntegrationInfo | null {
  const integrationConfig = {
    schlage: {
      id: config.SEAM_SCHLAGE_INTEGRATION_ID,
      name: IntegrationType.Schlage,
      logo: '/assets/images/smart-devices/integrations/schlage-circle.svg',
    },
    remotelock: {
      id: config.REMOTELOCK_INTEGRATION_ID,
      name: IntegrationType.RemoteLock,
      logo: '/assets/images/apps/integrations/remote-lock-circle.svg',
    },
    august: {
      id: config.AUGUST_INTEGRATION_ID,
      name: IntegrationType.August,
      logo: '/assets/images/smart-devices/integrations/august-lock-circle.svg',
    },
    yale: {
      id: config.YALE_INTEGRATION_ID,
      name: IntegrationType.Yale,
      logo: '/assets/images/smart-devices/integrations/yale-circle.svg',
    },
    smartthings: {
      id: config.SMARTTHINGS_INTEGRATION_ID,
      name: IntegrationType.SmartThings,
      logo: '/assets/images/smart-devices/integrations/smartthings-circle.svg',
    },
    nuki: {
      id: config.NUKI_INTEGRATION_ID,
      name: IntegrationType.Nuki,
      logo: '/assets/images/smart-devices/integrations/nuki-circle.svg',
    },
    igloohome: {
      id: config.IGLOO_INTEGRATION_ID,
      name: IntegrationType.IglooHome,
      logo: '/assets/images/smart-devices/integrations/igloo-circle.svg',
    },
    ttlock: {
      id: config.TTLOCK_INTEGRATION_ID,
      name: IntegrationType.TTLock,
      logo: '/assets/images/smart-devices/integrations/ttlock-circle.svg',
    },
  };

  const integrationKey = Object.keys(integrationConfig).find((key) => currentURL.includes(key));

  if (integrationKey) {
    const integrationData = {
      integration_id: integrationConfig[integrationKey].id,
      integration: integrationConfig[integrationKey].name,
      page_identifier: `apps-${integrationConfig[integrationKey].name.toLowerCase()}`,
      logo: integrationConfig[integrationKey].logo,
    };

    return integrationData;
  }

  return null;
}
