import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressAutocompleteComponent } from '@app/ui/address-autocomplete/address-autocomplete.component';
import { AddressAutocompleteComponentModule } from '@app/ui/address-autocomplete/address-autocomplete.component.module';
import { FullscreenUploadDropzoneComponent } from '@app/ui/fullscreen-upload-dropzone/fullscreen-upload-dropzone.component';
import { MaterialModule } from '@app/ui/material.module';
import { ProfileImgPlaceholderComponent } from '@app/ui/profile-img/profile-img-placeholder.component';
import { CloseComponent } from './close/close.component';
import {
  CollapsibleCardComponent,
  CollapsibleCardContentDirective,
} from './collapsible-card/collapsible-card.component';
import { ConfettiComponent } from './confetti/confetti.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { FullSizeDialogComponent } from './full-size-dialog/full-size-dialog.component';
import { HeroCardComponent } from './hero-card/hero-card.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ProfileImgComponent } from './profile-img/profile-img.component';
import { ShowMoreComponent } from './show-more/show-more.component';
import { SplitCardComponent } from './split-card/split-card.component';
import { StarsComponent } from './stars/stars.component';
import { RadioCardComponent } from './radio-card/radio-card.component';
import { AlertCardComponent } from './alert-card/alert-card.component';

import { IconComponent } from './icons/icon/icon.component';
import { SvgCacheService } from '@app/ui/icons/icon/svg-cache.service';
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component';
import { TogglePillsComponent } from './toggle-pills/toggle-pills.component';
import { DayToggleGroupComponent } from './day-toggle-group/day-toggle-group.component';

/**
 * - This module should not contain any business logic or import any
 * other modules besides Angular Material/3rd party UI.
 * - Does NOT use angular flex layout.
 * - Enforces strict templates.
 **/
@NgModule({
  declarations: [
    AlertCardComponent,
    CloseComponent,
    CollapsibleCardComponent,
    CollapsibleCardContentDirective,
    ConfettiComponent,
    CopyToClipboardComponent,
    DayToggleGroupComponent,
    FullSizeDialogComponent,
    FullscreenUploadDropzoneComponent,
    HeroCardComponent,
    IconComponent,
    LoadingSpinnerComponent,
    ProfileImgComponent,
    ProfileImgPlaceholderComponent,
    RadioCardComponent,
    ShowMoreComponent,
    SplitCardComponent,
    StarsComponent,
    TextareaComponent,
    ToggleButtonsComponent,
    TogglePillsComponent,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, AddressAutocompleteComponentModule],
  exports: [
    MaterialModule,
    AddressAutocompleteComponentModule,
    AddressAutocompleteComponent,
    AlertCardComponent,
    CloseComponent,
    CollapsibleCardComponent,
    CollapsibleCardContentDirective,
    ConfettiComponent,
    CopyToClipboardComponent,
    DayToggleGroupComponent,
    FullSizeDialogComponent,
    FullscreenUploadDropzoneComponent,
    HeroCardComponent,
    IconComponent,
    LoadingSpinnerComponent,
    ProfileImgComponent,
    ProfileImgPlaceholderComponent,
    RadioCardComponent,
    ShowMoreComponent,
    SplitCardComponent,
    StarsComponent,
    TextareaComponent,
    ToggleButtonsComponent,
    TogglePillsComponent,
  ],
  providers: [SvgCacheService],
})
export class UiModule {}
