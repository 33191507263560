<div *ngIf="isCS && (debugMode$ | async)" class="debug-item">
  <hr *ngIf="borderTop" />
  <strong *ngIf="label" class="mr-1">{{ label }}:</strong>
  <span
    *ngIf="value"
    matTooltip="This only ever shows for internal CS logins - click to copy the value to your clipboard"
    class="cs__debug inline-flex items-center gap-1"
    ngxClipboard
    [cbContent]="value">
    {{ value }}
    <mat-icon class="icon" inline="true">content_copy</mat-icon>
  </span>

  <span>&nbsp;</span>

  <span *ngIf="link && linkLabel">
    <a
      [href]="link"
      class="cs__debug"
      target="_blank"
      matTooltip="This only ever shows for internal CS logins - click to follow the link">
      {{ linkLabel }}
    </a>
  </span>

  <hr *ngIf="borderBottom" />
</div>
