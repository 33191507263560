import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertiesFacade } from '@app/shared/+state/properties';
import SwiperCore, { Keyboard, Pagination, Virtual } from 'swiper';

SwiperCore.use([Keyboard, Pagination, Virtual]);

@Component({
  selector: 'sbnb-dialog-properties-pms-check',
  templateUrl: './dialog-properties-pms-check.component.html',
  styleUrls: ['./dialog-properties-pms-check.component.scss'],
})
export class DialogPropertiesPmsCheckComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogPropertiesPmsCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private propertiesFacade: PropertiesFacade
  ) {}

  acknowledge() {
    this.propertiesFacade.acknowledgePropertiesPMSCheck();
    this.dialogRef.close();
  }
}
