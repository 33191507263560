import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToList',
})
export class ArrayToListPipe implements PipeTransform {
  transform(value: string[]): string {
    // fairly robust support but catch any errors and just provide a comma separated list
    // https://caniuse.com/mdn-javascript_builtins_intl_listformat_format
    try {
      // @ts-expect-error - this method exists
      const formatter = new Intl.ListFormat('en', {
        style: 'long',
        type: 'conjunction',
      });
      return formatter.format(value);
    } catch (e) {
      return value.join(', ');
    }
  }
}
