import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@app/core/app-config';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import { BillingFacade } from '@app/shared/+state/billing';
import { UsersFacade } from '@app/shared/+state/users';
import { LoginFlow, LoginFlowApiResponse, LoginResult } from '@app/shared/+state/users/users.models';
import { LocalStorageKey, User } from '@app/shared/interfaces';
import { Error } from '@app/shared/models/error';
import { ApiCacheService } from '@app/shared/services/api-cache.service';
import { DelightedService } from '@app/shared/services/delighted.service';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { OnesignalService } from '@app/shared/services/onesignal/onesignal.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { PusherService } from '@app/shared/services/pusher/pusher.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { decodeToRealUserId, retrieveUserFromLocalStorage } from '@app/shared/utils';
import { environment } from '@env/environment';
import * as addYears from 'date-fns/add_years';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { setSentryContext } from 'src/sentry';
import UniversalCookie from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { NotificationsService } from '../notifications/notifications.service';
import { UserPermissionsService } from '../user-permissions/user-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public cookieStorageKeys = {
    isLoggedIn: 'isLoggedIn',
  };

  private headers = {
    nonce2faHeader: 'X-Hospitable-2FA-Nonce',
  };

  private loginUrl = `${config.API_URL}/auth/login`;
  private requestMagicLinkUrl = `${config.API_URL}/auth/magic-link`;
  private requestMagicCodeUrl = `${config.API_URL}/auth/magic-code`;
  private passwordReminderUrl = `${config.API_URL}/auth/forgotten-password`;
  private resetPasswordUrl = `${config.API_URL}/auth/reset-password`;
  private userUrl = `${config.API_URL}/user`;

  public isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly isLoggedIn$: Observable<boolean> = from(this.isLoggedInSubject.asObservable());

  private _user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public readonly user$: Observable<User> = this._user$.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private segmentIoService: SegmentIoService,
    private pusherService: PusherService,
    private notificationsService: NotificationsService,
    private onesignalService: OnesignalService,
    private optimizelyService: OptimizelyService,
    private demoService: DemoService,
    private permissionsService: UserPermissionsService,
    private directSubscriptionService: DirectSubscriptionService,
    private apiCacheService: ApiCacheService,
    private delightedService: DelightedService,
    private billingFacade: BillingFacade,
    private usersFacade: UsersFacade,
    private posthog: PosthogService
  ) {}

  public login(
    email: string,
    password: string,
    redirectUrl?: string,
    requestedFlow?: LoginFlow
  ): Observable<LoginResult | Error> {
    const payload = { email, password };

    if (requestedFlow) {
      payload['flow'] = requestedFlow;
    }

    return this.http
      .post<
        { data: User } | LoginFlowApiResponse
      >(this.loginUrl, payload, { headers: this.setTwoFactorAuthUuidHeaders() })
      .pipe(
        map((res) => {
          // We've got a user and a token, so we can login
          if ('data' in res && 'token' in res.data) {
            this.setSession(res.data.token, res.data);
            this.loginActions(redirectUrl ?? config.DEFAULT_PAGE);
            return {
              success: true,
              flow: null,
              device_trusted: true,
            };
          }

          // We've been presented a challenge - code or link to handle
          if ('flow' in res) {
            return {
              success: false,
              flow: res.flow,
              device_trusted: res.device_trusted,
            };
          }

          // We received a response, but with neither a user or a challenge, so something went wrong
          this.segmentIoService.track('fe_login_error', {});

          return {
            success: false,
            flow: null,
            device_trusted: false,
          };
        }),
        catchError(this.handleError)
      );
  }

  public loginActions(redirectUrl?: string) {
    this.usersFacade.resetStore();
    this.permissionsService.getPermissionsFromApi().subscribe();
    this.usersFacade.loadUser();
    this.pusherService.init();

    this.directSubscriptionService.getSubscriptionStatus().subscribe();

    this.notificationsService.fetchNotificationsFromRest().subscribe();

    this.optimizelyService.init();

    const user = this.getUserDetails(true);
    const userId = decodeToRealUserId(user.id, user.email) ?? '0';

    // Identify with, and initialise Segment
    this.segmentIoService.identify(userId, user);

    this.onesignalService.initialiseOneSignal(user.id_hash, user.email);

    this.delightedService.init();

    this.posthog.identifyUser(userId, user);

    // https://posthog.com/docs/feature-flags/adding-feature-flag-code#reloading-feature-flags
    // "Feature flag values are cached. If something has changed with your user and you'd like to refetch their flag values, call:"
    this.posthog.reloadFeatureFlags();

    if (redirectUrl) {
      this.redirect(redirectUrl);
    }
  }

  // Requests a password reset
  public passwordReset(email: string): Observable<boolean> {
    return this.http.post<any>(this.passwordReminderUrl, { email }).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => of(err))
    );
  }

  // Actually sets a new password
  public setNewPassword(identifier: string, newPassword: string) {
    return this.http.post(this.resetPasswordUrl, { identifier, newPassword }).pipe(
      map((res: any) => {
        res = res.data;
        if (res['token']) {
          this.setSession(res['token'], res);

          if (this.isLoggedIn()) {
            // Redirect to our onboarding url
            this.loginActions(config.DEFAULT_PAGE);
          }
        } else {
          return res;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return of({ error: true, message: err.error.message, err });
      })
    );
  }

  private redirect(url) {
    this.router.navigate([url]);
  }

  public logout(redirect = true): void {
    localStorage.removeItem('isCS');
    this.pusherService.closeDown();
    this.segmentIoService.logout();
    this.notificationsService.closeDown();
    this.onesignalService.shutdownOneSignal();
    this.apiCacheService.clear();

    this.posthog.loaded$.subscribe((loaded) => {
      if (loaded) {
        this.posthog.reset();
      }
    });

    this.clearSession();

    this.segmentIoService.logout();
    this.demoService.disableDemoMode();

    if (redirect) {
      this.redirect('/user/hello');
    }
  }

  public isLoggedIn(): boolean {
    const userObj = retrieveUserFromLocalStorage();

    return localStorage.getItem(LocalStorageKey.accessToken) && userObj?.id ? true : false;
  }

  public getCurrentToken(): string {
    return localStorage.getItem(LocalStorageKey.accessToken);
  }

  public getUserDetails(forceRefresh = false): User {
    if (this.isLoggedIn()) {
      let userObj = retrieveUserFromLocalStorage();
      const isUserInLocalStorage = userObj && userObj.id ? true : false;

      if (!isUserInLocalStorage || forceRefresh) {
        this.getUserDetailsFromApi().subscribe((res) => {
          console.log(`[AuthService]: getUserDetails() - had no local user object, refetching`);

          // We had some instances of the user object getting populated with {} from the API, so belt and braces
          if (!res.id) {
            return;
          }

          userObj = res;
          localStorage.setItem(LocalStorageKey.user, JSON.stringify(res));

          this._user$.next(userObj);
        });
      }

      if (userObj && userObj.picture && userObj.picture.startsWith('http://')) {
        userObj.picture.replace('http://', 'https://');
      }
      const { id, name, email } = userObj;
      setSentryContext({ email, name, id: decodeToRealUserId(id, email) });
      return userObj;
    } else {
      console.log(`[AuthService]: getUserDetails() - not logged in`);
    }
  }

  public getUserDetailsFromApi() {
    return this.http.get<any>(this.userUrl).pipe(
      map((res) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  // Returns 0 for sunday, 1 for monday
  public getStartOfWeekPreference(): number {
    const user = this.getUserDetails();

    if (!user) {
      console.log(`[AuthService]: getStartOfWeekPreference() - no user found`);
      return 0;
    }

    const setPreference = user.week_starts_on;

    if (setPreference) {
      if (setPreference === 'sunday') {
        return 0;
      } else {
        return 1;
      }
    }

    // Preference has not been set, fall back to brower locale:
    // `en-US` → `sunday`
    // `<any other>` → `monday`
    if (navigator?.language.toLowerCase() === 'en-us') {
      return 0;
    } else {
      return 1;
    }
  }

  public isCS(): boolean {
    // Are we a member of our fine Customer Services team?
    const isCS = localStorage.getItem('isCS');

    if (isCS === 'true') {
      return true;
    } else {
      return false;
    }
  }

  public getTimezoneOffset(): number {
    if (this.isLoggedIn()) {
      const userObj = retrieveUserFromLocalStorage();

      if (userObj && userObj.timezone_offset) {
        return userObj.timezone_offset;
      }

      return 0;
    }
  }

  public setSession(accessToken, user): void {
    localStorage.setItem(LocalStorageKey.accessToken, accessToken);
    delete user.token;
    localStorage.setItem(LocalStorageKey.user, JSON.stringify(user));
    this.setCookie(this.cookieStorageKeys.isLoggedIn);

    this.isLoggedInSubject.next(true);
  }

  public clearSession() {
    localStorage.removeItem(LocalStorageKey.accessToken);
    localStorage.removeItem(LocalStorageKey.refreshToken);
    localStorage.removeItem(LocalStorageKey.user);
    localStorage.removeItem(LocalStorageKey.featureFlags);
    localStorage.removeItem(LocalStorageKey.featureFlagsPostHog);
    this.removeCookie(this.cookieStorageKeys.isLoggedIn);
    this.isLoggedInSubject.next(false);
  }

  public setCookie(key) {
    const universalCookie = new UniversalCookie();

    if (universalCookie) {
      universalCookie.set(key, true, {
        expires: addYears(new Date(), 1),
        path: '/',
        domain: '.hospitable.com',
      });
    }
  }

  public removeCookie(key) {
    const universalCookie = new UniversalCookie();
    if (universalCookie) {
      universalCookie.remove(key, {
        path: '/',
        domain: '.hospitable.com',
      });
    }
  }

  public requestLoginMagicLink(email: string, flow?: LoginFlow) {
    const payload = {
      email,
    };

    if (flow) {
      payload['flow'] = flow;
    }

    return this.http.post<{ flow: LoginFlow }>(`${this.requestMagicLinkUrl}`, payload, {
      headers: this.setTwoFactorAuthUuidHeaders(),
    });
  }

  useLoginMagicCode(email: string, code: string, trust = false) {
    const payload = {
      email,
      code,
    };

    let url = `${environment.apiUrl}/auth/device/code`;

    if (!trust) {
      url += '?once=true';
    }

    return this.http.post(url, payload, { headers: this.setTwoFactorAuthUuidHeaders() });
  }

  // Logs a user in given a magic token
  processMagicLink(token: string, trust = false) {
    let url = `${environment.apiUrl}/auth/device/link/${token}`;

    if (!trust) {
      url += '?once=true';
    }

    return this.http.post(url, {}, { headers: this.setTwoFactorAuthUuidHeaders() });
  }

  public checkFor2faNonce() {
    const nonce = this.get2faNonce();

    if (nonce) {
      return nonce;
    } else {
      this.generate2faNonce();
    }
  }

  public get2faNonce() {
    return localStorage.getItem(LocalStorageKey.twoFactorAuthUuid);
  }

  public generate2faNonce() {
    const nonce = uuidv4();
    localStorage.setItem(LocalStorageKey.twoFactorAuthUuid, nonce);

    return nonce;
  }

  private setTwoFactorAuthUuidHeaders(): HttpHeaders {
    const deviceUuid = this.get2faNonce();
    const headers: HttpHeaders = new HttpHeaders({
      [this.headers.nonce2faHeader]: deviceUuid,
    });

    return headers;
  }

  private handleError(res: HttpErrorResponse): Observable<Error> {
    // Add some logging

    const error: Error = {
      errorCode: res?.error?.code,
      errorDetail: res?.error?.message,
    };

    return of(error);
  }
}
