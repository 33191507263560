import { User } from '@app/shared/interfaces';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';

const SENTRY_DSN = 'https://236a8dca8f6cd604f5dc96695c9013b9@us.sentry.io/4506700527435776';

export const initSentry = () => {
  if (!environment.production) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    release: environment.version,
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.hospitable\.com/],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    sampleRate: 0.01, // Capture 1% of the transactions
    // Performance Monitoring
    tracesSampleRate: 0.01, //  Capture 1% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    allowUrls: [/https?:\/\/((api|my)\.)?hospitable\.com/],
  });
};

// The id in the main user object is obfuscated and needs to be decoded,
// so the types are separated to avoid confusion.
export const setSentryContext = ({ id, email, name: username }): Pick<User, 'email' | 'name'> & { id: string } => {
  if (!environment.production) return;

  Sentry.setContext('user', {
    email,
    id,
    username,
  });
};

export const captureException = (err: unknown) => {
  if (!environment.production) return;

  Sentry.captureException(err);
};
