import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditKnowledgeComponent } from '@app/modules/knowledge/components/dialog-edit-knowledge/dialog-edit-knowledge.component';
import { KnowledgeState, KnowledgeUploadProgress } from '@app/modules/knowledge/models/knowledge.interface';
import { KnowledgeService } from '@app/modules/knowledge/services/knowledge.service';
import { DialogDeleteGenericComponent } from '@app/shared/components/dialog-delete-generic/dialog-delete-generic.component';
import { Property } from '@app/shared/interfaces';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sbnb-knowledge',
  templateUrl: './knowledge.component.html',
  styles: [
    `
      @import 'variables';

      .expand-open {
        transform: scaleY(-1);
      }

      :host ::ng-deep .question-icon path,
      :host ::ng-deep .question-icon g {
        stroke: $grape-darker;
      }
    `,
  ],
})
export class KnowledgeComponent implements OnChanges {
  @Input() propertyId: Property['id'] | null = null;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  knowledge$ = this.knowledgeService.knowledge$;
  searchCriteria: BehaviorSubject<string> = new BehaviorSubject('');

  aiToggleOpen = false;

  filteredKnowledge$ = combineLatest([this.knowledge$, this.searchCriteria]).pipe(
    map(([knowledgeState, search]) => {
      if (search.trim().length) {
        return knowledgeState.knowledge.filter((item) => {
          return (
            item.topic.toLowerCase().includes(search.toLowerCase()) ||
            item.items.some((item) => item.content.toLowerCase().includes(search.toLowerCase()))
          );
        });
      } else {
        return knowledgeState.knowledge;
      }
    })
  );

  constructor(
    private knowledgeService: KnowledgeService,
    private dialog: MatDialog,
    private segmentIoService: SegmentIoService,
    private posthog: PosthogService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['propertyId']) {
      this.fetchKnowledgeForProperty(this.propertyId);
    }
  }

  onTestResponse(question: KnowledgeState['questions'][0]) {
    question.computed_loading = true;

    this.knowledgeService.testResponse(this.propertyId, question.query).subscribe({
      next: (reply) => {
        question.computed_loading = false;
        question.computed_reply = reply;
      },
      error: () => {
        question.computed_loading = false;
      },
    });
  }

  onRewriteAllInformation() {
    if (this.fileUploadInput) {
      this.fileUploadInput.nativeElement.click();
    } else {
      alert('fileUploadInput is not defined');
    }
  }

  fetchKnowledgeForProperty(propertyId: Property['id']) {
    return this.knowledgeService.fetchKnowledgeForProperty(propertyId);
  }

  onImportPDFClicked(fileInput: HTMLInputElement) {
    const file: File = fileInput.files[0];
    const reader = new FileReader();

    this.segmentIoService.track(SegmentEvent.KnowledgeHubImportStarted, {
      import_type: 'pdf',
    });

    reader.onload = () => {
      this.knowledgeService.uploadPDF(this.propertyId, file);
    };

    reader.readAsDataURL(file);
  }

  openEditDialog(itemId: number, propertyId: Property['id'], content: string, topicName: string) {
    this.dialog.open(DialogEditKnowledgeComponent, {
      width: '800px',
      data: {
        itemId: itemId,
        propertyId: propertyId,
        content: content,
        topicName: topicName,
      },
    });
  }

  onDeleteItemClicked(id: number, propertyId: Property['id']) {
    const dialogRef = this.dialog.open(DialogDeleteGenericComponent, {
      width: '800px',
      data: {
        name: 'this knowledge item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteItem(id, propertyId);
      }
    });
  }

  deleteItem(id: number, propertyId: Property['id']) {
    this.knowledgeService.deleteKnowledgeItem(id, propertyId);
  }

  trackProgressFn(index: number, item: KnowledgeUploadProgress) {
    return item.state;
  }

  triggerPosthogFeedback() {
    this.posthog.capture(SegmentEvent.KnowledgeHubImportSurveyTriggered, {
      import_type: 'pdf',
    });
  }

  onAiToggle(event: { open: boolean }) {
    this.aiToggleOpen = event.open;

    if (this.aiToggleOpen) {
      // scroll to the top of the window
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
