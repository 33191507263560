<div fxLayout="column" fxLayoutAlign="space-between">
  <div fxFlex>
    <h3>Another property management software is connected to one or more of your Vrbo listings</h3>
    <p>
      These Vrbo listing calendars cannot be updated or synced while another property management software (PMS) is
      connected.
    </p>
    <p>
      <a
        href="https://help.hospitable.com/en/articles/6139500-vrbo-limitations-when-connected-to-another-property-management-software"
        class="raspberry-darker"
        target="_blank">
        Click here to learn more about these limitations
      </a>
    </p>
  </div>

  <div fxLayout fxLayoutAlign="stretch center" fxLayoutGap="12px">
    <swiper
      [slidesPerView]="1"
      [navigation]="false"
      [pagination]="{ type: 'bullets', clickable: true }"
      [keyboard]="{
        enabled: true
      }"
      [virtual]="false"
      class="dialog-image-swiper">
      <ng-template swiperSlide>
        <div class="swiper-slide-container">
          <section class="sbnb-onboarding one-column">
            <article class="column">
              <div style="display: flex; justify-content: center; align-items: center; height: 100%">
                <img
                  src="/assets/images/pms/connected-accounts-calendar-restricted.png"
                  alt="connected-accounts-calendar-restricted..." />
              </div>
            </article>
          </section>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="swiper-slide-container">
          <section class="sbnb-onboarding one-column">
            <article class="column">
              <div style="display: flex; justify-content: center; align-items: center; height: 100%">
                <img src="/assets/images/pms/property-calendar-restricted.png" alt="property-calendar-restricted..." />
              </div>
            </article>
          </section>
        </div>
      </ng-template>

      <div class="swiper-pagination"></div>
    </swiper>

    <div class="swiper-pagination-bullets"></div>
  </div>

  <div fxLayoutAlign="end center" fxLayoutGap="8px" style="margin-top: 40px">
    <button type="submit" mat-flat-button (click)="acknowledge()">I Understand</button>
  </div>
</div>
