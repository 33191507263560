import { Injectable } from '@angular/core';
import { SegmentEvent, LocalStorageKey, User } from '@app/shared/interfaces';
import { environment } from '@env/environment';
import { DeviceDetectionService } from '../device-detection/device-detection.service';
import { Logger } from '@app/shared/utils';

export { SegmentEvent as SegmentEvent };

@Injectable({
  providedIn: 'root',
})
export class SegmentIoService {
  constructor(private readonly deviceDetectionService: DeviceDetectionService) {}

  isProd() {
    if (environment.production && localStorage.getItem('isCS') !== 'true') {
      return true;
    } else {
      return false;
    }
  }

  identify(userId: string, payload: Partial<User>) {
    if (!this.isProd()) {
      return;
    }

    const featureFlagsStringified = localStorage.getItem(LocalStorageKey.featureFlags);

    if (featureFlagsStringified) {
      const featureFlags = JSON.parse(featureFlagsStringified);

      if (featureFlags.length > 0) {
        featureFlags.forEach((flag) => {
          payload['feature_' + flag] = '1';
        });
      }
    }

    try {
      const featureFlagsStringifiedPostHog = localStorage.getItem(LocalStorageKey.featureFlagsPostHog);

      if (featureFlagsStringifiedPostHog) {
        const featureFlags: string[] | null = JSON.parse(featureFlagsStringifiedPostHog);

        if (Array.isArray(featureFlags) && featureFlags.length > 0) {
          featureFlags.forEach((flag) => {
            // A system-level flag that we don't control and don't want to send to Segment/Intercom.
            if (flag.startsWith('survey-targeting')) {
              return;
            }
            payload['feature_' + flag] = '1';
          });
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (payload.created_at) {
      payload.createdAt = payload.created_at;
    }

    const identifyExtras = {
      Intercom: {
        user_hash: payload.intercom_hash ? payload.intercom_hash : 'missing',
      },
    };

    const identifyPayload = {
      ...payload,
    };

    if (payload.team?.id) {
      identifyPayload['active_team_id'] = payload.team.id;
    }

    (<any>window).analytics.identify(userId, identifyPayload, identifyExtras);
  }

  page(url: string) {
    if (!this.isProd()) {
      return;
    }

    let hideIntercom = false;

    if (this.deviceDetectionService.isAndroidApp() || this.deviceDetectionService.isIosApp()) {
      hideIntercom = true;
    }

    // (<any>window).analytics.page(url, {}, { Intercom: { hideDefaultLauncher: hideIntercom } });
    (<any>window).analytics.page(url, {}, {});
  }

  track(action: SegmentEvent, payload: any) {
    try {
      if (!this.isProd()) {
        Logger.info(`[Segment Event Preview]: ${action}`);
        return;
      }
      Logger.info(`[Segment Event]: ${action}`);
      (<any>window).analytics.track(action, payload);
    } catch (err) {
      console.error(err);
    }
  }

  logout() {
    if (!this.isProd()) {
      return;
    }
    if ((window as any).Intercom) {
      (window as any).Intercom('shutdown');
    }

    (<any>window).analytics.reset();
  }
}
