export enum EventType {
  PageView = 'Page Viewed',
  Action = 'Action',
}

export enum EventNames {
  /*******  Acquisition — “Where Are Our Users / Customers Coming From?”    *******/
  SignUpWithAirbnbShown = 'fe_signup_with_airbnb_shown',
  StartedSignUpWithAirbnb = 'fe_started_signup_with_airbnb',
  SignUpWithAirbnbError = 'fe_signup_with_airbnb_error',
  SignUpWithAirbnbSuccess = 'fe_signup_with_airbnb_success',

  /*******  Activation — “How good is the user’s / customer’s first experience?”    *******/
  ClickedTrialBannerPrimaryCta = 'fe_clicked_trial_banner_primary_cta',
  ClickedTrialBannerSecondaryCta = 'fe_clicked_trial_banner_secondary_cta',
  ClickedTrialBannerVideo = 'fe_clicked_trial_banner_video',

  /*******  Retention — “How many of your customers are you retaining and why are you losing the others?”    *******/
  StartedMergeMatch = 'fe_merge_match_started',
  MergeMatchError = 'fe_merge_match_error',
  MergeMatchOutcome = 'fe_merge_match_outcome',

  ClickedNovaContentLink = 'fe_clicked_nova_content_link',
  ClickedNovaSidebarLink = 'fe_clicked_nova_sidebar_link',
  ClickedNovaIntroLink = 'fe_clicked_nova_intro_link',
  ClickedNovaDefaultLink = 'fe_clicked_nova_default_link',

  SignUpClickedEnableAllRuleSets = 'fe_signup_clicked_enable_all_rulesets',
  SignUpNoSavedRuleSets = 'fe_signup_no_saved_rulesets',
  SignUpSavedRuleSets = 'fe_signup_saved_rulesets',
  SignUpSavedRuleSetsError = 'fe_signup_saved_rulesets_error',
  SignUpScheduledSalesCall = 'fe_signup_scheduled_sales_call',

  UpdateSupportPreference = 'fe_updated_support_preference',

  StartedCalendarBulkEdit = 'fe_started_calendar_bulk_edit',
  ClickedCalendarBulkEditDay = 'fe_clicked_calendar_bulk_edit_day',

  MessageSentFromTheInbox = 'Message sent from the inbox',

  /*******  Referral — “How can you turn your customers into your advocates?”    *******/

  /*******  Other    *******/
  OptimizelyError = 'fe_optimizely_error',
  CalendlyFailedToLoad = 'fe_signup_calendly_failed_to_load',
  DemoMode412 = 'fe_demo_mode_412',
  CalendarConflictingEventsDialogOpened = 'fe_calendar_conflicting_events_dialog_opened',

  // AI Usage
  AIDraftMessageGenerated = 'Draft message generated',
  AIPrewrittenDraftMessageViewed = 'Prewritten draft message viewed',
  AIDraftMessageSentFromTheInbox = 'Draft message sent from the inbox',
  AIDraftMessageImproved = 'Draft message improved',
  AIUndoImprovement = 'Undo draft message',
  AIReviewReplySent = 'Review reply sent',
  AIReviewEdited = 'AI review edited',
  AIReviewEditActioned = 'Review Edit Actioned',
  AIEngaged = 'Engaged with AI',
  AISuggestionLiked = 'AI Suggestion Liked',
  AISuggestionDisliked = 'AI Suggestion Disliked',
  AISuggestionSurveyTriggered = 'AI Suggestion Survey Triggered',

  // Knowledge Hub
  KnowledgeHubImportStarted = 'Knowledge Hub Import Started',
  KnowledgeHubImported = 'Knowledge Hub Imported',
  KnowledgeHubImportSurveyTriggered = 'Knowledge Hub Import Survey Triggered',

  // Direct
  CreateDirectQuote = 'Create direct quote',
  CreateDirectQuoteError = 'Create direct quote error',
  SetAmountOnDirectQuote = 'Set amount on direct quote',
  SetGuestDetailsOnDirectQuote = 'Set guest details on direct quote',
  SetExpirationOnDirectQuote = 'Set expiration on direct quote',
  CreateDirectCustomQuote = 'Create direct custom quote',
  CreateDirectCustomQuoteError = 'Create direct custom quote error',

  // Rental agreements
  CreateRentalAgreementTemplate = 'Created rental agreement template',
  CreateRentalAgreementTemplateError = 'Created rental agreement template error',
  UpdateRentalAgreementTemplate = 'Updated rental agreement template',
  UpdateRentalAgreementTemplateError = 'Updated rental agreement template error',
  DeleteRentalAgreementTemplate = 'Deleted rental agreement template',
  DeleteRentalAgreementTemplateError = 'Deleted rental agreement template error',

  // Owner Statements
  OwnerStatementShareFeedbackDashboard = 'fe_owner_statements_share_feedback_dashboard',
  OwnerStatementsPropertyLimitReached = 'owner_statements_property_limit_reached',

  // Billing
  DirectPaymentCardsAuthorize3dsStarted = 'fe_direct_payment_cards_authorize_3ds_started',
  DirectPaymentCardsAuthorize3dsReturned = 'fe_direct_payment_cards_authorize_3ds_returned',
  DirectPaymentCardsAuthorize3dsFailed = 'fe_direct_payment_cards_authorize_3ds_failed',
}

export interface AnalyticsEvent {
  type: EventType;
  name: EventNames | string;
  parameters?: Record<string, unknown>;
  options?: unknown;
  meta?: unknown;
}
