import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as PropertiesActions from './properties.actions';
import * as PropertiesSelectors from './properties.selectors';
import { PropertiesService } from './properties.service';
import { RequestOverride } from '../shared/request-override.interface';

@Injectable()
export class PropertiesFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  propertiesLoadingState$ = this.store.pipe(select(PropertiesSelectors.getPropertiesLoadingState));
  properties$ = this.store.pipe(select(PropertiesSelectors.getProperties));
  propertyEntities$ = this.store.pipe(select(PropertiesSelectors.getPropertyEntities));

  propertiesMergeCandidatesLoadingState$ = this.store.pipe(
    select(PropertiesSelectors.getPropertiesMergeCandidatesLoadingState)
  );
  propertiesMergeCandidateEntities$ = this.store.pipe(select(PropertiesSelectors.getPropertiesMergeCandidateEntities));
  propertiesPMSCheck$ = this.store.pipe(select(PropertiesSelectors.getPropertiesPMSCheck));
  bulkMuteAndUnMutePropertiesLoadingState$ = this.store.pipe(
    select(PropertiesSelectors.getBulkMuteAndUnMutePropertiesLoadingState)
  );

  constructor(
    private readonly store: Store,
    private propertiesService: PropertiesService
  ) {}

  loadProperties(
    paginate?: boolean,
    filterCriteria?: any[],
    offset?: number,
    search?: string,
    limit?: number,
    transformer?: string, // simple
    idList?: any[]
  ) {
    this.store.dispatch(
      PropertiesActions.loadProperties({
        paginate,
        filterCriteria,
        offset,
        search,
        limit,
        transformer,
        idList,
      })
    );
  }

  loadPropertiesMergeCandidates(propertyIds: string[], listingIds?: string[]) {
    this.store.dispatch(
      PropertiesActions.loadPropertiesMergeCandidates({
        propertyIds,
        listingIds,
      })
    );
  }

  mergeProperty(propertyId: string, mergeWithPropertyId: string, viaAction: boolean = false): Observable<any> {
    viaAction
      ? console.error(
          'You are trying to merge a property via an NGRX action, but it does not exist. Please create the NGRX action'
        )
      : null;
    // TODO -> when updating current merging process to use ngrx return the appropriate action here
    return viaAction ? null : this.propertiesService.mergeProperty(propertyId, mergeWithPropertyId);
  }

  muteProperty(propertyId: string, viaAction: boolean = false): Observable<any> {
    viaAction
      ? console.error(
          'You are trying to mute a property via an NGRX action, but it does not exist. Please create the NGRX action'
        )
      : null;
    // TODO -> when updating current muting process to use ngrx return the appropriate action here
    return viaAction ? null : this.propertiesService.muteProperty(propertyId);
  }

  unmuteProperty(propertyId: string, viaAction: boolean = false): Observable<any> {
    viaAction
      ? console.error(
          'You are trying to unmute a property via an NGRX action, but it does not exist. Please create the NGRX action'
        )
      : null;
    // TODO -> when updating current unmuting process to use ngrx return the appropriate action here
    return viaAction ? null : this.propertiesService.unmuteProperty(propertyId);
  }

  propertiesPMSCheck(): void {
    this.store.dispatch(PropertiesActions.propertiesPMSCheck());
  }

  acknowledgePropertiesPMSCheck(): void {
    this.store.dispatch(PropertiesActions.acknowledgePropertiesPMSCheck());
  }

  bulkMuteAndUnMuteProperties(mute: string[], unmute: string[], requestOverride?: RequestOverride) {
    return this.store.dispatch(PropertiesActions.bulkMuteAndUnMuteProperties({ mute, unmute, requestOverride }));
  }
}
