import { EventsMap } from 'redux-beacon';
import { trackEvent } from '@app/shared/analytics';
import * as fromActions from './users.actions';
import { EventType, EventNames } from '@app/shared/interfaces';

const type = EventType.Action;

const StartedSignUpWithAirbnb = trackEvent((action) => ({
  type,
  name: EventNames.StartedSignUpWithAirbnb,
}));

const SignUpWithAirbnbSuccess = trackEvent((action) => ({
  type,
  name: EventNames.SignUpWithAirbnbSuccess,
}));

const SignUpWithAirbnbError = trackEvent((action) => ({
  type,
  name: EventNames.SignUpWithAirbnbError,
  parameters: {
    error:
      action && action.response && action.response.error && action.response.error.detail
        ? action.response.error.detail
        : 'unknown error',
  },
}));

export const UsersEvents: EventsMap = {
  [fromActions.signUpWithAirbnb.type]: StartedSignUpWithAirbnb,
  [fromActions.signUpWithAirbnbCallbackSuccess.type]: SignUpWithAirbnbSuccess,
  [fromActions.signUpWithAirbnbCallbackFailure.type]: SignUpWithAirbnbError,
};
