import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DirectService, PropertyPhotoTag } from '@app/modules/direct/services/direct.service';
import { NotificationType } from '@app/shared/interfaces';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

export interface Image {
  id: number;
  thumbnail: string;
  xx_large: string;
  caption: string;
  order: number;
  deleting?: boolean;
}

@Component({
  selector: 'sbnb-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit, OnChanges {
  @Input() propertyId: string;
  @Input('images') readonly origImages: ReadonlyArray<Image> = [];
  @Input() canAddImage: boolean = true;
  @Input() canDeleteImage: boolean = true;
  @Input() editMode: boolean = false;
  @Input() tags: PropertyPhotoTag[];

  @Output() editsMade: EventEmitter<boolean> = new EventEmitter();
  @Output() editModeChanged: EventEmitter<boolean> = new EventEmitter();

  public images: Image[];

  saving: boolean = false;

  constructor(
    private readonly propertiesService: PropertiesService,
    private direct: DirectService,
    private toast: ToastNotificationsService
  ) {}

  ngOnInit(): void {
    // Immutable local copy to work with
    if (this.origImages) {
      this.images = this.origImages.slice(0);
    } else {
      this.images = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editMode && changes.editMode.currentValue === false && !changes.editMode.firstChange) {
      // We finished editing, update the sorts and captions now
      this.saveUpdatedOrderAndCaptions();
    }
  }

  saveUpdatedOrderAndCaptions() {
    this.saving = true;

    this.updateImageOrdersToArrayIndex();
    this.removeBlankCaptions();

    this.propertiesService.updateImageGalleryOrdersAndCaptions(this.propertyId, this.images).subscribe(
      (res) => {
        this.saving = false;
      },
      (err) => {
        this.saving = false;
      }
    );
  }

  imageAdded($event) {
    const image = $event.target.files[0];
    const order = this.images.length;

    const maxSizeInBytes = 2048 * 1024; // 2048 KB
    if (image.size > maxSizeInBytes) {
      this.toast.open('Image must be less than 2MB', 'OK', NotificationType.Error);
      return;
    }

    this.propertiesService.uploadPropertyImage(this.propertyId, image, order).subscribe((res: any) => {
      if (res.data?.photos) {
        this.images = res.data.photos;
        this.editsMade.emit();
      }
    });
  }

  imageDeleted(image: Image) {
    image.deleting = true;

    this.propertiesService.deletePropertyImage(this.propertyId, image.id).subscribe(
      (res: any) => {
        if (res.data?.photos) {
          this.images = res.data.photos;
        }
        image.deleting = false;
        this.editsMade.emit();
      },
      (err) => (image.deleting = false)
    );
  }

  drop(event: CdkDragDrop<number>): void {
    moveItemInArray(this.images, event.previousContainer.data, event.container.data);
  }

  setPhotoAsTag(imageId: number, tagKey: string) {
    this.direct.setPhotoTagForProperty(this.propertyId, imageId, tagKey).subscribe((res) => {
      const tagToUpdate = this.tags.find((tag) => tag.key === tagKey);

      if (tagToUpdate) {
        tagToUpdate.photo.id = imageId; // Update the photo ID to the new image ID
      }

      this.tags = Object.assign([], this.tags); // Force change detection

      this.toast.open('Photo tag updated', 'OK', NotificationType.Success);
    });
  }

  changeEditMode(newMode: boolean) {
    this.editMode = newMode;
    this.editModeChanged.emit(newMode);
  }

  private updateImageOrdersToArrayIndex() {
    // for each image in images, set its order to its position in the array
    this.images.forEach((image, index) => {
      image.order = index;
    });
  }

  private removeBlankCaptions() {
    // The API does not allow blank caption strings, it expects them omitted
    this.images.forEach((image) => {
      if (!image.caption) {
        delete image.caption;
      }
    });
  }
}
