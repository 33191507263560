export enum OptimizelyFeatureFlag {
  SignupMessagingRules = 'fe_signup_messaging_rules',
  MetricsV2 = 'metrics_v2_early_adopter',
  DirectReservationAgreements = 'direct_rental_agreements',
  GuestExperienceAi = 'ai_gx',
  BillingDeviceAddon = 'billing_device_addon',
  AirbnbPMSScope = 'airbnb_pms_scope',
  SmartCodes = 'ai_smart_codes',
  OwnerStatements = 'fe_owner_statements',
  DirectInstantBook = 'frontend_direct_instant_book',
  DirectPromoCodes = 'frontend_direct_promo_codes',
  ThreeDSAuthorizationDetails = '3ds_authorization_details',
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface OptimizelyFeatureSwitchMeta {
  [OptimizelyFeatureFlag.SignupMessagingRules]?: {
    scenarios: {
      key: string;
      description: string;
      criteria: {
        properties: number[];
        rulesSelected: number[];
        logicalOperator: LogicalOperator;
      };
      primaryCta: number;
      secondaryCta: number | null;
    }[];
  };
}

export enum PosthogFeatureFlag {
  PaymentTerms = 'payment_terms',
  AdhocGuestVerification = 'adhoc_guest_verification_fe',
}
