import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ToastNotificationsService } from '../toast-notifications/toast-notifications.service';
import { NotificationType } from '@app/shared/interfaces';

const IGNORABLE_422_URLS = ['/settings/security', '/signup/email', '/generate-overview'];
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private toast: ToastNotificationsService) {}

  handle422(error: HttpErrorResponse) {
    const message = error?.error.error || Object.values(error?.error?.errors)[0];

    if (!Boolean(IGNORABLE_422_URLS.find((url) => error?.url.indexOf(url) > -1))) {
      this.toast.open(`${message}`, 'Dismiss', NotificationType.Error);
    }

    return of({
      message,
      errors: error?.error?.errors,
      error: true,
    });
  }

  handle413(error: HttpErrorResponse) {
    this.toast.open(
      `We're having some trouble processing your request. Please contact support. CODE: 413.`,
      'Dismiss',
      NotificationType.Error
    );
  }

  handle429(error: HttpErrorResponse) {
    this.toast.open(
      `We're having some trouble processing your request (Too many requests). Please try again in a few minutes. CODE: 429.`,
      'Dismiss',
      NotificationType.Error
    );
  }

  handle500(error: HttpErrorResponse) {
    this.toast.open(
      `An error occured processing this action - please try again. If this keeps occuring, please contact support`,
      'Dismiss',
      NotificationType.Error
    );

    return of({
      error: true,
    });
  }
}
