<div *ngIf="!editMode" fxLayout="row wrap" fxLayoutGap="16px grid">
  <ng-container *ngFor="let image of images; let index = index">
    <div *ngIf="index < 8" class="gallery-image" fxFlex="0 0 128px">
      <img [src]="image.xx_large" />
    </div>
  </ng-container>
  <div
    *ngIf="images.length > 8"
    class="gallery-more clickable"
    fxLayoutAlign="center center"
    (click)="changeEditMode(true)">
    <span class="night-lighter text-center" style="font-size: 18px">
      + {{ images.length - 8 }}
      <br />
      more
    </span>
  </div>
</div>

<div *ngIf="editMode" fxLayout="row wrap" fxLayoutGap="16px grid" cdkDropListGroup class="gallery-edit">
  <div
    *ngFor="let image of images; let index = index"
    class="gallery-image"
    fxFlex="0 0 256px"
    style="height: 328px"
    cdkDropList
    cdkDropListOrientation="horizontal"
    [cdkDropListData]="index"
    (cdkDropListDropped)="drop($event)">
    <div cdkDrag>
      <img [src]="image.xx_large" style="width: 256px; height: 256px" />

      <div *ngIf="tags?.length > 0" class="gallery-tags" [matMenuTriggerFor]="propertyTagsMenu">
        <img
          [src]="
            (image.id | doesPropertyImageHaveTags: tags)
              ? '/assets/iconography/tag-grass.svg'
              : '/assets/iconography/tag.svg'
          "
          alt="tag icon" />

        <mat-menu #propertyTagsMenu="matMenu" class="filters__cdk-menu-320">
          <ng-container *ngFor="let tag of tags | sortPropertyImageTags: image.id">
            <!-- Check if current image's ID matches the tag's photo ID -->
            <ng-container *ngIf="image.id === tag.photo?.id">
              <!-- Displayed if the current image is used for this tag -->
              <div mat-menu-item disabled>✅ Used as {{ tag.label }} photo on Direct sites</div>
            </ng-container>

            <!-- If the current image's ID does NOT match the tag's photo ID -->
            <button *ngIf="image.id !== tag.photo?.id" mat-menu-item (click)="setPhotoAsTag(image, tag.key)">
              Set as {{ tag.label }} photo on Direct sites
            </button>
          </ng-container>
        </mat-menu>
      </div>

      <div class="gallery-delete">
        <sbnb-close *ngIf="!image.deleting" (click)="imageDeleted(image)"></sbnb-close>
        <mat-spinner *ngIf="image.deleting" diameter="16"></mat-spinner>
      </div>

      <div style="padding: 12px; padding-right: 0; padding-top: 0" class="text__small">
        <textarea
          class="caption-edit"
          [(ngModel)]="image.caption"
          placeholder="Add a caption..."
          type="text"></textarea>
      </div>
    </div>
  </div>

  <div *ngIf="canAddImage" class="gallery-add">
    <input id="file" class="image__input" #imageInput type="file" accept="image/*" (change)="imageAdded($event)" />
    <label for="file" class="upload__file">+</label>
  </div>

  <div *ngIf="!canAddImage && (!images || images.length === 0)" class="text__small">No photos</div>
</div>
