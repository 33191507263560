<mat-spinner *ngIf="loadingPlatforms" diameter="16"></mat-spinner>
<div
  #container
  *ngIf="!loadingProperties"
  [fxLayout]="container.offsetWidth > 1000 ? 'row' : 'column'"
  fxLayoutGap="24px">
  <div fxFlex class="rule__scope-box opacity__transition" [class.coloured-border]="!platformStagePassed">
    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px" class="rule__scope-header">
      <mat-checkbox [checked]="selectedAllPlatforms" (change)="platformAllCheckboxChange($event)"></mat-checkbox>
      <h4 fxFlex>All platforms</h4>
      <span *ngIf="!selectedAllPlatforms && !airbnbOnly">{{ selectedPlatforms?.length }} selected</span>
      <!-- <span *ngIf="selectedAllPlatforms">Any (including future)</span> -->
      <span role="button" class="clickable grass-darker text__small" (click)="clearPlatforms()">Clear selection</span>
    </div>
    <div>
      <div>
        <sbnb-search-bar placeholderText="Search..." (searchChanged)="platformsSearchUpdated($event)"></sbnb-search-bar>
      </div>
      <div class="rules__scrollable">
        <mat-spinner *ngIf="loadingPlatforms" diameter="16"></mat-spinner>
        <ng-container *ngFor="let platform of possiblePlatforms">
          <div
            *ngIf="
              (platform.label | matchPlatformSearchCriteria: searchCriteriaPlatforms) &&
              (!airbnbOnly || (airbnbOnly && platform.key === 'airbnb'))
            "
            fxLayout
            fxLayoutGap="4px"
            fxLayoutAlign="start center">
            <mat-checkbox
              [checked]="selectedAllPlatforms || selectedPlatforms.indexOf(platform.key) > -1"
              (change)="platformCheckboxChange(platform, $event)">
              <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
                <div class="host__img">
                  <img [src]="'/assets/iconography/' + platform.key + '.svg'" />
                </div>

                <span fxFlex>{{ platform.label }}</span>
              </span>
            </mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    [class.coloured-border]="!hostsStagePassed"
    [style.opacity]="platformStagePassed ? 1 : 0"
    fxFlex
    class="rule__scope-box opacity__transition">
    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px" class="rule__scope-header">
      <mat-checkbox [checked]="selectedAllHosts" (change)="hostAllCheckboxChange($event)"></mat-checkbox>

      <h4 fxFlex>All hosts</h4>

      <span *ngIf="!selectedAllHosts">{{ selectedHosts?.length - numDeletedHosts }} selected</span>
      <!-- <span *ngIf="selectedAllHosts">Any (including future)</span> -->
      <span role="button" class="clickable grass-darker text__small" (click)="clearHosts()">Clear selection</span>
    </div>
    <div class="opacity__transition">
      <div>
        <sbnb-search-bar placeholderText="Search..." (searchChanged)="hostsSearchUpdated($event)"></sbnb-search-bar>
      </div>

      <mat-spinner *ngIf="loadingHosts" diameter="16"></mat-spinner>

      <cdk-virtual-scroll-viewport itemSize="40" class="rules__scrollable">
        <ng-container *cdkVirtualFor="let host of filteredHosts">
          <div
            fxLayout
            [style.opacity]="host.selectable ? 1 : 0.2"
            [style.pointer-events]="host.selectable ? null : 'none'">
            <mat-checkbox
              style="width: 100% !important"
              [checked]="(host.selectable && selectedAllHosts) || selectedHosts.indexOf(host.user_id + '') > -1"
              (change)="hostCheckboxChange(host, $event)">
              <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
                <img [src]="host.picture" class="host__img" onerror="this.src='/assets/images/profile-default.png'" />
                <div class="host-platform-icon" fxLayoutAlign="center center">
                  <img [src]="'/assets/iconography/' + host.platform + '.svg'" />
                </div>
                <span fxFlex>{{ host.name }}</span>
              </span>
            </mat-checkbox>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

  <div
    [class.coloured-border]="!selectedAllProperties && selectedProperties.length === 0"
    [style.opacity]="hostsStagePassed ? 1 : 0"
    fxFlex
    class="rule__scope-box opacity__transition">
    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px" class="rule__scope-header">
      <mat-checkbox [checked]="selectedAllProperties" (change)="propertyAllCheckboxChange($event)"></mat-checkbox>
      <h4 fxFlex>All properties</h4>
      <span *ngIf="!selectedAllProperties">
        {{
          selectedProperties?.length - numDeletedProperties > -1 ? selectedProperties?.length - numDeletedProperties : 0
        }}
        selected
      </span>
      <span role="button" class="clickable grass-darker text__small" (click)="clearProperties()">Clear selection</span>
    </div>
    <div>
      <div>
        <sbnb-search-bar placeholderText="Search..." (searchChanged)="propertySearchUpdated($event)"></sbnb-search-bar>
      </div>
      <cdk-virtual-scroll-viewport itemSize="40" class="rules__scrollable">
        <ng-container *cdkVirtualFor="let property of filteredProperties">
          <div
            fxLayout
            [matTooltip]="property.listed === false ? 'This property is unlisted' : null"
            [style.opacity]="property.selectable ? 1 : 0.2"
            [style.pointer-events]="property.selectable ? null : 'none'"
            [class.property__unlisted]="property.listed === false">
            <mat-checkbox
              style="width: 100% !important"
              [checked]="(property.selectable && selectedAllProperties) || selectedProperties.indexOf(property.id) > -1"
              (change)="propertyCheckboxChange(property, $event)">
              <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
                <img [src]="property.picture" class="property__img" />
                <span fxFlex>{{ property.name }}</span>
              </span>
            </mat-checkbox>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
