import { Injectable } from '@angular/core';
import { sbnbIcon, sbnbIcons } from '../sbnb-icons';

@Injectable({
  providedIn: 'root',
})
export class SvgCacheService {
  private cache = new Map<sbnbIcon, string>();
  constructor() {
    this.registerIcons();
  }

  public getAllIcons() {
    return [...this.cache.keys()];
  }

  public getIcon(name: sbnbIcon): string {
    return this.cache.get(name);
  }

  private registerIcons(): void {
    sbnbIcons.forEach(({ name, data }) => {
      this.cache.set(name, data);
    });
  }
}
