<div *ngIf="isCS && (debugMode$ | async)" class="debug-item">
  <hr *ngIf="borderTop" />
  <strong *ngIf="label">{{ label }}:</strong>
  <span
    *ngIf="value"
    matTooltip="This only ever shows for internal CS logins - click to copy the value to your clipboard"
    class="cs__debug"
    ngxClipboard
    [cbContent]="value">
    {{ value }}
  </span>

  <span>&nbsp;</span>

  <span *ngIf="link && linkLabel">
    <a
      [href]="link"
      class="cs__debug"
      target="_blank"
      matTooltip="This only ever shows for internal CS logins - click to follow the link">
      {{ linkLabel }}
    </a>
  </span>

  <hr *ngIf="borderBottom" />
</div>
