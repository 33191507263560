import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KnowledgeService } from '@app/modules/knowledge/services/knowledge.service';
import { Property } from '@app/shared/interfaces';

@Component({
  template: `
    <h3 class="mb-4">Edit knowledge for {{ data.topicName }}</h3>
    <sbnb-textarea
      class="mb-8 block"
      [formControlRef]="content"
      placeholder="Write your knowledge here..."
      [maxRows]="16"></sbnb-textarea>

    <div class="flex justify-end">
      <sbnb-button [loading]="saving" (clicked)="saveChanges()">Save changes</sbnb-button>
    </div>
  `,
  styleUrls: ['./dialog-edit-knowledge.component.css'],
})
export class DialogEditKnowledgeComponent {
  saving = false;
  content = new FormControl(this.data.content, [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<DialogEditKnowledgeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { itemId: number; propertyId: Property['id']; content: string; topicName: string },
    private knowledgeService: KnowledgeService
  ) {}

  saveChanges() {
    this.saving = true;

    this.knowledgeService
      .editKnowledgeItem(this.data.itemId, this.data.propertyId, this.content.value)
      .subscribe((success) => {
        if (success) {
          this.dialogRef.close();
        }
      });
  }
}
