<h2 class="nomargin">Create new custom code</h2>
<span class="subheading">Custom codes add super-powers to your messages.</span>

<mat-form-field class="fw">
  <input [(ngModel)]="cc.key" matInput placeholder="Custom code name..." />
  <mat-hint *ngIf="cc.key">
    You'll be able to use this custom code in templates by typing
    <strong>%%{{ cc.key }}%%</strong>
  </mat-hint>
</mat-form-field>

<sbnb-rule-editor
  #ruleEditor
  [simpleMode]="true"
  [simpleInput]="cc.text"
  (simpleTextChange)="cc.updated_text = $event"
  [importEnabled]="true"
  [codesEnabled]="true"
  [previewEnabled]="false"></sbnb-rule-editor>

<div class="rule__section-container rule__section-collapsed">
  <div fxLayout fxLayoutAlign="start center">
    <h3
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      style="margin-top: 0; margin-bottom: 0; padding-right: 40px"
      class="not-clickable">
      <img src="/assets/iconography/time.svg" />
      <span>Condition</span>
    </h3>

    <sbnb-custom-code-conditions
      *ngIf="conditionsMeta && cc"
      [meta]="conditionsMeta"
      [customCode]="cc"
      (triggerSave)="handleConditionUpdate($event)"></sbnb-custom-code-conditions>
  </div>
</div>

<div class="rule__section-container" [class.rule__section-collapsed]="isCardCollapsed('scope')">
  <div fxLayout fxLayoutAlign="start start" class="clickable" (click)="toggleCard('scope')">
    <h3 fxFlex fxLayoutAlign="start center" fxLayoutGap="8px">
      <img src="/assets/iconography/scope.svg" />
      <span>Scope</span>
      <span class="card__collapsed-helper-text">Limit the usage of this custom code with the options below.</span>
    </h3>
    <img
      class="arrow"
      [class.rotate-arrow]="isCardCollapsed('scope')"
      src="/assets/iconography/arrow-down-grass.svg"
      width="28"
      height="28" />
  </div>

  <div *ngIf="!isCardCollapsed('scope')">
    <sbnb-rule-scope [initial]="cc" [debounceTime]="0" (completed)="handleScopeChange($event)"></sbnb-rule-scope>
  </div>
</div>

<div class="rule__section-container" fxLayout fxLayoutAlign="end center" fxLayoutGap="16px">
  <button type="submit" class="button__secondary" mat-flat-button (click)="close()">Cancel</button>

  <button [disabled]="saving" type="submit" mat-flat-button (click)="createCustomCode()">
    Save
    <mat-spinner *ngIf="saving" class="button__spinner" diameter="30"></mat-spinner>
  </button>
</div>
