import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { config } from '../app-config';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { EventNames } from '@app/shared/interfaces';

export enum NotificationActionType {
  BUTTON = 'button',
  DISMISSIBLE = 'dismissible',
}

export interface NotificationAction {
  text: string;
  url: string;
  type: NotificationActionType;
  icon: string;
  segmentEventName?: EventNames;
  product_tour_id: string;
  mobile_product_tour_id: string;
}

export enum NotificationActionColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
}

export enum NotificationDataType {
  INTRO = 'intro',
  SIDEBAR = 'sidebar',
  CONTENT = 'content',
  DEFAULT = 'default',
}

export interface NotificationData {
  uuid: string;
  title: string;
  description: string;
  tag: string;
  type: string;
  level: string;
  created_at: string;
  deleted_at: string;
  updated_at: string;
  actions: NotificationAction[];
  hidden?: boolean;
  image: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private getNotificationsUrl = `${config.API_URL}/notifications`;

  private _notifications: BehaviorSubject<NotificationData[]> = new BehaviorSubject([]);
  public readonly notifications: Observable<NotificationData[]> = this._notifications.asObservable();

  constructor(private http: HttpClient) {}

  closeDown() {
    this._notifications.next([]);
  }

  fetchNotificationsFromRest(): Observable<boolean> {
    return this.http.get(this.getNotificationsUrl).pipe(
      map((res: any) => {
        this._notifications.next(res.data);
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(false);
      })
    );
  }

  addNotification(newNotification): void {
    if (this._notifications.getValue().some((n) => n.uuid === newNotification.uuid)) {
      this.updateNotification(newNotification);
    } else {
      this._notifications.next([...this._notifications.getValue(), newNotification]);
    }
  }

  updateNotification(newNotification): void {
    const index = this._notifications.getValue().findIndex((x) => x.uuid === newNotification.uuid);

    if (index > -1) {
      this._notifications.getValue()[index] = newNotification;

      this._notifications.next(this._notifications.getValue());
    } else {
      this.addNotification(newNotification);
    }
  }

  deleteNotification(notification): void {
    this._notifications.next(this._notifications.getValue().filter((x) => x.uuid !== notification.uuid));
  }

  dismissNotification(url: string, notification: any): Observable<boolean> {
    return this.http.put(url, {}).pipe(
      map(() => {
        this.deleteNotification(notification);
        return true;
      }),
      catchError((err) => {
        // If the user wants to remove the notification, we should delete it even if the HTTP call fails
        // This is common when the API returns a 404 for a notification which has been deleted server side previously
        this.deleteNotification(notification);
        return of(true);
      })
    );
  }
}
