import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { HostsService } from '@app/shared/services/hosts/hosts.service';
import { PlatformsService } from '@app/shared/services/platforms/platforms.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface RuleScope {
  all_hosts: boolean;
  all_platforms: boolean;
  all_properties: boolean;
  platforms: string[];
  properties: number[];
  hosts: string[];
}

const isInList = (array, item) => (array.indexOf(item) === -1 ? 0 : 1);

// Sort by by selected, then platform,
const hostsSort = (a, b, selectedHosts) => {
  return (
    isInList(selectedHosts, b.user_id + '') - isInList(selectedHosts, a.user_id + '') ||
    a.platform.localeCompare(b.platform)
  );
};

// Sort by selected, then selectable
const propertiesSort = (a, b, selectedProperties) => {
  return isInList(selectedProperties, b.id) - isInList(selectedProperties, a.id) || b.selectable - a.selectable;
};

@Component({
  selector: 'sbnb-rule-scope',
  templateUrl: './rule-scope.component.html',
  styleUrls: ['./rule-scope.component.scss'],
})
export class RuleScopeComponent implements OnInit {
  possiblePlatforms: any[];
  possibleHosts: any[];
  possibleProperties: any[];

  selectedPlatforms: any[] = [];
  selectedAllPlatforms: boolean;

  selectedHosts: any[] = [];
  selectedAllHosts: boolean;

  selectedProperties: any[] = [];
  selectedAllProperties: boolean;

  public hostError = null;

  loadingPlatforms = true;
  loadingHosts = true;
  loadingProperties = true;

  searchCriteriaPlatforms: string;
  searchCriteriaHosts: string;
  searchCriteriaProperties: string;

  platformStagePassed: boolean;
  hostsStagePassed: boolean;
  propertiesStagePassed: boolean;

  numDeletedHosts: number;
  numDeletedProperties: number;

  initialized: boolean;

  @Input() initial: RuleScope;
  @Input() airbnbOnly: boolean;
  @Input() debounceTime = 1000;
  @Output() completed: EventEmitter<any> = new EventEmitter();

  filteredHosts$: BehaviorSubject<any> = new BehaviorSubject([]);
  filteredProperties$: BehaviorSubject<any> = new BehaviorSubject([]);

  private scopeChangeSubject$: Subject<any> = new Subject();

  constructor(
    private propertiesService: PropertiesService,
    private hostsService: HostsService,
    private platformsService: PlatformsService
  ) {}

  ngOnInit() {
    if (this.initial) {
      this.selectedAllHosts = this.initial.all_hosts;
      this.selectedAllPlatforms = this.initial.all_platforms;
      this.selectedAllProperties = this.initial.all_properties;
      this.selectedHosts = this.initial.hosts;
      this.selectedPlatforms = this.initial.platforms;
      this.selectedProperties = this.initial.properties;
    }

    const properties$ = this.propertiesService.getScopingProperties([], null, null, 5000, false);
    const hosts$ = this.hostsService.getHosts();
    const platforms$ = this.platformsService.getPlatforms();

    forkJoin([properties$, hosts$, platforms$]).subscribe(([properties, hosts, platforms]) => {
      this.loadingProperties = false;
      this.loadingHosts = false;
      this.loadingPlatforms = false;

      this.possibleProperties = properties.data.sort((a, b) => propertiesSort(a, b, this.selectedProperties));
      this.filteredProperties$.next(this.possibleProperties);
      this.possibleHosts = hosts
        .sort((a, b) => hostsSort(a, b, this.selectedHosts))
        // If airbnbOnly is true, filter out other hosts
        .filter((host) => (this.airbnbOnly === true ? host.platform === 'airbnb' : true));
      this.filteredHosts$.next(this.possibleHosts);
      this.possiblePlatforms = platforms;

      this.selectAllPlatforms();
      this.onPlatformValueChange();

      this.numDeletedHosts = this.howManyHostsAreDeleted();
      this.numDeletedProperties = this.howManyPropertiesAreDeleted();

      if (this.selectedAllProperties || this.selectedProperties.length > 0) {
        this.propertiesStagePassed = true;
      }

      this.initialized = true;

      // Debounce scope updates
      this.scopeChangeSubject$.pipe(debounceTime(this.debounceTime)).subscribe((x) => {
        this.propertyStagePassedCheck();
      });
    });
  }

  // The API tells us about all hosts, active or deleted
  // The count of hosts should only show those which are active, we need to manually calculate how many are deleted to achieve this
  howManyHostsAreDeleted(): number {
    if (!this.possibleHosts.length || !this.selectedHosts.length) {
      return 0;
    }

    const possibleHostIds = this.possibleHosts.map((host) => String(host.user_id));
    return this.selectedHosts.filter((selectedHostId) => !possibleHostIds.includes(selectedHostId)).length ?? 0;
  }

  howManyPropertiesAreDeleted(): number {
    if (!this.possiblePlatforms.length || !this.selectedProperties.length) {
      return 0;
    }

    const possiblePropertyIds = this.possibleProperties.map((property) => property.id);
    return (
      this.selectedProperties.filter((selectedPropertyId) => !possiblePropertyIds.includes(selectedPropertyId))
        .length ?? 0
    );
  }

  propertyCheckboxChange(property, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedProperties.push(property.id);
    } else {
      this.selectedAllProperties = false;
      const index = this.selectedProperties.findIndex((prop) => prop === property.id);

      if (index !== -1) {
        this.selectedProperties.splice(index, 1);
      }
    }

    this.scopeChangeSubject$.next(true);
  }

  propertyAllCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedAllProperties = true;
      this.searchCriteriaProperties = '';
      this.selectAllProperties(true);
    } else {
      this.selectedAllProperties = false;
    }

    this.scopeChangeSubject$.next(true);
  }

  propertySearchUpdated(searchCriteria: string) {
    this.filteredProperties$.next(
      this.possibleProperties
        .filter((prop) => (prop.name ? prop.name.toLowerCase().includes(searchCriteria.toLowerCase()) : false))
        .sort((a, b) => propertiesSort(a, b, this.selectedProperties))
    );
  }

  hostsSearchUpdated(searchCriteria: string) {
    this.filteredHosts$.next(
      this.possibleHosts
        .filter((host) => host?.name?.toLowerCase()?.includes(searchCriteria?.toLowerCase()))
        .sort((a, b) => hostsSort(a, b, this.selectedHosts))
    );
  }

  hostCheckboxChange(host, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedHosts.push(host.user_id + '');
    } else {
      this.selectedAllHosts = false;
      const index = this.selectedHosts.findIndex((selHost) => selHost == host.user_id);

      if (index !== -1) {
        this.selectedHosts.splice(index, 1);
      }
    }

    this.hostsStagePassedCheck();
  }

  hostAllCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedAllHosts = true;
      this.selectAllHosts();
      this.searchCriteriaHosts = '';
    } else {
      this.selectedAllHosts = false;
    }

    this.hostsStagePassedCheck();
  }

  selectAllHosts() {
    if (this.selectedAllHosts) {
      this.selectedHosts = this.possibleHosts
        // direct hosts are not selectable so that the user can't turn them off,
        // so when direct is selected, we need to specifically include all direct hosts
        .filter((host) => host.selectable || this.selectedAllPlatforms || this.matchesDirect(host.platform))
        .map((host) => host.user_id + '');
    }
  }

  selectAllPlatforms() {
    if (this.selectedAllPlatforms) {
      this.selectedPlatforms = this.possiblePlatforms.map((pP) => pP.key);
    }
  }

  selectAllProperties(force = false) {
    if (this.selectedAllProperties) {
      this.selectedProperties = this.possibleProperties
        .filter((pProp) => pProp.selectable && pProp.hosts.length > 0)
        .map((pProp) => pProp.id);
    }

    if (this.initialized && !force) {
      this.checkForUnselectableProperties();
      return;
    }
  }

  checkForUnselectableProperties() {
    const newSelected = [];
    this.selectedProperties.forEach((sP) => {
      const pP = this.possibleProperties.find((pP) => pP.id === sP);

      if (pP && pP.selectable) {
        newSelected.push(sP);
      }
    });

    this.selectedProperties = newSelected;
  }

  platformAllCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedAllPlatforms = true;
      this.searchCriteriaPlatforms = '';
      this.selectAllPlatforms();
      // we infer that by checking "All platforms" the user wants to select all hosts for those platforms
      this.onPlatformValueChange(true);
    } else {
      this.selectedAllPlatforms = false;
      // we infer that by unchecking "All platforms" the user wants to manage the host selection manually
      this.onPlatformValueChange();
    }
  }

  platformsSearchUpdated(event: string) {
    this.searchCriteriaPlatforms = event;
  }

  platformCheckboxChange(platform, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedPlatforms.push(platform.key);
    } else {
      this.selectedAllPlatforms = false;
      const index = this.selectedPlatforms.findIndex((selPlat) => selPlat === platform.key);

      if (index !== -1) {
        this.selectedPlatforms.splice(index, 1);
      }
    }
    // we infer that when a user selects or deselects a platform, they want to select or deselect all hosts for that platform
    this.onPlatformValueChange(true);
  }

  /**
   * - Mark everything as not passed
   * - Make hosts that match the platforms selectable (except direct, which is always unselectable)
   * - Automatically select matching hosts if applicable
   * - Mark the platform stage as passed
   * - Select all hosts to fill this.selectedHosts with all selectable hosts (its important that this happens after hosts are marked as selectable or not)
   * - Start the hosts stage checks
   * @param automaticallySelectMatchingHosts Boolean
   * @returns void
   */
  onPlatformValueChange(automaticallySelectMatchingHosts = false) {
    // Mark everything as not passed, run through the checks, trigger the next stage if passed
    this.platformStagePassed = false;
    this.hostsStagePassed = false;
    this.propertiesStagePassed = false;

    if (!this.selectedAllPlatforms && !this.selectedPlatforms.length) {
      return;
    }

    // Make hosts that match the platforms selectable (except direct, which is always unselectable)
    this.possibleHosts.forEach((host) => {
      const matchesSelectedPlatforms = this.matchesPlatform(host.platform);
      const directHost = host.platform === 'direct';
      host.selectable = !directHost && (this.selectedAllPlatforms || matchesSelectedPlatforms);
    });

    if (automaticallySelectMatchingHosts) {
      // remove selected hosts who no longer match the selected platforms
      this.selectedHosts = this.selectedHosts.filter((hostId) => {
        const host = this.possibleHosts.find((x) => x.user_id == hostId); // sadly, leave this as '==', as we have some string ID's, and some numeric :(
        return this.matchesPlatform(host.platform) || this.selectedAllHosts;
      });

      // select all users who match the selected platforms
      this.possibleHosts.forEach((host) => {
        const matchesSelectedPlatforms = this.matchesPlatform(host.platform);

        if (
          (this.selectedAllPlatforms || matchesSelectedPlatforms) &&
          !this.selectedHosts.includes(host.user_id + '')
        ) {
          this.selectedHosts.push(host.user_id + '');
        }
      });
    }

    this.platformStagePassed = true;
    this.selectAllHosts();
    this.hostsStagePassedCheck();
    this.runValidations();
  }

  hostsStagePassedCheck() {
    this.hostsStagePassed = false;
    this.propertiesStagePassed = false;

    if (this.selectedAllHosts || this.selectedHosts.length > 0) {
      this.hostsStagePassed = true;

      // Make properties that don't match the hosts unselectable
      this.possibleProperties.forEach((property) => {
        const propertyHostIds = property.hosts.map((host) => host.platform_id);
        const matchesHost =
          this.selectedAllHosts ||
          this.selectedPlatforms.includes('direct') ||
          this.selectedHosts.some((host) => propertyHostIds.includes(host));

        property.selectable = matchesHost;
      });

      this.selectAllProperties();

      this.scopeChangeSubject$.next(true);
    } else {
      this.hostsStagePassed = false;
    }
    this.runValidations();
  }

  propertyStagePassedCheck() {
    this.runValidations();
    if (this.selectedAllProperties || this.selectedProperties.length > 0) {
      this.propertiesStagePassed = true;

      this.completed.emit({
        all_hosts: this.selectedAllHosts,
        all_platforms: this.selectedAllPlatforms,
        all_properties: this.selectedAllProperties,
        platforms: this.selectedPlatforms,
        properties: this.selectedProperties,
        hosts: this.selectedHosts,
      });
    }
  }

  private matchesDirect(hostPlatform) {
    return this.selectedPlatforms.includes('direct') && hostPlatform === 'direct';
  }

  private matchesPlatform(hostPlatform) {
    return this.selectedPlatforms.includes(hostPlatform);
  }

  clearPlatforms() {
    this.selectedAllPlatforms = false;
    this.selectedPlatforms = [];
    this.selectedAllHosts = false;
    this.selectedHosts = [];
    this.selectedAllProperties = false;
    this.selectedProperties = [];
    this.onPlatformValueChange();
  }

  clearHosts() {
    this.selectedAllHosts = false;
    this.selectedHosts = [];
    this.selectedAllProperties = false;
    this.selectedProperties = [];
    this.hostsStagePassedCheck();
  }

  clearProperties() {
    this.selectedAllProperties = false;
    this.selectedProperties = [];
    this.scopeChangeSubject$.next(true);
  }

  private runValidations() {
    this.hostError = this.validate();
  }

  private validate() {
    const selectedPlatformsWithoutManual = this.selectedPlatforms.filter((platform) => platform !== 'manual');
    // Validate required, minimum of at least 1 selected
    if (!this.selectedAllHosts && !this.selectedHosts.length) {
      return { required: true };
    }

    if (this.selectedAllHosts) {
      return null;
    }

    // Validate that each host matches one of the selected platforms
    const hosts = this.possibleHosts.filter((host) => this.selectedHosts.includes(host.user_id + ''));
    if (!hosts.every((host) => selectedPlatformsWithoutManual.includes(host.platform))) {
      return { hostWithoutPlatform: true };
    }

    // Validate that each selected platform has at least one host
    const platformsMissingHosts = selectedPlatformsWithoutManual
      .filter((platform) => !hosts.some((host) => host.platform === platform))
      .map((platform) => this.possiblePlatforms.find((p) => p.key === platform).label);
    if (platformsMissingHosts?.length) {
      return { platformWithoutHost: platformsMissingHosts };
    }

    // Validate that at least one host from each selected property is selected
    const properties = this.possibleProperties.filter((prop) => this.selectedProperties.includes(prop.id));
    if (
      !this.selectedAllProperties &&
      !properties.every((property) => {
        const propertyHostIds = property.hosts.map((host) => host.platform_id);
        return this.selectedHosts.some((host) => propertyHostIds.includes(host));
      })
    ) {
      return { propertyWithoutHost: true };
    }

    return null;
  }
}
