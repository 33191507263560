export interface UserPermissions {
  user_id?: number;
  team_id?: number;
  owner_id?: number;
  is_admin: boolean;
  all_features: boolean;
  all_properties: boolean;
  all_financials: boolean;
  is_team_owner: boolean;
  features?: Features[];
}

export enum Features {
  PERM_RESERVATIONS = 'reservations',
  PERM_RESERVATION_FINANCIALS = 'res_financials',
  PERM_MANAGE_RESERVATIONS = 'manage_res',
  PERM_INBOX = 'inbox',
  PERM_CALENDAR = 'calendar',
  PERM_CALENDAR_DISPLAY_PRICING = 'cal_display_pricing',
  PERM_CALENDAR_UPDATE_PRICING_AND_AVAIL = 'cal_update_p_and_a',
  PERM_CALENDAR_UPDATE_AVAILABILITY = 'cal_update_avail',
  PERM_PROPERTIES = 'properties',
  PERM_GUEST_EXPERIENCE = 'gx',
  PERM_OPERATIONS = 'ops',
  PERM_OPERATIONS_OWNER_STATEMENTS = 'ops_owner_statements',
  PERM_TASKS = 'tasks',
  PERM_TASKS_UPDATE_YOURS = 'tasks_update_yours',
  PERM_TASKS_UPDATE_ALL = 'tasks_update_all',
  PERM_APPS = 'apps',
  PERM_METRICS = 'metrics',
  PERM_DIRECT = 'direct',
  PERM_DIRECT_DISPLAY_FINANCIAL_DATA = 'direct_display_financial_data',
  PERM_SMARTLOCKS_DASHBOARD = 'smartlocks_dashboard',
  PERM_SMARTLOCKS_DASHBOARD_WRITE = 'smartlocks_write',
  PERM_PURCHASE_ADDITIONAL_SERVICES = 'purchase_additional_services',
}
