import { EventsMap } from 'redux-beacon';
import { trackEvent } from '@app/shared/analytics';
import * as fromActions from './listings.actions';
import { EventType, EventNames } from '@app/shared/interfaces';

const type = EventType.Action;

const StartedMergeMatch = trackEvent((action) => ({
  type,
  name: EventNames.StartedMergeMatch,
}));

const MergeMatchOutcome = trackEvent((action) => ({
  type,
  name: EventNames.MergeMatchOutcome,
  parameters: action.analytics,
}));

export const ListingsEvents: EventsMap = {
  [fromActions.trackStartedMergeMatchSuccess.type]: StartedMergeMatch,
  [fromActions.trackMergeMatchOutcome.type]: MergeMatchOutcome,
};
