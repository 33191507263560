<!-- Stop the click on this div from being propgated up and prematurely closing the tooltip -->
<div *ngIf="content?.length; else emptyContent" class="ctc__container" ngxClipboard (click)="onCopied($event)">
  <ng-container *ngTemplateOutlet="childContent"></ng-container>
  <span matTooltip="Copy to clipboard" matTooltipPosition="above" #tooltip="matTooltip">
    <mat-icon class="icon" inline="true">content_copy</mat-icon>
  </span>
</div>
<ng-template #emptyContent>
  <ng-container *ngTemplateOutlet="childContent"></ng-container>
</ng-template>
<ng-template #childContent>
  <ng-content></ng-content>
</ng-template>
