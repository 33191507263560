import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import {
  NotificationsService,
  NotificationData,
  NotificationDataType,
  NotificationAction,
} from '@app/core/notifications/notifications.service';
import { map, filter } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';
import { NavbarService } from '../nav/navbar.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { EventNames } from '@app/shared/interfaces';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { IntercomService } from '@app/shared/services/intercom.service';
import { Logger } from '@app/shared/utils';

@Component({
  selector: 'sbnb-helptext-wrapper',
  templateUrl: './helptext-wrapper.component.html',
  styleUrls: ['./helptext-wrapper.component.scss'],
})
export class HelptextWrapperComponent implements OnInit, AfterViewInit {
  notifications$ = this.notificationsService.notifications;
  relevantNotifications: NotificationData[];
  areaArr: string[];
  isMobile: boolean;

  navExpanded: boolean;
  navExists: boolean;
  url: string;

  /**
   * Are we already dismissing something?
   */
  dismissing: boolean;

  excludedPages = [];

  @Input() area: 'sidebar' | 'intro' | 'content' | 'global';
  @Input() pageId: string;

  @Input() bypassNavCheck = false; // Some screens don't have the nav but need notifications

  NotificationDataType = NotificationDataType;

  constructor(
    private notificationsService: NotificationsService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private navbarService: NavbarService,
    private segmentIoService: SegmentIoService,
    private csService: InternalCsService,
    private intercomService: IntercomService
  ) {}

  ngOnInit() {
    this.navbarService.expanded$.subscribe((res) => {
      this.navExpanded = res;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((res) => {
      this.url = this.router.url;
    });

    this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

    const requiredLevel = this.area === 'global' ? 'major' : 'minor';

    this.areaArr = this.areaToTypesMapping();
    this.notifications$
      .pipe(
        map((nf) => {
          return nf.filter(
            (x) =>
              x.level === requiredLevel &&
              this.areaArr.includes(x.type) &&
              (x.tag === 'global' || x.tag === `page:${this.pageId}`)
          );
        })
      )
      .subscribe((res) => {
        this.relevantNotifications = res;
        this.addClassToMain();
      });

    if (this.pageId && this.csService.isCS()) {
      Logger.log(`Nova notification page ID: ${this.pageId}, area: ${this.area}`);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.navExists = document.getElementsByTagName('sbnb-nav').length ? true : false;
    }, 250);
  }

  addClassToMain() {
    // If we have a global notification, we need to add a class to our <main>
    if (this.relevantNotifications) {
      const globals = this.relevantNotifications.filter((x) => x.tag === 'global');

      if (globals.length > 0) {
        const mains = document.getElementsByTagName('body');
        if (mains.length > 0) {
          let i;
          for (i = 0; i < mains.length; i++) {
            mains[i].classList.add('has__global-notifications');
          }
        }
      }
    }
  }

  trackNotification(index, notification) {
    return notification ? notification.uuid : undefined;
  }

  dismissNotification(url: string, notification: any) {
    if (this.dismissing) {
      return;
    }

    this.dismissing = true;

    this.notificationsService.dismissNotification(url, notification).subscribe(() => {
      this.dismissing = false;
    });
  }

  startTour(tourId: string, action: NotificationAction, notification: NotificationData) {
    if (notification) {
      this.trackNotificationAction(notification, action);
    }
    this.intercomService.startTour(tourId);
  }

  getEventName(notification: NotificationData) {
    let eventName;

    switch (notification.type) {
      case NotificationDataType.CONTENT: {
        eventName = EventNames.ClickedNovaContentLink;
        break;
      }
      case NotificationDataType.INTRO: {
        eventName = EventNames.ClickedNovaIntroLink;
        break;
      }
      case NotificationDataType.SIDEBAR: {
        eventName = EventNames.ClickedNovaSidebarLink;
        break;
      }
      case NotificationDataType.DEFAULT: {
        eventName = EventNames.ClickedNovaDefaultLink;
        break;
      }
    }

    return eventName;
  }

  getActionUrlType(action: NotificationAction) {
    if (action) {
      if (action.product_tour_id || action.mobile_product_tour_id) {
        return 'product_tour';
      }

      if (action.url.includes('support-documentation/article/')) {
        return 'article';
      }

      if (action.url.includes('loom.com/')) {
        return 'video';
      }

      return 'link';
    }
  }

  trackNotificationAction(notification: NotificationData, action: NotificationAction) {
    if (action && notification) {
      this.segmentIoService.track(this.getEventName(notification), {
        pageIdentifier: notification.tag,
        ctaType: this.getActionUrlType(action),
        ctaText: action.text,
        ctaUrl: action.url,
      });
    }
  }

  extractArticleId(url: string): string | undefined {
    const fragments = url.split('support-documentation/article/');
    if (fragments[1]) {
      const fragment = fragments[1];
      const match = fragment.match(/^\d+|\d+$/g);
      return match && match[0] ? match[0] : undefined;
    }

    return undefined;
  }

  redirectToUrl(url: string) {
    if (url.includes('https://my.hospitable.com')) {
      const split = url.split('https://my.hospitable.com');

      if (split[1]) {
        return this.router.navigate([split[1]]);
      }
    } else {
      window.open(url, '_blank');
    }
  }

  openLink(action: NotificationAction, notification: NotificationData) {
    if (action && action.url) {
      const { url } = action;

      notification ? this.trackNotificationAction(notification, action) : null;

      if (url.includes('support-documentation/article/')) {
        this.intercomService.openHelpArticle(this.extractArticleId(url));
      } else {
        this.redirectToUrl(url);
      }
    }
  }

  areaToTypesMapping() {
    switch (this.area) {
      case 'sidebar':
        return [NotificationDataType.SIDEBAR];

      case 'intro':
        return [NotificationDataType.INTRO, NotificationDataType.DEFAULT];

      case 'content':
        return [NotificationDataType.CONTENT];

      case 'global':
        return [NotificationDataType.DEFAULT];

      default:
        break;
    }
  }
}
