import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Money, OptimizelyFeatureFlag } from '@app/shared/interfaces';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DirectPlan, DirectSubscription, DirectUiPlan } from '../models/direct-subscription.interface';

const ALL_PLANS: DirectUiPlan[] = [
  {
    key: DirectPlan.Lite,
    name: 'Direct Lite',
    subtitle: null,
    perPropertyPrice: null,
    guestServiceFee: null,
    headline: 'Direct site only',
    description: `I have properties in locations not supported by Stripe, so I will take booking requests without payment processing.`,
    features: [
      'Take payments off-platform using the payment channel of your choice',
      'You are the liable Merchant of Record for refunds and chargebacks',
      'Add your own taxes',
    ],
    recommended: false,
  },
  {
    key: DirectPlan.Premium,
    name: 'Direct Premium',
    subtitle: '<span style="font-size: 32px">🇺🇸🇦🇺🇬🇧</span><br>US, UK & Australian properties only (for now)',
    perPropertyPrice: null,
    guestServiceFee: null,
    headline: 'All-in-one integrated solution',
    description: `I can use Hospitable's site or build my own, with integrated payments, tax calculation, guest vetting, and damage protection.`,
    recommended: true,
    features: [
      'Link any personal or business bank accounts for your payouts',
      'Hospitable is the liable Merchant of Record for refunds and chargebacks',
      'Taxes automatically calculated and collected',
      'Integrated guest-vetting',
      'US$5M damage protection',
      'Receive payouts to your bank account 24 hours after check-in, in most cases',
    ],
  },
  {
    key: DirectPlan.Basic,
    name: 'Direct Basic',
    subtitle: null,
    perPropertyPrice: null,
    guestServiceFee: null,
    headline: 'Site + payment processing',
    description: `I can use Hospitable's site or build my own, connect my Stripe account for payments, and manage my own taxes.`,
    recommended: false,
    features: [
      'Connect your Stripe accounts for payment processing',
      'You are the liable Merchant of Record for refunds and chargebacks﻿',
      'Add your own taxes',
      'You control your payouts',
    ],
  },
];

export interface AvailablePlansApiResponse {
  data: {
    id: DirectPlan;
    is_available: boolean;
    available_countries: string[]; // EE, FR, GB, US
    guest_service_fees: {
      fixed: Money | null;
      percentage: { formatted_decimal: string; formatted_string: string; value: number } | null;
    };
    per_property_fee: Money;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class DirectSubscriptionService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private optimizelyService: OptimizelyService
  ) {}

  private _subscriptionStatus: BehaviorSubject<DirectSubscription> = new BehaviorSubject(null);
  public readonly subscriptionStatus$: Observable<DirectSubscription> = from(this._subscriptionStatus);

  getCurrentSubscriptionStatus(): DirectSubscription {
    return this._subscriptionStatus.getValue();
  }

  public isPremium$: Observable<boolean> = this.subscriptionStatus$.pipe(
    filter((sub) => sub !== null),
    map((sub: DirectSubscription) => sub.plan === DirectPlan.Premium)
  );

  public isBasic$: Observable<boolean> = this.subscriptionStatus$.pipe(
    filter((sub) => sub !== null),
    map((sub: DirectSubscription) => sub.plan === DirectPlan.Basic)
  );

  public isLite$: Observable<boolean> = this.subscriptionStatus$.pipe(
    filter((sub) => sub !== null),
    map((sub: DirectSubscription) => sub.plan === DirectPlan.Lite)
  );

  public isOnboarded$: Observable<boolean> = this.subscriptionStatus$.pipe(
    filter((sub) => sub !== null),
    map((sub: DirectSubscription) => sub.onboarded)
  );

  public instantBookEnabled$: Observable<boolean> = combineLatest([this.isPremium$, this.isBasic$]).pipe(
    switchMap(([isPremium, isBasic]) => {
      if (isPremium) {
        return of(true);
      }
      // Optimizely might be blocked so only rely on it if the user is not premium
      if (isBasic) {
        return this.optimizelyService.featureEnabled$(OptimizelyFeatureFlag.DirectInstantBook);
      }
      return of(false);
    })
  );

  getSubscriptionStatus(): Observable<DirectSubscription> {
    return this.http.get<{ data: DirectSubscription }>(`${environment.apiUrl}/direct/subscription`).pipe(
      tap((res) => {
        if (res?.data) {
          this._subscriptionStatus.next(res.data);
        }
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  startDirectTrial(planKey: DirectPlan): Observable<DirectSubscription> {
    return this.http
      .post<{ data: DirectSubscription }>(`${environment.apiUrl}/direct/trial/activate`, { plan: planKey })
      .pipe(
        tap((res) => {
          if (res?.data) {
            this._subscriptionStatus.next(res.data);
          }
        }),
        map((res) => {
          return res.data;
        })
      );
  }

  activateDirectSubscription(
    planKey: DirectPlan = this._subscriptionStatus.getValue().plan
  ): Observable<DirectSubscription> {
    return this.http
      .post<{ data: DirectSubscription }>(`${environment.apiUrl}/direct/subscription/activate`, { plan: planKey })
      .pipe(
        tap((res) => {
          if (res?.data) {
            this._subscriptionStatus.next(res.data);
          }
        }),
        map((res) => {
          return res.data;
        })
      );
  }

  switchPlan(isTrialling: boolean, newPlan: DirectPlan): Observable<DirectSubscription> {
    if (isTrialling) {
      // If we're in a trial, we call activate trial with the other plan key
      return this.startDirectTrial(newPlan);
    } else {
      // If we're not in a trial, we call activate subscription with the other plan key
      return this.activateDirectSubscription(newPlan);
    }
  }

  cancelDirect(): Observable<DirectSubscription> {
    return this.http.delete(`${environment.apiUrl}/direct/subscription`).pipe(
      tap((res: { data: DirectSubscription }) => {
        if (res?.data) {
          this._subscriptionStatus.next(res.data);
        }
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  dontCancelDirect(): Observable<DirectSubscription> {
    return this.http.post(`${environment.apiUrl}/direct/subscription/cancel-cancellation`, {}).pipe(
      tap((res: { data: DirectSubscription }) => {
        if (res?.data) {
          this._subscriptionStatus.next(res.data);
        }
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  // Patch in the prices from the available-plans API to our local plan data
  private updatePlansWithPricing(plans: DirectUiPlan[], apiPlans: AvailablePlansApiResponse['data']): DirectUiPlan[] {
    return plans.map((plan) => {
      const apiPlan = apiPlans.find((apiP) => apiP.id === plan.key);

      if (apiPlan) {
        let guestServiceFee = apiPlan.guest_service_fees.fixed
          ? this.formatCurrencyWithoutTrailingZeros(apiPlan.guest_service_fees.fixed.formatted_string)
          : '';

        if (apiPlan.guest_service_fees.percentage) {
          guestServiceFee = `${apiPlan.guest_service_fees.percentage.formatted_string} + ${guestServiceFee}`;
        }

        return {
          ...plan,
          perPropertyPrice: this.formatCurrencyWithoutTrailingZeros(apiPlan.per_property_fee.formatted_string),
          guestServiceFee,
        };
      }

      return plan;
    });
  }

  getDirectPlans(filteredToHostSelectable = false): Observable<DirectUiPlan[]> {
    return (
      this.http
        .get<AvailablePlansApiResponse>(`${environment.bookingApiUrl}/available-plans`)
        // return of(this.getMockApiResponse())
        .pipe(
          map((response) => {
            const availableApiPlans = response.data.filter((plan) => plan.is_available);

            // Patch in the pricing from the available-plans API
            const updatedPlans = this.updatePlansWithPricing(ALL_PLANS, response.data);

            if (filteredToHostSelectable) {
              // We don't want to show Lite if we also have Basic, they can discover it themselves later
              const hasBasic = availableApiPlans.some((plan) => plan.id === DirectPlan.Basic);
              const hasLite = availableApiPlans.some((plan) => plan.id === DirectPlan.Lite);

              if (hasBasic && hasLite) {
                const indexToRemove = updatedPlans.findIndex((plan) => plan.key === DirectPlan.Lite);
                if (indexToRemove > -1) {
                  updatedPlans.splice(indexToRemove, 1);
                }
              }

              return updatedPlans.filter((plan) => availableApiPlans.some((apiPlan) => apiPlan.id === plan.key));
            } else {
              return updatedPlans;
            }
          })
        )
    );
  }

  getEligibleSwitchPlans(): Observable<DirectPlan[]> {
    return this.http.get<AvailablePlansApiResponse>(`${environment.bookingApiUrl}/available-plans`).pipe(
      map((res) => {
        // get the users current plan
        const currentPlan = this._subscriptionStatus.getValue().plan;

        // filter out plans which are not available and the user's current plan
        const eligibleSwitchPlans = res.data
          .filter((planObj) => planObj.is_available && planObj.id !== currentPlan)
          .map((planObj) => planObj.id); // return only the plan id (DirectPlan)

        return eligibleSwitchPlans;
      })
    );
  }

  private formatCurrencyWithoutTrailingZeros(currencyStr: string): string {
    return currencyStr.replace(/\.00$/, '');
  }

  // Mock function for simulated API response
  private getMockApiResponse(): AvailablePlansApiResponse {
    return {
      data: [
        {
          id: DirectPlan.Lite,
          is_available: true,
          available_countries: ['EE', 'FR', 'GB', 'US'],
          guest_service_fees: {
            fixed: null,
            percentage: null,
          },
          per_property_fee: { amount: 100, currency: 'GBP', formatted_decimal: '1.00', formatted_string: '£1.00' },
        },
        {
          id: DirectPlan.Premium,
          is_available: true,
          available_countries: ['US', 'AU'],
          guest_service_fees: {
            fixed: { amount: 1000, currency: 'GBP', formatted_decimal: '10.00', formatted_string: '£10.00' },
            percentage: { formatted_decimal: '6', formatted_string: '6%', value: 0.06 },
          },
          per_property_fee: { amount: 500, currency: 'GBP', formatted_decimal: '5.00', formatted_string: '£5.00' },
        },
        {
          id: DirectPlan.Basic,
          is_available: true,
          available_countries: ['EE', 'FR', 'GB', 'US'],
          guest_service_fees: {
            fixed: { amount: 0, currency: 'USD', formatted_decimal: '0.00', formatted_string: '$0.00' },
            percentage: null,
          },
          per_property_fee: { amount: 800, currency: 'GBP', formatted_decimal: '8.00', formatted_string: '£8.00' },
        },
      ],
    };
  }
}
