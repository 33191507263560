import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { config } from '@app/core/app-config';
import { HttpClient } from '@angular/common/http';
import { map, catchError, switchMap } from 'rxjs/operators';
import * as format from 'date-fns/format';
import { FilterService } from '../filter/filter.service';
import { SegmentEvent, SegmentIoService } from '../segmentIo/segment-io.service';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import {
  ApiResponse,
  EditablePropertyAvailabilityRules,
  PropertyAvailabilityRules,
  PropertyAvailabilityRulesResponse,
  PropertyTag,
} from '@app/shared/interfaces';
import { Image } from '@app/shared/components/image-gallery/image-gallery.component';
import { DirectPlan } from '@app/modules/direct/models/direct-subscription.interface';

interface SimplifiedError {
  error: boolean;
  message?: string;
}

export enum DirectPropertyCheckType {
  FILTER_PAYOUT_METHOD_BASIC = 'payout-method-basic',
  FILTER_PAYOUT_METHOD_PREMIUM = 'payout-method-premium',
}

export interface CreateParentChildApiResponse {
  data: {
    updated_users: { id: number; name: string }[];
  };
}

export interface RatePlan {
  uuid: string;
  name: string;
  is_primary: boolean;
  multiple_guest_markup: {
    type: 'amount' | 'percent';
    value: number;
  };
}

export interface SimpleProperty {
  id: number;
  name: string;
  picture: string;
  location: PropertyLocation;
  currency: string;
}

export interface Property {
  access: string;
  address: string;
  amenities: string[];
  bathrooms: number;
  bedrooms: number;
  beds: number;
  check_in_time: string;
  check_out_time: string;
  city: string;
  cleaning_fee: unknown;
  community_fee: unknown;
  counts: {
    airbnb: number;
    booking: number;
    homeaway: number;
    total: number;
  };
  currency: string;
  edited: {
    access: boolean;
    amenities: boolean;
    bathrooms: boolean;
    bedrooms: boolean;
    beds: boolean;
    check_in_time: boolean;
    check_out_time: boolean;
    garages: boolean;
    gardens: boolean;
    gyms: boolean;
    hot_tubs: boolean;
    house_rules: boolean;
    kitchens: boolean;
    living_rooms: boolean;
    neighborhood_overview: boolean;
    notes: boolean;
    offices: boolean;
    patios: boolean;
    person_capacity: boolean;
    photos: boolean;
    pools: boolean;
    space: boolean;
    summary: boolean;
    transit: boolean;
  };
  garages: number;
  gardens: number;
  guests_included: unknown;
  gyms: number;
  hosts: Host[];
  hot_tubs: number;
  house_rules: string;
  ical_feed: string;
  id: number;
  is_new: boolean;
  kitchens: number;
  languages: unknown;
  lat: string;
  lead_listing: unknown;
  linens_fee: unknown;
  listed: boolean;
  listings: Listing[];
  living_rooms: number;
  lng: string;
  location: PropertyLocation;
  management_fee: unknown;
  monthly_discount: unknown;
  muted: boolean;
  name: string;
  neighborhood_overview: string;
  notes: string;
  occupancy_taxes: unknown;
  offices: number;
  patios: number;
  person_capacity: number;
  photos: unknown[];
  picture: string;
  pools: number;
  price_per_extra_person: unknown;
  property_type: unknown;
  public_name: string;
  resort_fee: unknown;
  space: string;
  state: string;
  street: string;
  summary: string;
  sync: boolean;
  tags: string[];
  thumbnail: string;
  timezone: string;
  transit: string;
  types: string[];
  weekly_discount: unknown;
  zip: string;
  imported_calendars: ImportedCalendar[];
}

export interface DirectProperty {
  id: number;
  name: string;
  public_name: string;
  picture: string;
  thumbnail: string;
  listed: boolean;
  checks: DirectPropertyCheck[];
  slug: string;
  google_vacation_rentals_sync: boolean | null;
  google_vacation_rentals_site_id: string | null;
  eligible_sites: {
    site_id: string;
    site_type: 'custom' | 'internal';
    url: string;
    full_url: string;
  }[];
  initial_checked?: boolean; // frontend only
  gvr?: {
    property_id: number;
    sync_enabled: boolean;
    site_id: number;
    status: 'disabled' | 'pending' | 'live' | 'error';
    url: string;
    issues: {
      type: 'info' | 'error';
      issue: string;
    }[];
  };
}

export interface DirectPropertyCheck {
  id: string;
  label: string;
  result: string; // pass / fail
  notes: string | null;
}

export interface ImportedCalendar {
  uuid: string | null; // null during a validate response
  name: string;
  platform: string; // May contain unusual platforms, not our standard list of Airbnb | vrbo | booking
  icon_url: string | null;
  calendar_url: string;
}

export interface PropertyLocation {
  apt: string;
  city: string;
  country: string;
  country_name: string;
  lat: string;
  lon: string;
  state: string;
  street: string;
  zipcode: string;
}

export interface Host {
  first_name: string;
  last_name: string;
  name: string;
  picture: string;
  platform: string;
  platform_id: string;
}

export interface Listing {
  id: number;
  listing_id: string;
  name: string;
  picture: string;
  listing_type: string;
  platform_key: string;
  unified_platform_key: string;
  platform: string;
  public_url: string;
  host: {
    platform_id: number;
    first_name: string;
    last_name: string;
    name: string;
    picture_url: string;
  };
  property: {
    id: string;
    name: string;
    muted: boolean;
  };
  delete?: boolean;
  is_lead_listing?: boolean;
}

export interface EnrichedAttribute {
  key: string;
  value: string;
  meta: {
    description: string;
    example: string;
  };
}

export type ParentChildInteractionType = 'blocking' | 'none-blocking';

export interface ParentChildRelationship {
  configuration: ParentChildInteractionType;
  properties: {
    id: number;
    name: string;
    picture: string;
    type: 'parent' | 'child';
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  private resultsPerPage = 20;
  private availableAmenitiesUrl = `${config.API_URL}/internal/available-amenities`;
  private propertiesUrl = `${config.API_URL}/properties`;
  private propertiesScopingUrl = `${config.API_URL}/scoping/properties`;
  private propertyUrl = `${config.API_URL}/properties/`;
  private accountPropertyTagsUrl = `${config.API_URL}/property-tags`;
  private mergeUrl = `${config.API_URL}/properties/merge`;
  private homeAwayListingsUrl = `${config.API_URL}/listings/homeaway?pagination=false`;
  private strListingsUrl = `${config.API_URL}/listings/str?pagination=false`;
  private markupSettingsUrl = `${config.API_URL}/settings/properties`;

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private segmentIo: SegmentIoService,
    private directSubscriptionService: DirectSubscriptionService
  ) {}

  getProperties(
    filterCriteria?: any[],
    offset?: number,
    search?: string,
    limit?: number,
    paginate = true,
    transformer?: string, // simple
    idList?: any[],
    includeChildren?: boolean
  ): Observable<any> {
    let url;

    if (paginate) {
      url = `${this.propertiesUrl}?limit=${limit ? limit : this.resultsPerPage}`;
    } else {
      url = `${this.propertiesUrl}?pagination=false`;
    }

    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }

    if (transformer) {
      url = `${url}&transformer=${transformer}`;
    }

    if (idList) {
      url = `${url}&ids=${idList.join()}`;
    }

    if (includeChildren) {
      url = `${url}&include=children`;
    }

    return this.http.post<any>(url, { filters: apiFilters }).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getScopingProperties(
    filterCriteria?: any[],
    offset?: number,
    search?: string,
    limit?: number,
    paginate = true
  ): Observable<any> {
    let url;

    if (paginate) {
      url = `${this.propertiesScopingUrl}?limit=${limit ? limit : this.resultsPerPage}`;
    } else {
      url = `${this.propertiesScopingUrl}?pagination=false`;
    }

    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }

    return this.http.post<any>(url, { filters: apiFilters }).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getProperty(id: string | number): Observable<any> {
    const url = `${this.propertyUrl}${id}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        const property = res.data;

        this.determineLeadListing(property);
        return property;
      }),
      catchError(this.handleGenericError)
    );
  }

  getAccountPropertyTags(): Observable<PropertyTag[]> {
    return this.http.get<ApiResponse<PropertyTag[]>>(this.accountPropertyTagsUrl).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  // For a given property - mark it's listings with `is_lead_listing` boolean
  private determineLeadListing(property: any): void {
    if (property.lead_listing) {
      property.listings.forEach((listing) => {
        listing.is_lead_listing =
          property.lead_listing.listing_id === String(listing.listing_id) &&
          property.lead_listing.user_id === String(listing.host?.platform_id);
      });
    }

    property.listings.sort((x, y) => y.is_lead_listing - x.is_lead_listing);
  }

  getListings(withMarkups = false): Observable<Listing[]> {
    const homeaway$ = this.http.get(this.homeAwayListingsUrl);
    const str$ = this.http.get(this.strListingsUrl);
    const markups$ = withMarkups ? this.http.get(this.markupSettingsUrl) : of([]);

    return forkJoin([homeaway$, str$, markups$]).pipe(
      map((res: any) => {
        let listings = [];

        listings = listings.concat(res[0].data);
        listings = listings.concat(res[1].data);

        if (res[2].data && res[2].data.listing_markups) {
          listings.forEach((listing, index) => {
            const markupObj = res[2].data.listing_markups.find((x) => x.listing_id === listing.listing_id);
            const markupVal = markupObj && markupObj.markup ? markupObj.markup : null;
            listings[index].markup = markupVal ? markupVal * 100 : markupVal;
          });
        }

        return listings;
      })
    );
  }

  /**
   * Merge properties
   * @param propertyIds Array of property ids. Must also include the lead_listing_id if being used
   * @param lead_property_id An optional parameter to specify which of the properties should
   * be considered the lead. Originated as a way to allow merging muted and unmuted properties
   * and the result be an unmuted property. Can be expanded in the future to allow specific lead
   * listing selection.
   * @returns Observable
   */
  mergeProperties(propertyIds: any[], lead_property_id: number = null): Observable<any> {
    const url = `${this.mergeUrl}`;

    if (lead_property_id && !propertyIds.includes(lead_property_id)) {
      throw new Error('"lead_property_id" must exist in the "propertyIds" array');
    }

    return this.http.put<any>(url, { property_ids: propertyIds, lead_property_id }).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleMergeError)
    );
  }

  updateProperty(propertyId: string, property: any) {
    const url = `${this.propertyUrl}${propertyId}`;

    return this.http.put<any>(url, property).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleUpdateError)
    );
  }

  addListing(propertyId: string, payload: any) {
    const url = `${this.propertyUrl}${propertyId}/link`;

    return this.http.put<any>(url, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }

  removeListing(propertyId: string, payload: any) {
    const url = `${this.propertyUrl}${propertyId}/unlink`;

    return this.http.put<any>(url, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => this.handleUpdateError(err))
    );
  }

  getPricingAndAvailability(propertyId: any, start: Date, end: Date) {
    const formattedStart = format(start, 'YYYY-MM-DD');
    const formattedEnd = format(end, 'YYYY-MM-DD');

    const url = `${this.propertyUrl}${propertyId}/prices-and-availability?start=${formattedStart}&end=${formattedEnd}&flags[events_only]=true`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleGenericError)
    );
  }

  savePricingAndAvailability({
    propertyId,
    start,
    end,
    daysOfTheWeek,
    available,
    price,
    lengthOfStay,
    note,
    closed_for_checkin,
    closed_for_checkout,
  }: PropertyAvailabilityRulesResponse) {
    const url = `${this.propertyUrl}${propertyId}/calendar?flags[events_only]=true`;

    const data = { start, end };
    if (daysOfTheWeek?.length) {
      data['days_of_the_week'] = daysOfTheWeek;
    }

    if (note !== null) {
      data['note'] = note;
    }

    if (typeof available !== 'undefined' && available !== null) {
      data['available'] = available;
    }

    // only send a price if greater than 0
    if (price > 0) {
      data['price'] = price;
    }

    if (lengthOfStay) {
      data['minimum_length_of_stay'] = lengthOfStay;
    }

    if (
      typeof closed_for_checkin !== 'undefined' &&
      typeof closed_for_checkout !== 'undefined' &&
      closed_for_checkin !== null &&
      closed_for_checkout !== null
    ) {
      data['closed_for_checkin'] = closed_for_checkin;
      data['closed_for_checkout'] = closed_for_checkout;
    }

    return this.http.post(url, data).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleGenericError)
    );
  }

  resetCalendarAccess(propertyId: any) {
    const url = `${this.propertiesUrl}/${propertyId}/feed`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        if (res.data?.ical_feed) {
          return res.data.ical_feed;
        }
      })
    );
  }

  public getBookingRatePlans(propertyId: any) {
    return this.http.get(`${this.propertiesUrl}/${propertyId}/rate-plans`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  public updateRatePlan(propertyId: any, ratePlan: RatePlan) {
    return this.http.put(`${this.propertiesUrl}/${propertyId}/rate-plans`, ratePlan).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  resyncPropertyDetails(id: string) {
    return this.http.get(`${config.API_URL}/properties/${id}/trigger_resync`);
  }

  availableAmenities() {
    return this.http.get(this.availableAmenitiesUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  uploadPropertyImage(propertyId: string, image: any, order: number) {
    const formData = new FormData();

    formData.append('image', image);
    formData.append('order', order.toString());

    return this.http.post(`${config.API_URL}/properties/${propertyId}/image`, formData);
  }

  deletePropertyImage(propertyId: string, imageId: number) {
    return this.http.delete(`${config.API_URL}/properties/${propertyId}/image/${imageId}`);
  }

  updateImageGalleryOrdersAndCaptions(propertyId: string, images: Image[]) {
    return this.http.put(`${config.API_URL}/properties/${propertyId}/images`, { images });
  }

  setListingAsLeadListing(propertyId: string, listingId: string, platform: string): Observable<boolean> {
    const payload = {
      platform,
      listing_id: listingId,
    };

    return this.http.post(`${config.API_URL}/properties/${propertyId}/lead-listing`, payload).pipe(map((res) => true));
  }

  fetchPropertyFeesDiscounts(propertyId: string) {
    return this.http.get(`${config.API_URL}/properties/${propertyId}/pricing-settings`).pipe(
      map((res: { data: unknown }) => {
        Object.keys(res.data).forEach((key) => {
          if (res.data[key] === null) {
            res.data[key] = {
              type: key.indexOf('discount') > -1 ? 'percent' : 'fixed',
              value: null,
              edited: false,
            };
          }
        });
        return res.data;
      }),
      catchError(this.handleGenericError)
    );
  }

  updatePropertyFeesDiscounts(propertyId: string, key: string, data: any) {
    // Segment tracking
    this.segmentIo.track(SegmentEvent.PropertiesUpdatedFeesDiscounts, {
      key,
    });

    const payload = Object.assign({}, data);

    if ('edited' in payload) {
      delete payload.edited;
    }

    payload.value = Number(payload.value);

    if (isNaN(payload.value)) {
      payload.value = 0;
    }

    if (payload.value < 0) {
      payload.value = 0;
    }

    if (payload.value > 100 && payload.type === 'percent') {
      payload.value = 100;
    }

    data.value = payload.value;
    data.edited = true;

    return this.http.put(`${config.API_URL}/properties/${propertyId}/pricing-settings`, { [key]: payload });
  }

  deleteImportedCalendar(calendarUuid: string): Observable<string> {
    return this.http.delete<{ message: string }>(`${config.API_URL}/listings/ical/${calendarUuid}`).pipe(
      map((res) => {
        return res.message;
      })
    );
  }

  validatePotentialImportedCalendar(propertyId: string, calendarUrl: string): Observable<ImportedCalendar> {
    return this.http
      .post(`${config.API_URL}/listings/ical/${propertyId}/validate`, {
        url: calendarUrl,
      })
      .pipe(map((res: { data: ImportedCalendar }) => res.data));
  }

  saveNewImportedCalendar(propertyId: string, calendarUrl: string): Observable<ImportedCalendar> {
    return this.http
      .post(`${config.API_URL}/listings/ical/${propertyId}`, {
        url: calendarUrl,
      })
      .pipe(
        map((res: { data: ImportedCalendar }) => {
          return res.data;
        })
      );
  }

  getDirectProperties(check?: string, site_uuid?: string, propertyId?: number): Observable<DirectProperty[]> {
    if (!check) {
      return this.directSubscriptionService.subscriptionStatus$.pipe(
        switchMap((sub) => {
          const plan = sub.plan;
          let checkValue = '';

          switch (plan) {
            case DirectPlan.Basic:
              checkValue = 'payout-method-basic';
              break;

            case DirectPlan.Premium:
              checkValue = 'payout-method-premium';
              break;

            case DirectPlan.Lite:
              checkValue = 'payout-method-lite';
              break;

            default:
              break;
          }

          let url = `${config.API_URL}/properties/direct?check=${checkValue}&pagination=false`;

          if (site_uuid) {
            url = `${url}&site_id=${site_uuid}`;
          }

          if (propertyId) {
            url = `${url}&ids=${propertyId}`;
          }

          return this.http.get<{ data: DirectProperty[] }>(url).pipe(map((res) => res.data));
        })
      );
    } else {
      return this.http
        .get<{ data: DirectProperty[] }>(`${config.API_URL}/properties/direct?check=${check}&pagination=false`)
        .pipe(map((res) => res.data));
    }
  }

  updatePropertyDirectSlug(propertyId: number, newSlug: string) {
    return this.http.post(`${config.API_URL}/properties/${propertyId}/site-slug`, { slug: newSlug });
  }

  updateGoogleVacationRentalForProperty(propertyId: number, enable: boolean) {
    return this.http.post(`${config.API_URL}/properties/${propertyId}/google-vacation-rentals`, { enabled: enable });
  }

  updateGoogleVacationRentalsForAllProperties(properties: { property_id: number; site_id: string }[]) {
    return this.http.post(`${config.API_URL}/properties/google-vacation-rentals`, {
      properties: properties,
    });
  }

  getPropertyEnrichedAttributes(propertyId: number | string): Observable<EnrichedAttribute[]> {
    return this.http.get<{ data: EnrichedAttribute[] }>(`${this.propertiesUrl}/${propertyId}/enriched-attributes`).pipe(
      map((res) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  updatePropertyEnrichedAttribute(propertyId: number, payload: { key: string; value: string }) {
    const url = `${this.propertyUrl}${propertyId}/enriched-attributes/${payload.key}`;

    return this.http.put<any>(url, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => this.handleUpdateError(err))
    );
  }

  createParentChildRelationship(
    propertyIds: number[],
    parentId: number,
    interactionType: ParentChildInteractionType
  ): Observable<CreateParentChildApiResponse> {
    const payload = {
      parent_id: parentId,
      property_ids: propertyIds,
      configuration: interactionType,
    };

    return this.http.post<CreateParentChildApiResponse>(`${config.API_URL}/properties/parent-child`, payload);
  }

  validateParentChildProperties(propertiesIds: number[]) {
    return this.http.post(`${config.API_URL}/properties/parent-child/validate`, { property_ids: propertiesIds });
  }

  getParentChildRelationship(propertyId: number): Observable<ParentChildRelationship> {
    return this.http
      .get<{ data: ParentChildRelationship }>(`${config.API_URL}/properties/parent-child/${propertyId}`)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  removeParentChildRelationship(propertyId: number) {
    return this.http.delete(`${config.API_URL}/properties/parent-child/${propertyId}`);
  }

  public getPropertyAvailabilityRules(propertyId: string) {
    return this.http
      .get<{ data: PropertyAvailabilityRules }>(`${config.API_URL}/properties/${propertyId}/availability-rules`)
      .pipe(
        map((res) => res.data),
        catchError(() => this.handleGenericError())
      );
  }

  public updatePropertyAvailabilityRules(propertyId: string, rules: EditablePropertyAvailabilityRules) {
    return this.http
      .put<PropertyAvailabilityRules>(`${config.API_URL}/properties/${propertyId}/availability-rules`, rules)
      .pipe(catchError((err) => this.handleUpdateError(err)));
  }

  public isSimplifiedError(err: any): err is SimplifiedError {
    return err.error !== undefined;
  }

  private handleError(val: any = []) {
    return of(val);
  }

  private handleGenericError(): Observable<SimplifiedError> {
    return of({ error: true });
  }

  private handleUpdateError(err): Observable<SimplifiedError> {
    return of({
      error: true,
      message: err.error.message,
    });
  }

  private handleMergeError(err) {
    return of(err);
  }
}
