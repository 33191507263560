<ng-container *ngIf="knowledge$ | async as state">
  <!-- loaded state -->
  <ng-container *ngIf="!state.loading && !state.error">
    <!-- Empty state -->
    <div
      [hidden]="state.knowledge && state.knowledge.length > 0"
      class="border rounded border-smoke p-12 flex flex-col items-center gap-6">
      <ng-container *ngTemplateOutlet="emptyStateSvg"></ng-container>

      <h3 class="text-lg text-center">
        {{ state.uploading ? 'Adding' : 'Add' }} a guidebook or manual for {{ state.property.name }}
        {{ state.uploading ? '...' : '' }}
      </h3>

      <div class="text-center text-gray-600">Upload a PDF file to help the AI provide better responses to guests.</div>
      <div class="text-center text-gray-600">
        Useful information for the Knowledge Hub includes: rules and policies, operating instructions, local area and
        restaurant recommendations, and safety and emergency notes.
      </div>

      <!-- Uploading PDF -->
      <mat-progress-bar
        *ngIf="state.uploading"
        class="max-w-3xl"
        mode="determinate"
        [value]="
          state.uploadProgress.length > 0 ? state.uploadProgress[state.uploadProgress.length - 1].progress * 100 : 0
        "></mat-progress-bar>
      <div *ngIf="state.uploadProgress.length > 0" class="flex flex-col-reverse gap-2">
        <div
          *ngFor="let progress of state.uploadProgress; let last = last; trackBy: trackProgressFn"
          class="text-center text-smoke font-bold animate-fade-down animate-once animate-duration-200"
          [class.!text-grass-darker]="last"
          [class.animate-pulse]="last">
          {{ progress.message }}...
        </div>
      </div>

      <!-- Import from PDF -->
      <div [hidden]="state.uploading" class="mt-4">
        <input
          #fileUploadInput
          id="file"
          type="file"
          accept="application/pdf"
          class="hidden"
          (change)="onImportPDFClicked(fileUploadInput)" />
        <label for="file" class="bg-raspberry-darker p-4 rounded text-white cursor-pointer hover:brightness-95">
          Import from PDF
        </label>
      </div>
    </div>

    <!-- Happy path with data -->
    <ng-container *ngIf="state.knowledge && state.knowledge.length > 0">
      <!-- Sample questions -->
      <section
        *ngIf="state.questions && state.questions.length > 0"
        class="border rounded border-smoke bg-white shadow p-4 mt-4 mb-8 top-8 z-10"
        [class.sticky]="!aiToggleOpen">
        <sbnb-collapsible-card [showDivider]="false" (openToggled)="onAiToggle($event)">
          <h4 class="text-grape-darker flex gap-2 items-center" title>
            <img
              class="question-icon"
              src="/assets/iconography/question.svg"
              onload="SVGInject(this)"
              width="16"
              height="16" />
            <span>Ask the AI</span>
          </h4>

          <ng-template sbnbCollapsibleCardContent>
            <div class="text-sm mt-1 text-night-lighter">
              Check how the AI will use the Knowledge Hub to respond to typical questions a guest might ask.
            </div>

            <section class="mt-3 divide-y divide-smoke">
              <div
                *ngFor="let question of state.questions"
                class="hover:bg-smoke-lighter cursor-pointer -mx-4 px-4 group"
                [class.bg-grass-lighter]="question.computed_reply"
                (click)="onTestResponse(question)">
                <div class="flex items-center gap-2 py-2 h-11">
                  <div class="flex-1" [class.strong]="question.computed_reply">
                    {{ question.query }}
                  </div>
                  <mat-spinner *ngIf="question.computed_loading" diameter="16"></mat-spinner>
                  <sbnb-button
                    *ngIf="!question.computed_loading"
                    class="invisible group-hover:visible"
                    size="mini"
                    type="secondary">
                    Ask question
                  </sbnb-button>
                </div>

                <div *ngIf="question.computed_reply" class="text-sm text-night pb-4">
                  {{ question.computed_reply }}
                </div>
              </div>
            </section>
          </ng-template>
        </sbnb-collapsible-card>
      </section>

      <section class="flex flex-col lg:flex-row justify-between gap-4 border-b border-smoke pb-1 mt-10">
        <div class="flex gap-2 justify-between">
          <h4 class="text-lg text-grape-darker strong">Knowledge for this property</h4>
          <div class="flex lg:hidden">
            <mat-icon class="text-night cursor-pointer hover:brightness-150" [matMenuTriggerFor]="actionsMenu">
              more_vert
            </mat-icon>
          </div>
        </div>

        <div class="flex items-start gap-2">
          <sbnb-search-bar
            class="flex-1 min-w-60 lg:flex-none"
            placeholderText="Search property knowledge..."
            [enterRequiredToSearch]="false"
            (searchChanged)="searchCriteria.next($event)"></sbnb-search-bar>

          <sbnb-button size="mini" type="secondary" (click)="triggerPosthogFeedback()">Give feedback</sbnb-button>

          <div class="hidden lg:flex w-4 flex-shrink-0 flex-grow-0 mt-1">
            <mat-icon class="text-night cursor-pointer hover:brightness-150" [matMenuTriggerFor]="actionsMenu">
              more_vert
            </mat-icon>
          </div>
        </div>

        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="onRewriteAllInformation()">
            <span>Rewrite all information</span>
          </button>
        </mat-menu>
      </section>

      <section *ngFor="let knowledge of filteredKnowledge$ | async">
        <div
          class="flex border-b border-smoke items-center py-4 gap-12 cursor-pointer hover:bg-smoke-lighter px-4"
          (click)="knowledge.computed_expanded = !knowledge.computed_expanded">
          <div class="flex items-center gap-2 flex-1">
            <div class="flex flex-col gap-2">
              <strong class="text-grape-darker">{{ knowledge.topic }}</strong>
            </div>
            <mat-icon class="transition-all scale-105" [class.expand-open]="knowledge.computed_expanded">
              expand_more
            </mat-icon>
          </div>
          <div *ngIf="!knowledge.computed_expanded" class="text-xs hidden md:block">
            {{ knowledge.updated_at | date: 'longDate' }}
          </div>
        </div>

        <!-- parent has multiple children -->
        <div *ngIf="knowledge.computed_expanded">
          <div
            *ngFor="let item of knowledge.items"
            class="flex text-sm py-3 gap-12 pl-8 items-center border-b border-smoke px-4">
            <div class="flex-1 break-words whitespace-pre-line">
              {{ item.content }}
            </div>
            <div class="text-xs hidden md:block">{{ item.updated_at | date: 'longDate' }}</div>
            <div class="w-5">
              <ng-container
                [ngTemplateOutlet]="actionsTemplate"
                [ngTemplateOutletContext]="{
                  id: item.id,
                  property: state.property.id,
                  content: item.content,
                  topicName: knowledge.topic
                }"></ng-container>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </ng-container>

  <!-- loading state -->
  <sbnb-loading-spinner *ngIf="state.loading"></sbnb-loading-spinner>

  <!-- error state -->
  <div *ngIf="state.error" class="error">{{ state.error }}</div>

  <!-- debug -->
  <!-- <pre class="text-xs">{{ state | json }}</pre> -->
  <!-- <pre class="text-xs">{{ filteredKnowledge$ | async | json }}</pre> -->
</ng-container>

<ng-template #actionsTemplate let-id="id" let-property="property" let-content="content" let-topicName="topicName">
  <img
    src="/assets/iconography/more.svg"
    class="w-5 h-5 cursor-pointer"
    [matMenuTriggerFor]="actionsMenu"
    (click)="$event.stopPropagation()" />

  <mat-menu #actionsMenu="matMenu">
    <button mat-menu-item (click)="openEditDialog(id, property, content, topicName)">
      <span>Edit</span>
    </button>
    <button mat-menu-item (click)="onDeleteItemClicked(id, property)">
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #emptyStateSvg>
  <img src="/assets/images/knowledge-hub/empty-state-book-lightbulb.svg" alt="Empty state" />
</ng-template>
