import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sbnbIcon } from '@app/ui/icons/sbnb-icons';

@Component({
  selector: 'sbnb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  /**
   * We have various types of pre-defined buttons which dictate their base styling.
   * Current options are: primary, primary-teal, secondary, text (default to 'primary' if not specified)
   */
  @Input() type:
    | 'primary'
    | 'primary-teal'
    | 'primary-grape'
    | 'secondary'
    | 'secondary-danger'
    | 'text'
    | 'text-secondary' = 'primary';

  /**
   * The size/height of the button
   * Current options are: normal (48px), mini (32px). Defaults to 'normal' if not specified
   */
  @Input() size: 'normal' | 'mini' = 'normal';

  /**
   * A way of selecting an icon for a button (left of the label, same color as the label)
   * Expects a string, such as 'delete' or `accounting_coins_stack
   * This will be displayed using our sbnb-icon component
   */
  @Input() icon: sbnbIcon;

  /**
   * The label displayed on the button
   */
  @Input() label: string;

  /**
   * If a loadingLabel is specified, the label will be replaced with this while the button is in a loading state
   */
  @Input() loadingLabel: string;

  /**
   * The color of the label - typically only used for 'text' button types
   */
  @Input() labelColor: string;

  /**
   * Is the button currently in a loading state?
   * This disables the button, and changes the styling to add a spinner
   */
  @Input() loading = false;

  /**
   * Is this button currently disabled? (Prevents all click events)
   */
  @Input() disabled = false;

  /**
   * Expand to fit the parents width?
   */
  @Input() fullWidth = false;

  /**
   * Specify an exact width of the button
   */
  @Input() width: string;

  /**
   * An EventEmitter which emits when the button has been clicked
   */
  @Output() clicked: EventEmitter<null> = new EventEmitter();

  public _width?: string = null;

  constructor() {}

  ngOnInit(): void {
    if (this.fullWidth) {
      this._width = '100%';
    } else if (this.width) {
      this._width = this.width;
    }
  }
}
