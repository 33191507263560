import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROPERTIES_FEATURE_KEY, PropertiesState } from './properties.reducer';

// Lookup the 'Properties' feature state managed by NgRx
export const getPropertiesState = createFeatureSelector<PropertiesState>(PROPERTIES_FEATURE_KEY);
export const getPropertiesLoadingState = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.properties.loadingState
);
export const getPropertyEntities = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.properties.data
);
export const getProperties = createSelector(getPropertyEntities, (entities: PropertiesState['properties']['data']) => {
  const properties = entities ? Object.keys(entities).map((id) => entities[id]) : [];

  if (properties && properties.length > 0) {
    return properties;
  } else {
    return [];
  }
});

export const getPropertiesMergeCandidatesLoadingState = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.propertyMergeCandidates.loadingState
);
export const getPropertiesMergeCandidateEntities = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.propertyMergeCandidates.data
);

export const getPropertiesPMSCheckLoadingState = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.propertiesPMSCheck.loadingState
);
export const getPropertiesPMSCheck = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.propertiesPMSCheck.data
);

export const getBulkMuteAndUnMutePropertiesLoadingState = createSelector(
  getPropertiesState,
  (state: PropertiesState) => state.bulkMuteAndUnMuteProperties.loadingState
);
