import { LocalStorageKey } from '@app/shared/interfaces';
import { info } from 'console';

export function countWords(str): number {
  const arr = str.split(' ');
  return arr.filter((word) => word !== '').length;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getBoolean(value: any) {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}

export const retrieveUserFromLocalStorage = () => {
  const userObj = JSON.parse(localStorage.getItem(LocalStorageKey.user) ?? '{}');
  return userObj ? userObj : {};
};

export function decodeToRealUserId(obfuscatedId: string | number, email: string): string | undefined {
  if (!obfuscatedId || !email) {
    return undefined;
  }

  const code = email.charCodeAt(0) + '';
  const xor = parseInt(code, 10).toString(2);
  const actual_id = Number(obfuscatedId) ^ +xor;

  return actual_id + '';
}

export const dedupeArray = (arr: any[]) => Array.from(new Set(arr));

export class Logger {
  /**
   * High contrast log for logs containing information about concrete events
   * @param msg
   */
  static log(...msg) {
    // eslint-disable-next-line no-console
    console.log(`%c ${msg}`, 'background: #230b41; color: #ed3c6a');
  }

  /**
   * Slightly more muted log for logs containing read only information, like that a service initialized
   * @param msg
   */
  static info(...msg) {
    // eslint-disable-next-line no-console
    console.info(`%c ${msg}`, 'background: #f6f0ff; color: #9571c3');
  }
}
