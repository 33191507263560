import { Injectable } from '@angular/core';
import { ApiErrorResponse, PropertyMergeCandidate } from '@app/shared/interfaces';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of, Observable, forkJoin, EMPTY } from 'rxjs';
import * as PropertiesActions from './properties.actions';
import { PropertiesService } from './properties.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { PropertiesPMSCheck, PropertiesResponse } from './properties.models';

@Injectable()
export class PropertiesEffects {
  loadProperties$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PropertiesActions.loadProperties),
        map((action) => action),
        mergeMap((action) =>
          this.propertiesService
            .loadProperties(
              action.paginate,
              action.filterCriteria,
              action.offset,
              action.search,
              action.limit,
              action.transformer,
              action.idList
            )
            .pipe(
              mergeMap((response: PropertiesResponse) => {
                return of(
                  PropertiesActions.loadPropertiesSuccess({
                    response,
                  })
                );
              }),
              catchError((error: ApiErrorResponse) => of(PropertiesActions.loadPropertiesFailure({ response: error })))
            )
        )
      )
  );

  loadPropertyMergeCandidate$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(PropertiesActions.loadPropertiesMergeCandidates),
        map((action) => action),
        switchMap((action) => {
          const { propertyIds, listingIds } = action;

          if (propertyIds.length === 0) {
            return EMPTY;
          }

          const requests = propertyIds.map((id) => {
            return this.propertiesService.getPropertyMergeCandidate(id);
          });

          return forkJoin(requests).pipe(
            map((rawResponse: PropertyMergeCandidate[]) => {
              const response: PropertyMergeCandidate[] = [];

              for (let i = 0; i < propertyIds.length; i++) {
                response.push({
                  propertyId: propertyIds[i],
                  listingId: listingIds[i],
                  ...rawResponse[i],
                });
              }

              return PropertiesActions.loadPropertiesMergeCandidatesSuccess({
                response,
              });
            }),
            catchError((error: ApiErrorResponse) =>
              of(
                PropertiesActions.loadPropertiesMergeCandidatesFailure({
                  response: error,
                })
              )
            )
          );
        })
      )
  );

  propertiesPMSCheck$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PropertiesActions.propertiesPMSCheck),
        map((action) => action),
        mergeMap((action) =>
          this.propertiesService.propertiesPMSCheck().pipe(
            mergeMap((response: PropertiesPMSCheck) => {
              return of(
                PropertiesActions.propertiesPMSCheckSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) =>
              of(PropertiesActions.propertiesPMSCheckFailure({ response: error }))
            )
          )
        )
      )
  );

  acknowledgePropertiesPMSCheck$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PropertiesActions.acknowledgePropertiesPMSCheck),
        map((action) => action),
        mergeMap((action) =>
          this.propertiesService.acknowledgePropertiesPMSCheck().pipe(
            mergeMap(() => {
              return of(PropertiesActions.acknowledgePropertiesPMSCheckSuccess());
            }),
            catchError((error: ApiErrorResponse) =>
              of(PropertiesActions.acknowledgePropertiesPMSCheckFailure({ response: error }))
            )
          )
        )
      )
  );

  bulkMuteUnmuteProperties$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PropertiesActions.bulkMuteAndUnMuteProperties),
        map((action) => action),
        mergeMap((action) =>
          this.propertiesService.bulkMuteAndUnMuteProperties(action.mute, action.unmute, action.requestOverride).pipe(
            mergeMap(() => {
              return of(PropertiesActions.bulkMuteAndUnMutePropertiesSuccess());
            }),
            catchError((error: ApiErrorResponse) =>
              of(PropertiesActions.bulkMuteAndUnMutePropertiesFailure({ response: error }))
            )
          )
        )
      )
  );

  constructor(
    private readonly actions$: Actions,
    private propertiesService: PropertiesService
  ) {}
}
