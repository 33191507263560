import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@app/core/not-found/not-found.component';
import { KnowledgeHarnessComponent } from '@app/modules/knowledge/components/knowledge-harness.component';
import { OnesignalPushNotificationHandlerComponent } from '@app/shared/components/onesignal-push-notification-handler/onesignal-push-notification-handler.component';
import { PageLoadingComponent } from '@app/shared/components/page-loading/page-loading.component';
import { HasEntitlementForFeatureGuard } from '@app/shared/guards/has-entitlement-for-feature.guard';
import { EntitlementEnum } from '@app/shared/interfaces';
import { environment } from '@env/environment';
import { AuthGuard } from './core/authentication/auth.guard';
import { TurnoActivateComponent } from './shared/components/turno-activate/turno-activate.component';

const routes: Routes = [
  {
    // Partner discount codes during signup
    path: 'partners',
    loadChildren: () => import('./modules/partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
    data: {
      identifier: 'dashboard',
    },
  },
  {
    path: 'inbox',
    loadChildren: () => import('./modules/inbox/inbox.module').then((m) => m.InboxModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'threads',
    redirectTo: 'inbox',
    pathMatch: 'prefix',
  },
  {
    path: 'gx',
    loadChildren: () =>
      import('./modules/guest-experience/guest-experience.module').then((m) => m.GuestExperienceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'properties',
    loadChildren: () => import('./modules/properties/properties.module').then((m) => m.PropertiesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'operations',
    loadChildren: () => import('./modules/operations/operations.module').then((m) => m.OperationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar',
    loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'apps',
    loadChildren: () => import('./modules/apps/apps.module').then((m) => m.AppsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'devices',
    loadChildren: () => import('./modules/smart-devices/smart-devices.module').then((m) => m.SmartDevicesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./modules/connected-accounts/connected-accounts.module').then((m) => m.ConnectedAccountsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'support-documentation',
    loadChildren: () =>
      import('./modules/support-documentation/support-documentation.module').then((m) => m.SupportDocumentationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'referrals',
    loadChildren: () => import('./modules/referrals/referrals.module').then((m) => m.ReferralsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'direct',
    loadChildren: () => import('./modules/direct/direct.module').then((m) => m.DirectModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then((m) => m.DemoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'metrics',
    canActivate: [HasEntitlementForFeatureGuard],
    data: {
      feature: EntitlementEnum.Metrics,
      callbackFn: () => {
        window.location.href = environment.metricsV2Url;
      },
      redirectPath: '/demo/metrics',
    },
    component: PageLoadingComponent,
  },
  {
    path: 'turno',
    component: TurnoActivateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'push-action/:action',
    component: OnesignalPushNotificationHandlerComponent,
  },
  {
    path: 'knowledge/:propertyId',
    component: KnowledgeHarnessComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
