import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { NotificationType } from '@app/shared/interfaces';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { ErrorService } from '@app/shared/services/error/error.service';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserPermissionsService } from '../user-permissions/user-permissions.service';
import { Logger } from '@app/shared/utils';

declare let ga: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private errorService: ErrorService,
    private deviceDetectionService: DeviceDetectionService,
    private toast: ToastNotificationsService,
    private permissions: UserPermissionsService,
    private segmentIoService: SegmentIoService,
    private csService: InternalCsService,
    private demoService: DemoService,
    private posthog: PosthogService
  ) {}

  parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (err) {}
  }

  isTokenExpired(expiration): boolean {
    return Date.now() >= expiration * 1000;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getCurrentToken();
    const isCS = this.auth.isCS() ? 'true' : 'false';
    let googleAnalyticsClientId = null;

    try {
      googleAnalyticsClientId = ga.getAll()[0].get('clientId');
    } catch (err) {
      // no action required
    }

    const headers = {
      Authorization: `Bearer ${authToken}`,
      Accept: 'application/json',
      'Authorization-CS': isCS,
      'Authorization-CS-debug': this.csService._debugMode.getValue()?.toString(),
      'sbnb-app-ios': this.deviceDetectionService.isIosApp().toString(),
      'sbnb-app-android': this.deviceDetectionService.isAndroidApp().toString(),
      'X-Posthog-Session-ID': this.posthog.getSessionId(),
    };

    if (googleAnalyticsClientId) {
      headers['GA-Client'] = googleAnalyticsClientId;
    }

    if (req.headers.get('Authorization')) {
      headers.Authorization = req.headers.get('Authorization');
    }

    const authReq = req.clone({
      setHeaders: headers,
    });

    if (req.headers.get('X-Skip-Interceptor') === 'true') {
      return next.handle(authReq);
    }

    return next.handle(authReq).pipe(catchError(this.handleError));
  }

  isFalsePositiveLogout(authToken): boolean {
    if (authToken) {
      const parsedJwt = this.parseJwt(authToken);
      return this.isTokenExpired(parsedJwt?.exp) ? false : true;
    }
    return false;
  }

  private handleError = (error: HttpErrorResponse) => {
    const authToken = this.auth.getCurrentToken();
    if (error.status === 401 && authToken) {
      // Swallow any error
      try {
        if (this.isFalsePositiveLogout(authToken)) {
          this.logFalsePositiveLogout(401, error);
          Logger.info('[Interceptor] isFalsePositiveLogout Code 401', error);
        }
      } catch (err) {}

      this.auth.logout();
      this.router.navigate(['/user/hello']);
      window.location.reload();
    }

    if (error.status === 403) {
      if (error.error.message) {
        this.toast.open(error.error.message, 'Dismiss', NotificationType.Error, 999999);
      }

      if (error.error.logout) {
        // Swallow any error
        try {
          if (this.isFalsePositiveLogout(authToken)) {
            this.logFalsePositiveLogout(403, error);
          }
        } catch (err) {}

        this.auth.logout();
        return;
      }

      if (error.error.redirect) {
        this.router.navigateByUrl(error.error.redirect);
      }
    }

    // Expired status
    if (error.status === 402) {
      this.permissions.loaded.subscribe((loaded) => {
        if (!loaded) return;

        this.permissions.isPrimaryUser().subscribe((isPrimaryUser) => {
          // redirect secondary users to expired notice
          if (!isPrimaryUser) {
            this.router.navigate(['/settings/subscription/expired']);
            return;
          }
          // if the admin is already in the settings section,
          // don't redirect them
          if (this.isInSubscriptionSection()) return;

          this.router.navigate(['/settings/your-plan']);
        });
      });
    }

    if (error.status === 412) {
      if (this.demoService.isDemo()) {
        this.segmentIoService.track(SegmentEvent.DemoMode412, {
          url: error.url,
        });
        return;
      }

      this.permissions.loaded.subscribe((loaded) => {
        if (!loaded) return;

        const isAdmin = this.permissions.isAdmin();
        // secondary users need their primary user to connect an account
        // before they can use the product
        if (!isAdmin) {
          this.router.navigate(['/accounts/denied']);
          return;
        }

        // if the admin is in the settings section,
        // don't redirect them
        if (this.isInSubscriptionSection()) {
          return;
        }

        const isDesktop = window.innerWidth > 600;
        const redirectTo = this.redirectUrl(this.router.url);
        if (isDesktop && redirectTo) {
          this.router.navigate([redirectTo]);
          return;
        }

        // Fall back to the accounts page as a default
        this.router.navigate(['/accounts']);
      });
    }

    if (error.status === 413) {
      this.errorService.handle413(error);
    }

    if (error.status === 429) {
      this.errorService.handle429(error);
    }

    if (error.status === 422) {
      this.errorService.handle422(error);
    }

    if (error.status === 500) {
      this.errorService.handle500(error);
    }

    return throwError(error);
  };

  redirectUrl(url: string): string | undefined {
    let redirectToUrl;
    const urls = [
      {
        url: 'dashboard',
        redirectTo: '/demo/dashboard',
      },
      {
        url: 'inbox',
        redirectTo: '/demo/inbox',
      },
      {
        url: 'properties',
        redirectTo: '/demo/properties',
      },
      {
        url: 'calendar',
        redirectTo: '/demo/calendar',
      },
      {
        url: 'gx',
        redirectTo: '/demo/gx',
      },
      {
        url: 'operations',
        redirectTo: '/demo/operations',
      },
      {
        url: 'direct',
        redirectTo: '/demo/direct',
      },
    ];
    for (let i = 0; i < urls.length; i++) {
      if (url.indexOf(urls[i].url) > -1) {
        redirectToUrl = urls[i].redirectTo;
        break;
      }
    }

    return redirectToUrl;
  }

  private isInSubscriptionSection() {
    return (
      this.router.url.indexOf('settings/your-plan') > -1 || this.router.url.indexOf('settings/manage-subscription') > -1
    );
  }

  private logFalsePositiveLogout(code, error) {
    Logger.info('[Interceptor] isFalsePositiveLogout Code', code, error);
    this.segmentIoService.track(SegmentEvent.FalsePositiveLogout, {});
  }
}
